import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import React, {useEffect, useState} from 'react';

function AlertComponent(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AlertMessage = (props: any) => {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('success');

  useEffect(() => {
    if (props.open !== undefined) {
      setOpen(props.open);
      setSeverity(props.severity);
    }
  }, [props]);

  return (
    <Snackbar open={open} autoHideDuration={3000} onClose={props.handleClose}>
      <AlertComponent onClose={props.handleClose} severity={props.severity}>
        {props.message}
      </AlertComponent>
    </Snackbar>
  );
};

export default AlertMessage;
