import {
  useState,
  useEffect,
  useCallback,
} from "react";
import * as Yup from "yup";
import { connect } from "react-redux";
import { useFormik } from "formik";
import { bindActionCreators } from "redux";
import { useForm }  from "react-hook-form"; 
import ApiClient from "../../../../../services/apiclient";
import { nextStep } from "../../../../../store/actions/PrestamosPersonalesActions";
import "./styles.scss";
import { ProgressCircular } from "../../../Progress";
import Menu from "../Menu/Menu";
//import { useForm }  from "react-hook-form"; 
const AddressForm = ({
  nominaId,
  nextStep,
}) => {

  const apiClient = ApiClient.getInstance();

  const [colonias, setColonias] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [preInfo,setPreInfo] = useState(null);

  const { register, handleSubmit, reset, formState: { errors } } = useForm();
 
useEffect(() => {
    apiClient.getUserPreInfo().then(data => {
      if(!data.error) {
        formik.setFieldValue('street', data.street);
      formik.setFieldValue('exteriorNumber', data.exteriorNumber);
      formik.setFieldValue('interiorNumber',data.interiorNumber);
      formik.setFieldValue('propertyType',data.propertyType);
      formik.setFieldValue('postalCode',data.postalCode);
      formik.setFieldValue('antiquity',data.antiquity);
      formik.setFieldValue('antiquityMonth',data.antiquityMonth);
      formik.setFieldValue('homePhone',data.homePhone);

      console.log(data.street);
      }
      
    });
  

}, []);

  const formik = useFormik({
    
    initialValues: {
      postalCode: "",
      street: "",
      exteriorNumber: "",
      interiorNumber: "",
      delegation: "",
      state: "",
      city: "",
      suburb: "",
      propertyType: "",
      antiquity: "",
      antiquityMonth: "",
      homePhone: "",
      
      // postalCode: preInfo[0].postalCode,
      // street: preInfo[0].street,
      // exteriorNumber: preInfo[0].exteriorNumber,
      // interiorNumber: preInfo[0].interiorNumber,
      // delegation: '',
      // state: '',
      // city: '',
      // suburb: '',
      // propertyType: preInfo[0].propertyType,
      // antiquity: preInfo[0].antiquity,
      // antiquityMonth: preInfo[0].antiquityMonth,
      // homePhone: preInfo[0].homePhone,
    },
    validationSchema: Yup.object({
      postalCode: Yup.string().matches(/(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/).required(),
      street: Yup.string().required(),
      exteriorNumber: Yup.string().required(),
      state: Yup.string().required(),
      city: Yup.string().required(),
      delegation: Yup.string().required(),
      suburb: Yup.string().required(),
      propertyType: Yup.string().required(),
      antiquity: Yup.number().required(),
      antiquityMonth: Yup.number().required(),
      homePhone: Yup.string().matches(/[0-9]+/),
    }),
    onSubmit: values => {

      setIsLoading(true);

      apiClient.addressFormData(values, nominaId)
        .then((data) => {
          console.log(data)
          nextStep("LABOR-DATA");
        })
        .catch(e => {
          console.log(e)
          setIsLoading(false);
        })
    }
  });

  useEffect(() => {

    const setPostalCode = () => {

      if(formik.values.postalCode.trim().length == 5){
        const postalCode = formik.values.postalCode.trim();
  
        apiClient
          .getAdrressFromZipCode(postalCode)
          .then(data => {
            formik.setFieldValue('state', data.estado);
            formik.setFieldValue('city', data.ciudad || data.municipio);
            formik.setFieldValue('delegation', data.municipio);
            formik.setFieldValue('postalCode', formik.values.postalCode);
            setColonias(data?.colonias);
          })
          .catch((e) => console.log(e));
      }
    }

    if (formik.values.postalCode !== "") {
      setPostalCode()
    }

  }, [formik.values.postalCode]);




  
  return (

    <div className="solicitud__container">
      <div className="solicitud-b__container">
        <form id="div-perfil" onSubmit={formik.handleSubmit}>

          <Menu step="address" />

          <div className="solicitud-4__input-group informacionC">

          <div >
              <label htmlFor="street">Calle *</label>
              <input
                tabIndex={2}
                type="text"
                placeholder="Ej."
                name="street"
                onChange={formik.handleChange}
                //{...register("street")}
                onBlur={formik.handleBlur}
                value={formik.values.street}
                className={formik.errors.street ? "inputErr" : ""}
              />
            </div>

            <div >
              <label htmlFor="exteriorNumber">Numero exterior *</label>
              <input
                type="text"
                name="exteriorNumber"
                onChange={formik.handleChange}
                value={formik.values.exteriorNumber}
                onBlur={formik.handleBlur}
                className={formik.errors.exteriorNumber ? "inputErr" : ""}
              />
            </div>

            <div >
              <label htmlFor="interiorNumber">Numero interior</label>
              <input
                type="text"
                name="interiorNumber"
                onChange={formik.handleChange}
                value={formik.values.interiorNumber}
                onBlur={formik.handleBlur}
                className={formik.errors.interiorNumber ? "inputErr" : ""}
              />
            </div>

            <div >
              <label htmlFor="propertyType">Tipo de propiedad *</label>
              <select
                tabIndex={15}
                name="propertyType"
                onChange={formik.handleChange}
                value={formik.values.propertyType}
                onBlur={formik.handleBlur}
                className={formik.errors.propertyType ? "input-select inputErr" : "input-select"}
              >
                <option value="">Seleccione una opción</option>
                <option value="Propia">Propia</option>
                <option value="Alquiler">Alquiler</option>
                <option value="Desconocido">Desconocido</option>
                <option value="Empresa">Empresa</option>
                <option value="Familiar">Familiar</option>
                <option value="Propietario">Propietario</option>
                <option value="Propiedad hipoteca">Propietario hipoteca</option>
                <option value="Sin domicilio propio">Sin domicilio propio</option>
              </select>
            </div>

            <div >
              <label htmlFor="zip">Código Postal *</label>
              <input
                tabIndex={16}
                type="text"
                name="postalCode"
                placeholder="Ej."
                onChange={formik.handleChange}
                value={formik.values.postalCode}
                onBlur={formik.handleBlur}
                className={formik.errors.postalCode ? "inputErr" : ""}
              />
            </div>

            <div >
              <label htmlFor="suburb">Colonia *</label>
              <select
                tabIndex={21}
                id="suburb"
                name="suburb"
                onChange={formik.handleChange}
                value={formik.values.suburb}
                onBlur={formik.handleBlur}
                className={formik.errors.suburb ? "input-select inputErr" : "input-select"}
              >
                <option value="">Seleccione una opción</option>
                {colonias.map((data) => {
                        return (
                          <option key={data?.colonia} value={data?.colonia}>
                            {data?.colonia}
                          </option>
                        );
                      })}
              </select>
            </div>

            <div >
              <label htmlFor="city">Ciudad *</label>
              <input
                tabIndex={5}
                type="text"
                placeholder="Ej."
                name="city"
                onChange={formik.handleChange}
                value={formik.values.city}
                onBlur={formik.handleBlur}
                className={formik.errors.city ? "inputErr" : ""}
                disabled
              />
            </div>

            <div >
              <label htmlFor="state">Estado  *</label>
              <input
                tabIndex={2}
                type="text"
                placeholder="Ej."
                name="state"
                onChange={formik.handleChange}
                value={formik.values.state}
                onBlur={formik.handleBlur}
                className={formik.errors.state ? "inputErr" : ""}
                disabled
              />
            </div>

            <div >
              <label htmlFor="delegation">Delegación*</label>
              <input
                tabIndex={6}
                type="text"
                placeholder="Ej."
                name="delegation"
                onChange={formik.handleChange}
                value={formik.values.delegation}
                onBlur={formik.handleBlur}
                className={formik.errors.delegation ? "inputErr" : ""}
                disabled
              />
            </div>

            <div >
              <label htmlFor="homePhone">Teléfono de casa</label>
              <input
                tabIndex={6}
                type="text"
                placeholder="Ej."
                name="homePhone"
                onChange={formik.handleChange}
                value={formik.values.homePhone}
                onBlur={formik.handleBlur}
                className={formik.errors.homePhone ? "inputErr" : ""}
              />
            </div>

            <div >
              <label htmlFor="antiquity">Tiempo en el domicilio *</label>
              <div className="domicilioTime">
                <input
                  tabIndex={14}
                  type="number"
                  name="antiquity"
                  placeholder="Años"
                  onChange={formik.handleChange}
                  value={formik.values.antiquity}
                  onBlur={formik.handleBlur}
                  className={formik.errors.antiquity ? "inputErr domicilioTimeI" : "domicilioTimeI"}
                />
                <input
                  tabIndex={14}
                  type="number"
                  placeholder="Meses"
                  name="antiquityMonth"
                  onChange={formik.handleChange}
                  value={formik.values.antiquityMonth}
                  onBlur={formik.handleBlur}
                  className={formik.errors.antiquityMonth ? "inputErr domicilioTimeI" : "domicilioTimeI"}
                />
              </div>
            </div>

          </div>

          <div className="btnSubmitWrapAddres">
            <button
              className="btn blue"
              type="submit"
              disabled={isLoading}
            >
              <ProgressCircular loading={isLoading} title='Siguiente' />
            </button>
          </div>
        </form>
      </div>
    </div>

  )
};

const mapStateToProps = ({ personalCreditReducer }) => ({
  user: personalCreditReducer?.user ?? {},
  nominaId: personalCreditReducer?.nominaId ?? "",
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    nextStep,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressForm);
