import React from 'react';
import Header from '../header.component';
import Footer from './footer';
import TerminosCondicionesView from './terminosCondicionesView';


export default function TermsConditions() {
  return (
    <div>
      <Header />
        <TerminosCondicionesView />
      <Footer />
    </div>
  );
}
