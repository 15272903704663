import React, { useEffect, useState } from "react";
import { Field, Formik, Form, useFormik, ErrorMessage } from "formik";
import "./validacion.css";
import { useHistory } from "react-router-dom";
import ApiClient from "../../../../services/apiclient";
import AlertMessage from "../../../alert.component";
import * as Yup from "yup";
import Modal from 'react-modal';
import contrato1 from "../../../../assets/contrato1.jpeg";
import contrato2 from "../../../../assets/contrato2.jpg";
import CircularProgress from '@material-ui/core/CircularProgress';
import { ProgressCircular } from "../../Progress";
const requierdMessage = "Requerido";

const validationSchema = Yup.object().shape({
  city: Yup.string().required(requierdMessage),
  contract: Yup.string().required(requierdMessage),
});

export const ValidacionPabs = ({ nextStep, setLoanAmount, setPabsNumber }) => {
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [ciudades, setCiudades] = useState([]);
  const [userLoan, setUserLoan] = useState({
    city: "",
    contract: "",
  });

  const [loading, setLoading] = useState(false);

  const apiClient = ApiClient.getInstance();
  const history = useHistory();

  useEffect(() => {
    apiClient
      .getCity()
      .then((ciudades) => {
        setCiudades(ciudades);
      })
      .catch((err) => {
        console.error(err);
      });
      
  }, []);



  const createPABS = (values: any, actions: any) => {
    setLoading(true);
     setPabsNumber(values.contract);

     apiClient.userContract(values.contract, values.city).then(value => {
      setLoading(false);
      setLoanAmount(function (prevData: any) {
        var oldObj = { ...prevData };
        oldObj.upper = value.upper;
        oldObj.lower = value.lower;
        return { ...oldObj };
      });
      localStorage.setItem("upper",value.upper);
      localStorage.setItem("lower",value.lower);
      nextStep();
    }).catch(err => {
      if(err.message == 'Contract number was not found.'){
        history.push('/RECHAZADO/pabs/contract-not-found');
      }
      else if(err.message == 'La ciudad elegida no existe.'){
        history.push('/RECHAZADO/pabs/city-not-found');
      }
      else if(err.message == 'El título registrado ya fue utilizado. Cuenta con otro titulo vigente?'){
        history.push('/RECHAZADO/pabs/validated-user');
      }
      else if(err.message == 'El nombre regisrado no coincide con el número de contrato. Favor de revisar los datos'){
        history.push('/RECHAZADO/pabs/user-does-not-match');
      }
      else if(err.message == 'El título registrado está en Suspensión. Cuenta con otro titulo vigente?'){
        history.push('/RECHAZADO/pabs/suspended-account');
      }
      else if(err.message == 'El título registrado cuenta con un saldo mayor a $2,000.00. Cuenta con otro titulo pagado o saldo menor a $2,000.00?'){
        history.push('/RECHAZADO/pabs/major-balance');
      }
      else if(err.message == 'Prestamo denegado.'){
        history.push('/RECHAZADO/pabs/loan-denied');
      }
      else {
        history.push('/RECHAZADO/pabs/not-found');
      }
    }); 
 
    


  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
  };

 


  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    /* subtitle.style.color = '#f00'; */
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className="container">
      <div className="navbar1">
        <a className="hg1">1. Validación</a>
        <a className="hg2">2. Información personal</a>
        <a className="hg3">3. Referencias</a>
        <a className="hg4">4. Perfil</a>
        <a className="hg5 ">5. Titulo PABS</a>
      </div>
      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />

      <Formik
        initialValues={userLoan}
        onSubmit={createPABS}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, errors, touched }) => (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div className="containerV">

              <div>
                <label htmlFor="city">Ciudad</label>
                <Field
                  as="select"
                  id="city"
                  name="city"
                  className={errors.city && touched.city && "invalidV"}

                >
                  <option value="">Selecciona una opcion</option>

                  {ciudades.map((ciudad) => (
                    <option key={ciudad.id} value={ciudad.name}>
                      {ciudad.name}
                    </option>
                  ))}
                </Field>
              </div>

              <div className="numero">
                <label htmlFor="contract">Número de Contrato</label>
                <Field
                  type="text"
                  id="contract"
                  name="contract"
               
                  className={errors.contract && touched.contract && "invalidV"}
                />
              </div>
              <button title="¿Dónde puedo encontrar mi número de contrato?" style={{marginBottom: 10}} className="btn yellow" id="contrato" onClick={openModal}>?</button>
            </div>

            <div className="ButtonV">
            <button className="btn blue" type="submit" disabled={loading}>
                <ProgressCircular loading={loading} title='Continuar'/>
              </button>
                    </div>

  
          </Form>
        )}
      </Formik>

      <div>
     
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
      
        contentLabel="Example Modal"
      >
        <div className="modal1-boton-cerrar">
          <button className="btn blue" onClick={closeModal}>Cerrar</button>
        
        </div>

        <div className="imagenes-modal" >
        <img src={contrato1} alt="" width="20%" />
        <img src={contrato2} alt="" width="50%" />
        </div>
      
        
       
      </Modal>
    </div>

    </div>
  );
};
