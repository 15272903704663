import React, {useState} from 'react';
import icons from '../../assets/icons.png';
import Header from '../header.component';
import AlertMessage from "../alert.component";
import useLocalStorage from "../../hooks/local-storage";
import ApiClient from '../../services/apiclient';
//function RequestForm2() {
  import {useHistory, useParams} from "react-router-dom";
import { error } from 'console';
const RequestForgotPassword = (props: any) => {

  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [email, setEmail] = useState(null)
  const [disabledButton, setDisabledButton] = useState(false);


  //function RequestForm2() {
  
  
  const history = useHistory();
  const reset ={
    phone: ""
  };

  const apiClient = ApiClient.getInstance();
  const requestForgotPassword = () => {

    apiClient.resetPassword(reset).then(res => {
      
        setOpenMessage(true);
        setMessage('Se ha enviado un codigo de verificación a su telefono');
  
        setTimeout( () => {
             history.push('/request-forgot-password-code');
        }, 3000);

    }).catch(error => {
      setOpenMessage(true);
      setMessage('Ocurrio un error al enviar la petición, verifique su numero de telefono');
      setSeverity('error');
    });;

    };

  const handleClose = () => {
    setOpenMessage(false);
  }

  const handleInputChange = (e: {target: {name: any; value: any}}) => {
    reset.phone =e.target.value;
    
  };

  return (
    <div>
      <Header />
      <AlertMessage
          open={openMessage}
          message={message}
          handleClose={handleClose}
          severity={severity}
      />
      <div className="solicitud__container">
        <div className="solicitud-2__content">

          <h2>Recupera tu contraseña</h2>

          <input
              type="email"
              onChange={handleInputChange}
              name="phone"
              placeholder="Teléfono"
          />
          <button disabled={disabledButton} onClick={requestForgotPassword} className="blue">Enviar</button>

        </div>
      </div>
    </div>
  );
}

export default RequestForgotPassword;
