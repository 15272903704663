import React, { useEffect, useState } from 'react';

export const TramiteCreditoContext = React.createContext();

const TramiteCreditoProvider = props => {
  const [credito, setCredito] = useState({
    idPrestamo: '',
    fecha_solicitud: '',
    isApproved: false,
    isDelivered: false,
    estatusSolicitud: 'sin-credito',
    importe: 0,
    paymentStatus: '0', // (0 = nuevo, 1 = en proceso, 2 = aprobado, 3 = Rechazado)
    importe_interes: 0,
    total_importe: 0,
    numero_plazo: 0,
    importe_liquidar: 0,
    importe_pendiente: 0,
    detalles: [],
    limitAmountCredit: 20000, // limite de dinero a prestar
    limitTermCredit: 12, // limite de meses
    tasa: 70,
  });

  const updateCredito = valor => {
    setCredito(valor);
    localStorage.setItem('creditoLocal', JSON.stringify(valor));
  };

  const deleteCredito = () => {
    setCredito({idPrestamo: '',
    fecha_solicitud: '',
    isApproved: false,
    isDelivered: false,
    estatusSolicitud: 'sin-credito',
    importe: 0,
    paymentStatus: '0', // (0 = nuevo, 1 = en proceso, 2 = aprobado, 3 = Rechazado)
    importe_interes: 0,
    total_importe: 0,
    numero_plazo: 0,
    importe_liquidar: 0,
    importe_pendiente: 0,
    detalles: [],
    limitAmountCredit: 20000, // limite de dinero a prestar
    limitTermCredit: 12, // limite de meses
    tasa: 70,});
    localStorage.removeItem('creditoLocal');
  };

  useEffect(() => {
    if (localStorage.getItem('creditoLocal')) {
      const creditoLocal = JSON.parse(localStorage.getItem('creditoLocal'));
      setCredito(creditoLocal);
    }
  }, []);

  return (
    <TramiteCreditoContext.Provider value={{ credito, updateCredito, deleteCredito }}>
      {props.children}
    </TramiteCreditoContext.Provider>
  );
};

TramiteCreditoProvider.propTypes = {};

export default TramiteCreditoProvider;
