import { useState } from "react";
import ApiClient from "../../../../../services/apiclient";
import "./Domicilio.css";
import { AddressData } from "../../prestamo-personal/forms";
import Direccion from "./direccion-form";


export default function Domicilio({
  nextStep,
}) {
  const [loading, setLoading] = useState(false);
  const apiClient = ApiClient.getInstance();
  const [addressData, setAddressData] = useState<AddressData>();

  const handleUpdateLoan = (body: object) => {
    console.log(body)
    if (body) {
      apiClient.updateAdress(body).then((res) => {
        nextStep();
      });
    }
  };

  return (
    <div>
      <div className="solicitud__container">
        <div className="solicitud-b__container">
          <Direccion
            setAddress={setAddressData}
            addressData={addressData}
            loading={loading}
            callback={handleUpdateLoan}
          />
        </div>
      </div>
    </div>
  );
}
