import React, { useEffect } from "react";
import "./aceptado.css";
import checked from "../../../../../assets/circle-checked.png";
import { Field, Formik, Form, useFormik, ErrorMessage } from "formik";
import ApiClient, { PabsPayment } from "../../../../../services/apiclient";
import { useState } from "react";
import { useHistory } from "react-router-dom";

function Aceptado({ nextStep, loanAmount, userData, setUserData, productName, setProductName }) {

  const apiClient = ApiClient.getInstance();
  const history = useHistory();

  const [options, setOptions] = useState<PabsPayment[]>([]);
  const [cantidades, setCantidades] = useState<PabsPayment[]>([]);

    // Redirect to /Veriff if user is not verified
    // useEffect(() => {
    //   const checkUserVeriffValidated = async () => {
      
    //     const isValidatedRes = await apiClient.checkUserVeriffValidated();
    //     if(isValidatedRes.veriffValidated !== true) {
    //         history.push('/veriff?from=PABS');
    //         return;
    //     }
    
    //   };
    //   const timeout = setTimeout(() => {
    //     checkUserVeriffValidated();
    //   }, 500);
  
    //   return () => {
    //     clearTimeout(timeout);
    //   }
    // }, []);

  useEffect(() => {
    apiClient.getProducts(parseInt(localStorage.getItem("upper")), parseInt(localStorage.getItem("lower"))).then((data: any) => {
      console.log('OPCIONES: ', data)

      const arr1 = getUniqueListBy(data, 'capital')
      console.log('reduceValue: ', arr1)
      setOptions(data);
      setCantidades(arr1);
    })
  }, []);

  function getUniqueListBy(arr, key): any {
    return [...new Map(arr.map(item => [item[key], item])).values()]
}

  const onsubmitForm = (values: any, actions: any) => {

    setProductName(values.amount);
    console.log(values.amount);

    const amount0 = values.amount.split('/')
    const amount1 = parseInt(amount0[0]);

    setUserData({
      ...values,
      amount: amount1
    })

    console.log(productName);


    console.log(amount1, typeof (amount1));

    nextStep();

  };
  const [cantidad, setCantidad] = useState();
  const [filtroOpciones, setFiltroOpciones] = useState([]);
  const onchangeProduct = e =>{
      setCantidad(
         e.target.value
      );
      const search = options.filter(value => value.capital == e.target.value);

      setFiltroOpciones(search);
  }

  return (
    <div className="containerVA">
      <img src={checked} alt="" />
      <h3>¡Felicidades! Contrato de PABS aceptado</h3>
      <h4>
        El rango de crédito aprobado para ti es entre ${localStorage.getItem("lower")} y $
        {localStorage.getItem("upper")} pesos
      </h4>

      <Formik initialValues={userData} onSubmit={onsubmitForm}>
        {({ handleSubmit, errors, touched }) => (
          <Form
            style={{width: '50%'}}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >


            <div className="container-selects">
              <div className="containerV productop" >
                <label htmlFor="cantidad">Selecciona tu crédito: </label>
                <Field
                  onChange={onchangeProduct}
                  style={{ textAlignLast: 'center'}}
                  as="select"
                  type="text"
                  id="cantidad"
                  name="cantidad"
                  value={cantidad}
                >
                  <option value="">Seleccione una opción</option>
                  {
                    cantidades.map(option => (
                      <option value={option.capital} >${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(option.capital)}</option>
                    ))
                  }
                  
                </Field>
              </div>
              <div className="containerV productop" >
                <label htmlFor="amount">Selecciona tu pago semanal: </label>
                <Field
                  style={{ textAlignLast: 'center'}}
                  as="select"
                  type="text"
                  id="amount"
                  name="amount"
                >
                  <option value="">Seleccione una opción</option>
                  {
                    filtroOpciones.map(option => (
                      <option value={option.name}>{option.semanas} pagos semanales de ${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(option.pago_semanal)} Total a pagar: ${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(option.total_pagado)}</option>
                    ))
                  }
                  
                </Field>
              </div>
            </div>
            <div className="buttonA">
              <button type="submit" className="btn blue ">Continuar</button>
            </div>


          </Form>

        )}
      </Formik>

    </div>
  );
}

export default Aceptado;
