import File from '../../../../assets/file.png';
import upload from '../../../../assets/upload.png';
import check from '../../../../assets/check-mark.png';
import { useDropzone } from "react-dropzone";
import React, { SetStateAction, useCallback } from "react";
import { FileUpload } from '../../../../interfaces/fileInterace';
import Compressor from 'compressorjs';

interface UploadBoxParams {
    propKey: string,
    mimeType: string,
    setter: React.Dispatch<SetStateAction<File>>,
    message: string,
    file: FileUpload
}

export default function UploadBox({
    mimeType,
    propKey,
    setter,
    message,
    file
}) {


    /**
   * Function that is executed when the user drops a file into
   * the box container.
   */
    const onDrop = useCallback((acceptedFiles) =>  {
        //alert(1)
        const img = acceptedFiles[0];
        console.log(img.size);
        new Compressor(img, {
          quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
          success: (compressedResult) => {
            console.log(compressedResult)
            // compressedResult has the compressed file.
            // Use the compressed file to upload the images to your server.        
            setter(compressedResult)
          },
        });
        // setter({ key: propKey, file: acceptedFiles[0] });
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: mimeType });

    return (
        <div >

            <div className="drag_and_drop" {...getRootProps()}>
                <i className="fas fa-file-upload">
                    {
                        file ?
                            file.type === 'application/pdf'  ? <i className="fas fa-file-pdf" style={{margin:' 5px', color: '#ef6666'}}></i>: <img src={URL.createObjectURL(file)} alt="INE icon" />
                            :
                            <img src={File} alt="INE icon" />
                    }
                </i>
                <span>
                    <label>
                        {isDragActive ? (
                            <p>Dejalo caer aqui</p>
                        ) : (
                            <p>Elegir un archivo </p>
                        )}
                        <img src={upload} alt="upload icon" />
                    </label>
                    <input
                        type="file"
                        id={propKey}
                        {...getInputProps()}
                    />
                </span>
            </div>
            <p>{message}</p>
        </div>
    )
}
