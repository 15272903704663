import React, { useEffect, useState } from "react";
import ApiClient, { IUser } from "../../../../services/apiclient";
import AlertMessage from "../../../alert.component";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./informacion.css";

export default function Informacion({ nextStep, userData, setUserData,prevStep }) {
  const [openMessage, setOpenMessage] = useState(false);
  const [userDetails, setUserDetails] = useState<IUser>({
    email: "",
    birthDate: "",
    firstName: "",
    lastFName: "",
    lastMName: "",
    secondName: "",
    userId: "",
    phoneNumber: "",
    sex: "",
  });

  const validationSchema = Yup.object().shape({

    user: Yup.object({
      curp: Yup.string()
        .trim()
        .required("Requerido")
        .matches(/^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/, "Ingrese CURP valido"),
      rfc: Yup.string()
        .required("Requerido")
        .matches(/^[a-zA-Z]{3,4}(\d{6})((\D|\d){3})?$/, "Ingrese RFC valido"),
        // .matches(/^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/, "Ingrese RFC valido"),
      nationality: Yup.string().required("Requerido"),
      countryOfBirth: Yup.string().required("Requerido"),
      stateOfBirth: Yup.string().required("Requerido")

    }),

    personalData: Yup.object({
      IDType: Yup.string().required("Requerido"),
      identificationNumber: Yup.string().required("Requerido").matches(/^[0-9]{13}$/, "Ingresa un numero de identificación valido"),
      civilStatus: Yup.string().required("Requerido"),
      educationLevel: Yup.string().required("Requerido"),
      economicDependents: Yup.number().required("Requerido").min(0)
    })
  });


  const apiClient = ApiClient.getInstance();

  useEffect(() => {
    apiClient
      .getUserData()
      .then((res) => {
        setUserDetails(res);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onsubmitForm = (values: any, actions: any) => {
    setUserData(values);
    console.log(values);
    nextStep();
  };

  return (
    <div>

      <AlertMessage open={openMessage} />
      <div className="solicitud__container">

        <div className="solicitud-b__container">
          <div className="navbar2">
            <a className="hg11">1. Validación</a>
            <a className="hg21">2. Información personal</a>
            <a className="hg31">3. Referencias</a>
            <a className="hg41">4. Perfil</a>
            <a className="hg51 ">5. Titulo PABS</a>
          </div>
          <p>
            {" "}
            <b>2.1 Identificación</b>{" "}
          </p>

          <Formik initialValues={userData} onSubmit={onsubmitForm} validationSchema={validationSchema}>
            {({ handleSubmit, errors, touched, }) => (
              <Form
                onSubmit={(e) => {

                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <div className="solicitud-4__input-group informacionC">
                  <div>
                    <label htmlFor="Nombre">Nombre</label>
                    <input
                      type="text"
                      id="Nombre"
                      name="address.city"
                      placeholder={userDetails.firstName}
                      disabled
                    />
                  </div>

                  <div>
                    <label htmlFor="Nombre">Segundo nombre</label>
                    <input
                      type="text"
                      id="Nombre"
                      name="address.city"
                      placeholder={userDetails.secondName}
                      disabled
                    />
                  </div>

                  <div>
                    <label htmlFor="employeeNumber">Apellido paterno</label>
                    <input
                      type="text"
                      id="employeeNumber"
                      name="employeeNumber"
                      placeholder={userDetails.lastFName}
                      disabled
                    />
                  </div>

                  <div>
                    <label htmlFor="employeeNumber">Apellido materno</label>
                    <input
                      type="text"
                      id="employeeNumber"
                      name="employeeNumber"
                      placeholder={userDetails.lastMName}
                      disabled
                    />
                  </div>

                  <div>
                    <label htmlFor="employeeNumber">Género</label>
                    <input
                      type="text"
                      id="employeeNumber"
                      name="employeeNumber"
                      placeholder={userDetails.sex}
                      disabled
                    />
                  </div>

                  <div>
                    <label htmlFor="nationality">Nacionalidad</label>
                    <Field
                      as="select"
                      type="text"
                      id="nationality"
                      maxlength="45"
                      name="user.nationality"
                    >
                      <option value="">Seleccione una opción</option>
                      <option value="Mexicana">Mexicana</option>
                    </Field>
                    <ErrorMessage name={`user.nationality`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                  </div>

                  <div>
                    <label htmlFor="employeeNumber">Fecha de nacimiento</label>
                    <input
                      type="text"
                      id="employeeNumber"
                      name="employeeNumber"
                      placeholder={userDetails.birthDate.substring(0, 10)}
                      disabled
                    />
                  </div>

                  <div>
                    <label htmlFor="rfc">RFC</label>
                    <Field type="text" maxlength="13"  id="rfc" name="user.rfc" />
                    <ErrorMessage name={`user.rfc`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>


                  </div>

                  <div>
                    <label htmlFor="curp">CURP</label>
                    <Field type="text"  maxlength="18" id="curp" name="user.curp" />
                    <ErrorMessage name={`user.curp`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                  </div>

                  <div>
                    <label htmlFor="stateOfBirth">Estado de nacimiento</label>
                    <Field
                      as="select"
                      type="text"
                      id="stateOfBirth"
                      name="user.stateOfBirth"
                      maxlength="31"
                    >
                      <option value="">Seleccione un estado</option>
                      <option value="Aguascalientes">Aguascalientes</option>
                      <option value="Baja California">Baja California</option>
                      <option value="Baja California Sur">
                        Baja California Sur
                      </option>
                      <option value="Campeche">Campeche</option>
                      <option value="Chiapas">Chiapas</option>
                      <option value="Chihuahua">Chihuahua</option>
                      <option value="Coahuila de Zaragoza">
                        Coahuila de Zaragoza
                      </option>
                      <option value="Colima">Colima</option>
                      <option value="Durango">Durango</option>
                      <option value="Estado de México">Estado de México</option>
                      <option value="Guanajuato">Guanajuato</option>
                      <option value="Guerrero">Guerrero</option>
                      <option value="Hidalgo">Hidalgo</option>
                      <option value="Jalisco">Jalisco</option>
                      <option value="Michoacán de Ocampo">
                        Michoacán de Ocampo
                      </option>
                      <option value="Morelos">Morelos</option>
                      <option value="Nayarit">Nayarit</option>
                      <option value="Nuevo León">Nuevo León</option>
                      <option value="Oaxaca">Oaxaca</option>
                      <option value="Puebla">Puebla</option>
                      <option value="Querétaro">Querétaro</option>
                      <option value="Quintana Roo">Quintana Roo</option>
                      <option value="San Luis Potosí">San Luis Potosí</option>
                      <option value="Sinaloa">Sinaloa</option>
                      <option value="Sonora">Sonora</option>
                      <option value="Tabasco">Tabasco</option>
                      <option value="Tamaulipas">Tamaulipas</option>
                      <option value="Tlaxcala">Tlaxcala</option>
                      <option value="Veracruz de Ignacio de la Llave">
                        Veracruz de Ignacio de la Llave
                      </option>
                      <option value="Yucatán">Yucatán</option>
                      <option value="Zacatecas">Zacatecas</option>
                    </Field>
                    <ErrorMessage name={`user.stateOfBirth`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                  </div>

                  <div>
                    <label htmlFor="countryOfBirth">Pais de nacimiento</label>
                    <Field
                      as="select"
                      type="text"
                      id="countryOfBirth"
                      name="user.countryOfBirth"
                      maxlength="45"
                    >
                      <option value="">Seleccione una opción</option>
                      <option value="México">México</option>
                    </Field>
                    <ErrorMessage name={`user.countryOfBirth`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>

                  </div>

                 {/*  <div>
                    <label htmlFor="Mail">Mail</label>
                    <input type="text" id="Mail" name="email" placeholder={userDetails.email} disabled />


                  </div> */}

                 {/*  <div>
                    <label htmlFor="phoneNumber">Celular</label>
                    <input
                      type="text"
                      id="phoneNumber"
                      name="phoneNumber"
                      placeholder={userDetails.phoneNumber}
                      disabled
                    />
                  </div> */}

                  <div>
                    <label htmlFor="IDType">
                      Tipo de identificación
                    </label>
                    <Field
                      as="select"
                      type="text"
                      id="IDType"
                      name="personalData.IDType"
                    >
                      <option value="">Selecciona una opcion</option>
                      <option value="INE">INE</option>
                    </Field>
                    <ErrorMessage name={`personalData.IDType`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                  </div>

                  <div>
                    <label htmlFor="identificationNumber">
                      Número de tu INE
                    </label>
                    <Field
                     maxlength="40"
                      tabIndex={13}
                      type="text"
                      id="identificationNumber"
                      name="personalData.identificationNumber"
                    />
                    <ErrorMessage name={`personalData.identificationNumber`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                  </div>

                  <div>
                    <label htmlFor="civilStatus">Estado civil</label>
                    <Field
                     maxlength="20"
                      as="select"
                      type="text"
                      id="civilStatus"
                      name="personalData.civilStatus"
                    >
                      <option value="">Seleccione una opción</option>
                      <option value="Soltero">Soltero</option>
                      <option value="Casado">Casado</option>
                      <option value="Divorciado">Divorciado</option>
                      <option value="Separado">Separado</option>
                      <option value="Soltero">Soltero</option>
                      <option value="Unión Libre">Unión Libre</option>
                      <option value="Viudo">Viudo</option>
                      <option value="Desconocido">Desconocido</option>
                    </Field>
                    <ErrorMessage name={`personalData.civilStatus`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                  </div>

                  <div>
                    <label htmlFor="educationLevel">Nivel de estudios</label>
                    <Field
                     maxlength="20"
                      as="select"
                      type="text"
                      id="educationLevel"
                      name="personalData.educationLevel"
                    >
                      <option value="">Seleccione una opción</option>
                      <option value="Ninguna">Ninguna</option>
                      <option value="Primaria">Primaria</option>
                      <option value="Secundaria">Secundaria</option>
                      <option value="Preparatoria">Preparatoria</option>
                      <option value="Tecnico">Tecnico</option>
                      <option value="Profesional">Profesional</option>
                      <option value="Maestria">Maestria</option>
                      <option value="Doctorado">Doctorado</option>
                      <option value="Otros">Otros</option>
                      <option value="Desconocido">Desconocido</option>
                    </Field>
                    <ErrorMessage name={`personalData.educationLevel`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                  </div>

                  <div>
                    <label htmlFor="dependientes">
                      Dependientes económicos
                    </label>

                    <select id="dependientes" name="dependientes">
                      <option value="">No</option>
                      <option value="">Si</option>
                    </select>
                  </div>

                  <div>
                    <label htmlFor="economicDependents">
                      ¿Cuántos dependientes económicos?
                    </label>
                    <Field
                      placeholder="4"
                      type="number"
                      min="0"
                      id="economicDependents"
                      name="personalData.economicDependents"
                    />
                    <ErrorMessage name={`personalData.economicDependents`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                  </div>
                </div>

                <div className="Button-cpr">
                <button onClick={prevStep} className="btn blue">
                    Regresar
                  </button>
                  <button type="submit" className="btn blue">
                    Continuar
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
