import "./nav.css";
import { useHistory } from "react-router-dom";
import { Business } from "./1-empresa/Business";
import Credit from "./3-credito/credit";
import City from "./2-ciudad/City";
import HeaderDashboard from "../../headerDashboardNomina.component";
import React, { useEffect, useState } from "react";
import ApiClient from "../../../../services/apiclient";
import Informacion from "../Nomina/4-informacion/Information";
import Domicilio from "./5-domicilio/Domicilio";
import Referencia from "./6-referencia/Referencia";
import Documents from "./7-documentos/Documents";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { UserContext } from "../../../../context/UserProvider";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > * + *': {
        marginLeft: theme.spacing(11),
      },
    },
  }),
);

export default function Nav() {
  const apiClient = ApiClient.getInstance();
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(0);
  const classes = useStyles();
  const { userMain, updateUser } = React.useContext(UserContext);

  useEffect(() => {
    setLoading(true);
    //validate if user has restriction 
    apiClient.validateRestriction('nomina').then((result) => {
      if(result.hasRestriction === true){
        history.push('/RECHAZADO-nomina/rejection/rejection');
      } else {
        updateUser({ ...userMain, creditoNomina:'nomina' });
        apiClient.getSteps().then((res) => {
          console.log("23---------------------",res);
    
          if (res.nominaId) {
            localStorage.setItem("NOMINA_ID", res.nominaId);
            console.log(res.nominaId)
          }
          if (res.step === null) {
            setCurrentPage(0);
            setLoading(false);
          }
          if (res.step === "BUSINESS") {
            setCurrentPage(1);
            setLoading(false);
          }
          if (res.step === "WEB-SERVICE") {
            setCurrentPage(1);
            setLoading(false);
          }
          if (res.step === "PAYMENT-SETTINGS") {
            setCurrentPage(2);
            setLoading(false);
          }
          if (res.step === "PERSONAL-INFORMATION") {
            setCurrentPage(3);
            setLoading(false);
          }
          if (res.step === "ADDRESS") {
            setCurrentPage(4);
            setLoading(false);
          }
          if (res.step === "PERSONAL-REFERENCES") {
            setCurrentPage(5);
            setLoading(false);
          }
          if (res.step === "FINISHED") {
            history.push("/proceso-credito-nomina");
            setLoading(false);
          }
        });
      }
    })

  }, []);
  const nextStep = () => setCurrentPage((prev) => prev + 1);
  const prevStep = () => setCurrentPage((prev) => prev - 1);

  const formPages: any = [
    // {
    //   component: <Business nextStep={nextStep}></Business>,
    //   component_name: "Empresa",
    //   header:
    //     "¡Hola! Bienvenido a percápita. Vamos a comenzar con tu solicitud el proceso de solicitud de crédito",
    // },
    {
      component: <City nextStep={nextStep}></City>,
      component_name: "Ciudad",
      header: "Continuemos con la selección de la ciudad.",
    },
    {
      component: <Credit nextStep={nextStep}></Credit>,
      component_name: "Crédito",
      header: "Selección de credito.",
    },
    {
      component: <Informacion nextStep={nextStep}></Informacion>,
      component_name: "Información",
      header:
        "Ahora, seguimos con algunos otros datos necesarios para analizar tu perfil y otorgarte tu crédito:",
    },
    {
      component: <Domicilio nextStep={nextStep}></Domicilio>,
      component_name: "Domicilio",
      header:
        "¡Ya casi terminamos. Solo necesitamos saber tu dirección y código postal.",
    },
    {
      component: <Referencia nextStep={nextStep}></Referencia>,
      component_name: "Referencia",
      header: "Datos de Empleo y Referencias Personales",
    },
    {
      component: <Documents />,
      component_name: "Documentos",
      header:
        "Necesitamos verificar tu identidad, por lo que necesitamos una foto de tu INE y una selfie tuya.",
    },
  ];

  return (
    <div>
      <HeaderDashboard />
      <div className="container" style={{ display: "flex" }}>
        <div
          className="header"
          style={{ maxWidth: "1000px", textAlign: "center" }}
        >
          <h4>
              {" "}
              <b>Recuerda que contamos con estas otras opciones de crédito : </b>{" "}
            </h4>
          <Typography className={classes.root}>
            <Link to ="/Celular">
              Crédito Celular
            </Link>
            {/*<Link to="/Tequila" >
              Crédito Tequila Solarum
            </Link>*/}
        </Typography>
    <br></br>
    <h3>
              {" "}
              <b>Dinero Efectivo</b>{" "}
            </h3>

          {!loading && (
            <h3>
              {" "}
              <b>{formPages[currentPage]?.header}</b>{" "}
            </h3>
          )}
        </div>
        {!loading && (
          <>
            <div className="header__text">
              <div className="navbar1">
                {formPages.map((element, index) => {
                  return (
                    <a className={currentPage === index ? "hg1" : "hg3"}>
                      {index + 1}
                      {". "}
                      {element.component_name}
                    </a>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>
      <div>
        {!loading && (
          <div className="cliente_container">
            {formPages[currentPage]?.component}
          </div>
        )}
      </div>
    </div>
  );
}
