import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TramiteCreditoContext } from '../../../../context/TramiteCreditoProvider';
import { UserContext } from '../../../../context/UserProvider';
import HeaderDashboard from '../../headerDashboardNomina.component';

export default function NoRealizarSolicitud() {
  const history = useHistory();

  const { userMain, updateUser } = React.useContext(UserContext);

  const { credito, updateCredito } = React.useContext(TramiteCreditoContext);

  var intervalo = setInterval(verificarSolicitud, 10000);

  function verificarSolicitud() {

  fetch(process.env.REACT_APP_URL_API + '/loan-no-approved-entities/' + userMain.userId, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(res => res.json())
    .then(result => {

      if (result.error) {

      } else {
        
        if(result.isActive){
          updateCredito({
            ...credito,
            estatusSolicitud: 'solicitud-no-aprobada',
          });
          clearInterval(intervalo);
        }else{
            updateCredito({
                ...credito,
                estatusSolicitud: 'sin-solicitud',
              });

              clearInterval(intervalo);
      
              return history.push('/sin-credito-nomina'); 
        }
                         
      }
    })
    .catch(error => console.log(error))
}

  return (
    <div>
      <HeaderDashboard />
      <div className="dashboard__container">
        <div className="dashboard__header">
          <h1>¡Hola {userMain.nombre}! Bienvenido a tu espacio</h1>
          <span>2 Noviembre 2020</span>
        </div>
        <div className="dashboard__card">
          <h2>
          Por el momento no puede realizar un nuevo trámite de solicitud.<br />
          Tiene una solicitud de préstamo no aprobada recientemente.
          </h2>
        </div>
      </div>
    </div>
  );
}
