import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation
} from "react-router-dom";

import Home from "./components/home.component";
import HomeNomina from "./components/nominaForm/homeNomina.component";

import Aceptado from "./components/pages/PABS/validacion/aceptado/aceptado";
import Rechazado from "./components/pages/PABS/validacion/rechazado/rechazado";
import Documentos from "./components/pages/PABS/informacion/documentos/documentos";

import DomicioProvider from "./context/DomicilioProvider";
import TramiteCreditoProvider from "./context/TramiteCreditoProvider";
import UserProvider from "./context/UserProvider";
import "./css/main.css";
import "./css/ss.scss";
import PrivateRoute from "./components/PrivateRoute";
import PrivateRouteNomina from "./components/nominaForm/PrivateRouteNomina";
import RutasPrivadas from "./utils/RutasPrivadas";
import rutasPublicas from "./utils/RutasPublicas";
import 'devextreme/dist/css/dx.light.css';
import  {useState} from 'react';
import {NOMINA_ACTIVE, PABS_ACTIVE, PERSONAL_ACTIVE} from './utils/config'

function App() {
  const privateRoutes = RutasPrivadas;
  return (
    <div className="appContainer">
      <UserProvider>
        <DomicioProvider>
          <TramiteCreditoProvider>
            <Router>
              <Switch>
                <Route exact path="/">
                { NOMINA_ACTIVE ? 
                  <HomeNomina />
                : <Home />
                
                }
                
                </Route>
                {privateRoutes.map(({ path, component }) => (
                  NOMINA_ACTIVE ?  <PrivateRouteNomina path={path} component={component} /> : <PrivateRoute path={path} component={component} />
                ))}
                {rutasPublicas.map(({ path, component }) => (
                  <Route path={path} component={component} />
                ))}
                <Route path="*" component={() => <Redirect to="/" />} />

              </Switch>
              
                
            </Router>
          </TramiteCreditoProvider>
        </DomicioProvider>
      </UserProvider>
    </div>
  );
}

export default App;
