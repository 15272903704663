import { min } from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ImgVector from '../../../../assets/dark-blue-vector.png';
import { TramiteCreditoContext } from '../../../../context/TramiteCreditoProvider';
import { UserContext } from '../../../../context/UserProvider';
import ApiClient from '../../../../services/apiclient';
import AlertMessage from '../../alertNomina.component';
import HeaderDashboard from '../../headerDashboardNomina.component';
import CustomSlider from '../../sliderNomina.component';
import CustomDropdown from './dropdown.nomina.component';

interface RouteParams {
  creditType: "nomina" | "personal";
}

export default function ConfigCreditUser() {
  const history = useHistory();

  const { creditType } = useParams<RouteParams>();

  const { credito, updateCredito } = React.useContext(TramiteCreditoContext);
  const { userMain, updateUser } = React.useContext(UserContext);

  const [loanAmount, setLoanAmount] = useState(0);
  const [pagoQuincenal, setPagoQuincenal] = useState(0);

  //este datos serán dinamicos en base a su perfil
  const [maxLoanAmount, setMaxLoanAmount] = useState(credito.limitAmountCredit); // monto maximo
  const [maxTerm, setMaxTerm] = useState(26);//useState(credito.limitTermCredit); // numero de meses
  const [minTerm, setMinTerm] = useState(6);
  const [minLoanAmount, setMinLoanAmount] = useState(2000);
  const [tasa, setTasa] = useState(credito.tasa);

  const [term, setTerm] = useState(0);
  const [recurrence, setRecurrence] = useState<string>('quincenal');
  const [recurrenceDisplay, setRecurrenceDisplay] = useState<string>('quincena');

  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');

  const [phone, setPhone] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [recommendation, setRecommendation] = useState(null);

  const apiClient = ApiClient.getInstance();

  let isSendCodePhone = false;

  const getUserPreautorizado = () => {
    console.log('getUserPreautorizado')
    const userId = userMain.userId;
    fetch(
      process.env.REACT_APP_URL_API + '/user-recommendation-monto/' + userId,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
      .then(res => res.json())
      .then(data => {
        setRecommendation(data)

      })
      .catch(err => console.error(err));
  }

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    var res = event.target.value as string;
    if (res === "quincenal") {
      setRecurrenceDisplay("quincena");
      setMinTerm(6);
      setMaxTerm(26);

    } else {
      setRecurrenceDisplay("semana");
      setMinTerm(12);
      setMaxTerm(52);
    }
    setRecurrence(res);
    recalcularMonto(res);
  };

  const requestCredit = () => {
    if (userMain.token !== '') {
      return history.push('/step4-nomina');
    } else {
      return history.push('/sin-credito-nomina');
    }
  };

  const handleUpdateLoan = () => {
    console.log('update loan');

    if (creditType === 'nomina') {

      if (loanAmount <= 0 && loanAmount < 3000) {
        setOpenMessage(true);
        setMessage('La cantidad de prestamo a solicitar debe ser un minimo de $3000.00. Favor de verificar.');
        setSeverity('error');
        return;
      }

    }

    updateLoan();

  };

  const updateLoan = () => {


    if (creditType === 'nomina') {

      if (loanAmount <= 0 && loanAmount < 3000) {
        setOpenMessage(true);
        setMessage('La cantidad de prestamo a solicitar debe ser un minimo de $3000.00. Favor de verificar.');
        setSeverity('error');
        return;
      }

    }

    const body = {
      amount: loanAmount,
      numberPayments: term,
      frequencyPayments: recurrence[0].toUpperCase() + recurrence.slice(1)
    };

    apiClient.getCredit(creditType).then(credit => {
      apiClient.updateCreditSettings(creditType, body, credit.id).then(data => {
        showMessage('Se han guardado los datos de su credito');
        setTimeout(() => {
          history.push('/proceso-credito-nomina');
        }, 1500);
      }).catch(err => {

      })
    })




    /* fetch(process.env.REACT_APP_URL_API + '/user-loan/' + userId, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(res => {
        console.log(res);
        updateCredito({
          ...credito,
          estatusSolicitud: 'revision',
        });
        showMessage('Se han guardado los datos de su credito');
        setTimeout(() => {
          history.push('/step5');
        }, 1500);
      })
      .catch(err => console.error(err)); */
  };

  const sendCodePhone = (phone: string) => {
    const body = {
      phone: phone,
    };

    fetch(process.env.REACT_APP_URL_API + '/verification/phone/resend', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(res => {
        console.log('code phone enviado ', res);
        // showMessage("Se han guardado los datos de su credito");
        isSendCodePhone = true;
      })
      .catch(err => {
        console.error(err);
        isSendCodePhone = true;
      });
  };

  function recalcularMonto(recurrence: any) {
    if (recurrence === 'mensual') {
      calcularPagoMensual();
    } else if (recurrence === 'quincenal') {
      calcularPagoQuincenal();
    } else {
      calcularPagoSemanal();
    }
  }

  function valueAmount(value: number) {
    setLoanAmount(value);

    return `${value}`;
  }
  function valueTerm(value: number) {
    setTerm(value);
    recalcularMonto(recurrence);
    return `${value}`;
  }

  const calcularPagoQuincenal = () => {
    var principal = loanAmount;
    var interest = tasa / 100 / 26; // 26 quiencenas al año
    var x = Math.pow(1 + interest, term);
    var total = (principal * x * interest) / (x - 1);
    setPagoQuincenal(total);
  };

  const calcularPagoSemanal = () => {
    var principal = loanAmount;
    var interest = tasa / 100 / 52; //52 semanas por año
    console.log(interest);

    var x = Math.pow(1 + interest, term); //Math.pow computes powers
    var total = (principal * x * interest) / (x - 1);

    setPagoQuincenal(total);
  };

  const calcularPagoMensual = () => {
    var principal = loanAmount;
    var interest = tasa / 100 / 12; // 12 meses por año
    console.log(interest);

    var x = Math.pow(1 + interest, term); //Math.pow computes powers
    var total = (principal * x * interest) / (x - 1);

    setPagoQuincenal(total);
  };

  useEffect(() => {
    if (creditType === 'nomina') {
      setLoanAmount(0);
    } else {
      setLoanAmount(10000);
    }

    setTerm(parseInt(String(maxTerm / 2), 10));
  }, [credito]);

  useEffect(() => {
    calcularPagoQuincenal();
    setPhone(userMain?.telefono);
  }, [loanAmount]);
  useEffect(() => {
    if (userMain.esEmpleado) {
      getUserPreautorizado();
    }
  }, [userMain])
  useEffect(() => {
    setTimeout(() => {
      if (!isSendCodePhone) {
        sendCodePhone(userMain.telefono);
      }
    }, 2000);
  }, []);

  const formatMoney = (value: number) => {
    return value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const showMessage = (message: string, type = 'success') => {
    setOpenMessage(true);
    setMessage(message);
    setSeverity(type);
  };

  const handleClose = () => {
    setOpenMessage(false);
  };

  return (
    <div>
      <HeaderDashboard />
      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />
      <div className="solicitud-credito">
        {/*<div className="solicitud-credito__header">*/}
        {/*  <h1>¡Hola {userMain.nombre}! Bienvenido a tu espacio</h1>*/}
        {/*  <span>2 Noviembre 2020</span>*/}
        {/*</div>*/}
        {
          userMain.esEmpleado
            ?
            recommendation
              ?
              <h2 style={{ textAlign: 'center' }}>*En base a tus ingresos se te recomienda solicitar un monto de ${new Intl.NumberFormat('en-US').format(recommendation)} a 52 semanas.</h2>
              :
              null
            :
            null
        }


        <h2>
          Selecciona la cantidad, el plazo y la recurrencia de pago para
          terminar tu solicitud.
        </h2>

        <div className="solicitud-credito__content">
          <section className="solicitud-credito__credito">

            {userMain.esEmpleado ? (
              <div className="solicitud-credito__amount solicitud-credito__item ">
                <div className="solicitud-credito__recurrence solicitud-credito__item ">
                  <h4>¿Cuánto dinero necesitas?</h4>
                  <input
                    placeholder="3000"
                    type="text"
                    id="loanAmount"
                    name="loanAmount"
                    onChange={e => setLoanAmount(Number(e.target.value))} />
                </div>
              </div>)
              : (<div className="solicitud-credito__amount solicitud-credito__item "><CustomSlider
                headerText="¿Cuánto dinero necesitas?"
                min={minLoanAmount}
                max={maxLoanAmount}
                minLabel={'$2,000'}
                maxLabel={formatMoney(maxLoanAmount)}
                step={500}
                valueText={valueAmount}
                defaultValue={(maxLoanAmount + minLoanAmount) / 2}
                valueLabelFormat={(value: number) =>
                  value.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                }
              /></div>)}

            <div className="solicitud-credito__termn solicitud-credito__item ">
              <CustomSlider
                headerText="¿A qué plazo?"
                min={minTerm}
                max={maxTerm}
                minLabel={`${minTerm} ${recurrenceDisplay}s`}
                maxLabel={`${maxTerm} ${recurrenceDisplay}s`}
                step={1}
                valueText={valueTerm}
                defaultValue={(maxTerm + minTerm) / 2}
                valueLabelFormat={(value: number) => value}
              />
            </div>

            <div className="solicitud-credito__recurrence solicitud-credito__item ">
              <h4>Selecciona tu recurrencia de pago</h4>

              <CustomDropdown
                placeholder={'Recurrencia'}
                handleChange={handleChange}
                value={recurrence}
              >
                <option aria-label="None" value="" />
                <option value={'quincenal'}>Quincenal</option>
                <option value={'semanal'}>Semanal</option>
              </CustomDropdown>
            </div>
            {/*
            <div className="solicitud-credito__result solicitud-credito__item ">
              <span>
                Pagos de
                <b>
                  {' '}
                  {pagoQuincenal.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}{' '}
                </b>
                {recurrence}es por{' '}
                <strong> {`${term} ${term !== 1 ? 'meses' : 'mes'}`}.</strong>
              </span>
              <span>
                <b> &nbsp; Los montos son de carácter informativo.</b>
              </span>
            </div> */}
          </section>
          {/*
          <section className="solicitud-credito__contrato">
            <h2>
              Para concluir, deberás aceptar el contrato al verificar tu
              teléfono celular
            </h2>

            <div className="solicitud-credito__input-group">
              <label>Teléfono</label>
              <input
                type="number"
                placeholder="333 123 2345"
                value={phone}
                onChange={e => setPhone(e.target.value)}
              />
            </div>
            <div className="solicitud-credito__input-group">
              <label>Código de verificación</label>
              <input
                placeholder="23frge"
                value={phoneCode}
                onChange={e => setPhoneCode(e.target.value)}
              />
            </div>
          </section> */}

          <section className="solicitud-credito__buttons">
            {/* <button className="descargar-contrato" onClick={requestCredit}>
              Descargar contrato <i className="fas fa-download"></i>
            </button> */}
            <button className="blue" onClick={handleUpdateLoan}>
              Enviar solicitud
            </button>
          </section>
        </div>
      </div>
      <img className="img-back-credito" src={ImgVector} />
    </div>
  );
}
