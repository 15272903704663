import {
  useState,
  useEffect,
} from "react";
import { connect }  from "react-redux";
import { useForm }  from "react-hook-form"; 
import { bindActionCreators } from "redux";

import ApiClient    from "../../../../../services/apiclient";
import { nextStep } from "../../../../../store/actions/PrestamosPersonalesActions";
import { ProgressCircular } from "../../../Progress";
import "./styles.scss";
import Menu from "../Menu/Menu";

const PersonalForm = ({
  nextStep,
}) => {

  const apiClient = ApiClient.getInstance();

  const [paises, setPaises] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [preInfo,setPreInfo] = useState(null);

  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const onSubmit = data => {
    // nextStep("ADDRESS")
    const values = {
      curp: data?.curp,
      rfc: data?.rfc,
      nationality: data?.nationality,
      educationLevel: data?.studiesLevel,
      countryOfBirth: data?.countryOfBirth,
      stateOfBirth: data?.stateOfBirth,
      economicDependents: Number(data?.economicDependents),
      identificationNumber: data?.identificationNumber,
    };

    setIsLoading(true)

    apiClient.personalFormData(values)
    .then(data => {
      nextStep("ADDRESS")
      console.log(data)
    })
    .catch((e) => {
      console.log(e)
      setIsLoading(false)
    })
  };

  useEffect(() => {
    apiClient.getCountries().then(data => setPaises(data.countries));
    apiClient.getUserData().then(data => {

      const user = data;
      user.birthDate = user.birthDate.substring(0, 10)

      reset(user);
    })
    .catch((e) => console.error(e) );

    apiClient.getUserPreInfo().then(data => {
      const userPreInfo = data;
      
      setPreInfo(userPreInfo);
      reset(userPreInfo);
     
      
    });

  }, []);
  return (
    <div className="solicitud__container"> 
        <div className="solicitud-b__container">
      <form onSubmit={handleSubmit(onSubmit)} id="div-perfil">
        
        <Menu step="personal" />

          
            <div className="solicitud-4__input-group informacionC">

              <div >
                <label htmlFor="firstName">Nombre(s) *</label>
                <input
                  tabIndex={2}
                  type="text"
                  placeholder="Ejemplo: Humberto"
                  disabled
                  {...register("firstName")}
                  className={errors.firstName ? "inputErr" : ""}
                />
              </div>

              <div >
                <label htmlFor="apellidoPaterno">Apellido Paterno *</label>
                <input
                  tabIndex={5}
                  type="text"
                  disabled
                  {...register("lastFName")}
                  className={errors.lastFName ? "inputErr" : ""}
                />
              </div>

              <div >
                <label htmlFor="apellidoMaterno">Apellido Materno *</label>
                <input
                  tabIndex={6}
                  type="text"
                  disabled
                  {...register("lastMName")}
                  className={errors.lastMName ? "inputErr" : ""}
                />
              </div>

              <div >
                <label htmlFor="sex">Genero *</label>
                <select
                  tabIndex={7}
                  disabled
                  {...register("sex")}
                  className={errors.sex ? "input-select inputErr" : "input-select"}
                >
                  <option value="">Selecciona una opción</option>
                  <option value="Masculino">Masculino</option>
                  <option value="Femenino">Femenino</option>
                </select>
              </div>

              <div >
                <label htmlFor="nationality">Nacionalidad *</label>
                <select
                  tabIndex={8}
                  id="nationality"
                  name="nationality"
                  {...register("nationality", { required: true })}
                  className={errors.nationality ? "input-select inputErr" : "input-select"}
                >
                  <option value="">Seleccione una opción</option>
                  <option value="MX">Mexicana</option>
                </select>
              </div>

              <div >
                <label htmlFor="fechaNacimiento">Fecha Nacimiento *</label>
                <input
                  disabled
                  tabIndex={9}
                  type="date"
                  {...register("birthDate")}
                  className={errors.birthDate ? "inputErr" : "input-select"}
                />
              </div>

              <div >
                <label htmlFor="cellphone">Número Teléfono *</label>
                <input
                  tabIndex={3}
                  type="text"
                  disabled
                  {...register("phone")}
                  className={errors.phone ? "inputErr" : ""}
                />
              </div>

              <div >
                <label htmlFor="rfc">RFC *</label>
                <input
                  tabIndex={2}
                  type="text"
                  placeholder="Ejemplo: PARUOA9308ASFHG"
                 // value = {preInfo[0]?.rfc ? preInfo[0]?.rfc : null }
                  {...register("rfc", { required: true, pattern: /^[a-zA-Z]{3,4}(\d{6})((\D|\d){3})?$/ })}
                  // {...register("rfc", { required: true, pattern: /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))((-)?([A-Z\d]{3}))?$/ })}
                  className={errors.rfc ? "inputErr" : ""}
                />
              </div>
              
              <div >
                <label htmlFor="curp">CURP *</label>
                <input
                  tabIndex={1}
                  type="text"
                  //value = {preInfo[0]?.curp}
                  {...register("curp", { required: true, pattern: /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/ })}
                  placeholder="Ejemplo: PARUOA9308ASFHG"
                  className={errors.curp ? "inputErr" : ""}
                />
              </div>

              <div >
                <label htmlFor="identificationNumber">Número de INE o IFE *</label>
                <input
                  type="text"
                  maxLength={13}
                  //value = {preInfo[0]?.IDNumber}
                  {...register("identificationNumber", { required: true, pattern: /^[0-9]{13}$/ })}
                  className={errors.identificationNumber ? "inputErr" : ""}
                />
              </div>

              <div >
                <label htmlFor="stateOfBirth">Estado de Nacimiento *</label>
                <select
                  tabIndex={11}
                  id="stateOfBirth"
                  name="stateOfBirth"
                  {...register("stateOfBirth", { required: true })}
                  className={errors.stateOfBirth ? "input-select inputErr" : "input-select"}
                >
                  <option value="">Seleccione un estado</option>
                  <option value="AGUASCALIENTES">Aguascalientes</option>
                  <option value="BAJA CALIFORNIA">Baja California</option>
                  <option value="BAJA CALIFORNIA SUR">Baja California Sur</option>
                  <option value="CAMPECHE">Campeche</option>
                  <option value="CHIAPAS">Chiapas</option>
                  <option value="CHIHUAHUA">Chihuahua</option>
                  <option value="COAHUILA DE ZARAGOZA">Coahuila de Zaragoza</option>
                  <option value="COLIMA">Colima</option>
                  <option value="DISTRITO FEDERAL">Distrito Federal</option>
                  <option value="DURANGO">Durango</option>
                  <option value="GUANAJUATO">Guanajuato</option>
                  <option value="GUERRERO">Guerrero</option>
                  <option value="HIDALGO">Hidalgo</option>
                  <option value="JALISCO">Jalisco</option>
                  <option value="MÉXICO">México</option>
                  <option value="MICHOACÁN DE OCAMPO">Michoacán de Ocampo</option>
                  <option value="MORELOS">Morelos</option>
                  <option value="NAYARIT">Nayarit</option>
                  <option value="NUEVO LEÓN">Nuevo León</option>
                  <option value="OAXACA">Oaxaca</option>
                  <option value="PUEBLA">Puebla</option>
                  <option value="QUERÉTARO">Querétaro</option>
                  <option value="QUINTANA ROO">Quintana Roo</option>
                  <option value="SAN LUIS POTOSÍ">San Luis Potosí</option>
                  <option value="SINALOA">Sinaloa</option>
                  <option value="SONORA">Sonora</option>
                  <option value="TABASCO">Tabasco</option>
                  <option value="TAMAULIPAS">Tamaulipas</option>
                  <option value="TLAXCALA">Tlaxcala</option>
                  <option value="VERACRUZ DE IGNACION DE LA LLAVE">Veracruz de Ignacio de la Llave</option>
                  <option value="YUCATÁN">Yucatán</option>
                  <option value="ZACATECAS">Zacatecas</option>
                </select>
              </div>

              <div >
                <label htmlFor="countryOfBirth">País de nacimiento *</label>
                <select
                  tabIndex={10}
                  {...register("countryOfBirth", { required: true })}
                  className={errors.countryOfBirth ? "input-select inputErr" : "input-select"}
                >
                  <option value="">Seleccione un país</option>
                  {paises.map((pais: any) => (
                    <option
                      key={pais}
                      value={pais}
                    >
                      {pais}
                    </option>
                  ))}
                </select>
              </div>

              <div >
                <label htmlFor="studiesLevel">Nivel de estudios *</label>
                <select
                  tabIndex={12}
                  {...register("studiesLevel", { required: true })}
                  className={errors.studiesLevel ? "input-select inputErr" : "input-select"}
                >
                  <option value="">Seleccione una opción</option>
                  <option value="Ninguna">Ninguna</option>
                  <option value="Primaria">Primaria</option>
                  <option value="Secundaria">Secundaria</option>
                  <option value="Preparatoria">Preparatoria</option>
                  <option value="Tecnico">Tecnico</option>
                  <option value="Profesional">Profesional</option>
                  <option value="Maestria">Maestria</option>
                  <option value="Doctorado">Doctorado</option>
                  <option value="Otros">Otros</option>
                  <option value="Desconocido">Desconocido</option>
                </select>
              </div>

              <div >
                <label htmlFor="economicDependents">Dependientes Económicos *</label>
                <input
                  tabIndex={13}
                  type="text"
                  placeholder="Ej."
                  {...register("economicDependents", { required: true })}
                  className={errors.economicDependents ? "inputErr" : ""}
                />
              </div>

              </div>
              


        <div className="btnSubmitWrapPersonal">
          <button
            className="btn blue"
            type="submit"
            disabled={isLoading}
            >
            <ProgressCircular loading={isLoading} title='Siguiente'/>
          </button>
        </div>          
      </form>
            </div>

    </div>
  )
};

const mapStateToProps = ({ personalCreditReducer }) => ({
  user: personalCreditReducer?.user ?? {},
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators ({
    nextStep,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalForm);