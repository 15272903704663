import React, {useEffect, useState} from 'react';
import Header from "../header.component";
import {useHistory, useParams} from 'react-router-dom'
import CircleCheckedIcon from './../../assets/circle-checked.png';
import ApiClient from '../../services/apiclient';

export default function AccountActived() {

    const [active, setActive] = useState(false);
    const [token, setToken] = useState(null);

    const history = useHistory();

    const irLogin = (valor: any) => {
        return history.push('/login');
    }

    let params = useParams<any>();
    const apiClient = ApiClient.getInstance();

    useEffect(() => {
        console.log(params.token);

        if(params.token){
            console.log('validate token');
            setToken(params.token)
        }

    }, [])

    useEffect( () => {
        console.log(token)
        if(token != null){
            apiClient.activateAccount(token).then( response => {
                if(response.status === 200){
                    setActive(true)

                    setTimeout(
                        () => history.push('/login'), 
                        5000
                    );
                }else{
                    setActive(false)

                    setTimeout(
                        () => history.push('/'), 
                        5000
                      );
                }
            }).catch( error => {

            });
        }
    }, [token])

    useEffect(() => {
        console.log(active)
    }, [active])

    return (
        <div>
            <Header />
            <div className="solicitud__container">
                <div className="solicitud-1__form-solicitud">
                    <h2>Activación de Cuenta</h2>

                    { token != null && active ?
                        ( <div>
                            <div className="center-div">
                                <h3>Tu cuenta ha quedo activada</h3>
                            </div>
                            <div className="center-div">
                                <img width="60%" src={CircleCheckedIcon}/>
                            </div>

                            <div className="center-div">
                                <button className="blue" onClick={irLogin}>Iniciar Sesion</button>
                            </div>

                        </div> ) :
                        (<h3> Sucedio un problema al activar tu cuenta</h3>)
                    }

                </div>
            </div>
        </div>
    );
}
