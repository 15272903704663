import { useState } from "react";
import ApiClient from "../../../../../services/apiclient";
import "./Documents.css";
import { useHistory } from "react-router-dom";

import Ine from "./ine-form";

export default function Documents() {
  const [loading, setLoading] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [ineFiles, setIneFiles] = useState([]);
  const apiClient = ApiClient.getInstance();
  const history = useHistory();

  const fileLoaded = (fileData) => {
    setIneFiles(fileData);
  };

  const handleUpdateLoan = () => {
    const formData = new FormData();
    if (ineFiles.length > 0) {
      setLoading(true);
      formData.append("ifeIneFrente", ineFiles[0].file);
      formData.append("ifeIneReverso", ineFiles[1].file);
      formData.append("selfieIne", ineFiles[2].file);
      formData.append("proofAddress", ineFiles[3].file);
      formData.append("BossRequest", ineFiles[4].file);
      apiClient.updateFileProduct(formData,'celular').then((res) => {
        setLoading(false);
        history.push("/proceso-credito-nomina");
      }).catch(err => {
        setLoading(false);
      });
    }
  };

  return (
    <div>
      <div className="solicitud__container">
        <div className="solicitud-b__container">
          <Ine
            loading={loading}
            fileLoaded={fileLoaded}
            setMessage={setMessage}
            setOpenMessage={setOpenMessage}
            setSeverity={setSeverity}
            file={ineFiles}
            callback={handleUpdateLoan}
          />
        </div>
      </div>
    </div>
  );
}
