import React, { useEffect } from "react";
import "./City.css";
import { Field, Formik, Form } from "formik";
import ApiClient, { PabsPayment } from "../../../../../services/apiclient";
import { useState } from "react";
import { ProgressCircular } from "../../Progress";
import * as Yup from "yup";
import AlertMessage from "../../../alertNomina.component";
import { useHistory } from "react-router-dom";
import FormHelperText from "@material-ui/core/FormHelperText";
import { createStyles, Theme } from "@material-ui/core/styles";
import {
  Grid,
  makeStyles,
  Card,
  CardContent,
  MenuItem,
  InputLabel,
  Select,
  CardActions,
  Button,
  CardHeader,
  FormControl,
  Box,
  TextField,
} from "@material-ui/core";
import { UserContext } from "../../../../../context/UserProvider";

function CityCelular({ nextStep }) {
  const apiClient = ApiClient.getInstance();
  const [options, setOptions] = useState<PabsPayment[]>([]);
  const [cantidades, setCantidades] = useState<PabsPayment[]>([]);
  const [ciudades, setCiudades] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [userLoan, setUserLoan] = useState({});
  const history = useHistory();
  const { userMain, updateUser } = React.useContext(UserContext);
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      formControl: {
        margin: theme.spacing(1),
        width: "50ch",
      },
      selectEmpty: {
        marginTop: theme.spacing(1),
      },
      root: {
        "& .MuiTextField-root": {
          margin: theme.spacing(1),
          width: "50ch",
        },
      },
    })
  );

  const classes = useStyles();
  const validationSchema = Yup.object().shape({
    city: Yup.string().required("Requerido"),
    cliente: Yup.string().required("Requerido"),
  });

  useEffect(() => {
    //const creditID = checkActiveCredit();
    //if (creditID) history.push("/productos");

    apiClient
      .getCityNomina()
      .then((ciudades) => {
        setCiudades(ciudades);
        console.log(ciudades);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    apiClient
      .getProducts(
        parseInt(localStorage.getItem("upper")),
        parseInt(localStorage.getItem("lower"))
      )
      .then((data: any) => {
        console.log("OPCIONES: ", data);

        const arr1 = getUniqueListBy(data, "capital");
        console.log("reduceValue: ", arr1);
        setOptions(data);
        setCantidades(arr1);
      });
  }, []);

  function getUniqueListBy(arr, key): any {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  const createNomina = (values: any) => {
    setLoading(true);

    console.log("ciudad", values.cliente, values.city);

    apiClient
      .getNominaTipoProductos(values.cliente, values.city, "celular")
      .then((res) => {
        setLoading(false);
        updateUser({ ...userMain, percepciones_20: res });
        nextStep();
      })
      .catch((err) => {
        console.log("err.message: ", err);
        if (err.message == "crédito activo con saldo.") {
          history.push("/RECHAZADO-nomina/nomina/credito-activo-con-saldo");
        } else {
          history.push("/RECHAZADO-nomina/nomina/not-found");
        }
        //nextStep();
        // setLoading(false);
        // setOpenMessage(true);
        // setMessage(err.message);
        // setSeverity("error");
      });
  };
  const [cantidad, setCantidad] = useState();
  const [filtroOpciones, setFiltroOpciones] = useState([]);
  const onchangeProduct = (e) => {
    setCantidad(e.target.value);
    const search = options.filter((value) => value.capital == e.target.value);

    setFiltroOpciones(search);
  };
  const handleClose = () => {
    setOpenMessage(false);
  };

  return (
    <>
      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />
      <div className="containerVA">
        <div className="containerVa productop empresas-margin">
          <p className="sp">Seleccione su ciudad</p>
        </div>

        <Formik
          initialValues={userLoan}
          onSubmit={createNomina}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, errors, touched, setFieldValue }) => (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div className="containerV">
                <div>
                  <label htmlFor="city">Ciudad</label>
                  <Field
                    as="select"
                    id="city"
                    name="city"
                    className={errors.city && touched.city && "invalidV"}
                  >
                    <option value="">Selecciona una opcion</option>

                    {ciudades.map((ciudad) => (
                      <option key={ciudad.id} value={ciudad.name}>
                        {ciudad.nameDescription}
                      </option>
                    ))}
                  </Field>
                </div>
                <div></div>

                <div className="numero">
                  <label htmlFor="cliente">Número de Empleado</label>
                  <Field
                    type="text"
                    id="cliente"
                    name="cliente"
                    onChange={(e) => {
                      const value = e.target.value || "";
                      setFieldValue(
                        "cliente",
                        value
                          .normalize("NFD")
                          .replace(/[\u0300-\u036f]/g, "")
                          .toUpperCase()
                      );
                    }}
                    className={errors.cliente && touched.cliente && "invalidV"}
                  />
                </div>
              </div>

              <div className="ButtonV">
                <button className="btn blue" type="submit" disabled={loading}>
                  <ProgressCircular loading={loading} title="Continuar" />
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default CityCelular;
