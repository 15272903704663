import React, { useEffect } from "react";
import "./City.css";
import { Field, Formik, Form } from "formik";
import ApiClient, { PabsPayment } from "../../../../../services/apiclient";
import { useState } from "react";
import { ProgressCircular } from "../../Progress";
import * as Yup from "yup";
import AlertMessage from "../../../alertNomina.component";
import { useHistory } from "react-router-dom";
import FormHelperText from "@material-ui/core/FormHelperText";
import { createStyles, Theme } from "@material-ui/core/styles";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useFormik } from "formik";
import logo_balnco from "../../../../../assets/lOGO2.png";
import {
  Grid,
  makeStyles,
  Card,
  CardContent,
  MenuItem,
  InputLabel,
  Select,
  CardActions,
  Button,
  CardHeader,
  FormControl,
  Box,
  TextField,
} from "@material-ui/core";
import { infoNomina } from "../forms";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function City({ nextStep }) {
  const apiClient = ApiClient.getInstance();
  const [options, setOptions] = useState<PabsPayment[]>([]);
  const [cantidades, setCantidades] = useState<PabsPayment[]>([]);
  const [ciudades, setCiudades] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [userLoan, setUserLoan] = useState({});
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("xl");
  const [isLoading2, setIsLoading2] = useState(false);
  const [changed, setChanged] = useState(false);
  const [init, setInit] = useState<infoNomina>();
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      formControl: {
        margin: theme.spacing(1),
        width: "50ch",
      },
      selectEmpty: {
        marginTop: theme.spacing(1),
      },
      root: {
        "& .MuiTextField-root": {
          margin: theme.spacing(1),
          width: "50ch",
        },
      },
      field: {
        minWidth: "180px",
      },
      backdrop: {
        color: "#fff",
        zIndex: theme.zIndex.appBar + 1,
      },
      dialogTitle: {
        backgroundColor: "#005593",
        color: "white",
        fontWeight: 700,
        textAlign: "center",
      },
    })
  );

  const classes = useStyles();
  const validationSchema = Yup.object().shape({
    city: Yup.string().required("Requerido"),
    cliente: Yup.string().required("Requerido"),
  });

  useEffect(() => {
    //const creditID = checkActiveCredit();
    //if (creditID) history.push("/productos");

    apiClient
      .getCityNomina()
      .then((ciudades) => {
        setCiudades(ciudades);
        console.log(ciudades);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    apiClient
      .getProducts(
        parseInt(localStorage.getItem("upper")),
        parseInt(localStorage.getItem("lower"))
      )
      .then((data: any) => {
        console.log("OPCIONES: ", data);

        const arr1 = getUniqueListBy(data, "capital");
        console.log("reduceValue: ", arr1);
        setOptions(data);
        setCantidades(arr1);
      });
  }, []);

  function getUniqueListBy(arr, key): any {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  const createNomina = (values: any) => {
    setLoading(true);
    console.log(values);

    console.log("ciudad", values.cliente, values.city, values.ciudad, values.y);

    apiClient
      .getNominaTipo(values.cliente, values.city, "nomina")
      .then((res) => {
        setLoading(false);
        nextStep();
      })
      .catch((err) => {
        console.log("err.message: ", err);
        if (err.message == "crédito activo con saldo.") {
          history.push("/RECHAZADO-nomina/nomina/credito-activo-con-saldo");
        } else {
          history.push("/RECHAZADO-nomina/nomina/not-found");
        }
        //nextStep();
        // setLoading(false);
        // setOpenMessage(true);
        // setMessage(err.message);
        // setSeverity("error");
      });
  };
  const [cantidad, setCantidad] = useState();
  const [filtroOpciones, setFiltroOpciones] = useState([]);
  const onchangeProduct = (e) => {
    setCantidad(e.target.value);
    const search = options.filter((value) => value.capital == e.target.value);

    setFiltroOpciones(search);
  };
  const handleClose = () => {
    setOpenMessage(false);
  };

  const handleClose2 = () => {
    if (!changed) {
      //      formik2.setValues(init);
    }
    setOpen(false);
  };

  const setData = async (data: any) => {
    setInit({
      userDetails: {
        firstName: data.userDetails.firstName,
        secondName: data.userDetails.secondName,
        birthDate: data.userDetails.birthDate.substr(0, 10),
        lastFName: data.userDetails.lastFName,
        lastMName: data.userDetails.lastMName,
      },
    });
    formik2.setValues({
      userDetails: {
        firstName: data.userDetails.firstName,
        secondName: data.userDetails.secondName,
        birthDate: data.userDetails.birthDate,
        lastFName: data.userDetails.lastFName,
        lastMName: data.userDetails.lastMName,
      },
    });
  };

  const formik2 = useFormik({
    initialValues: {
      userDetails: {
        lastFName: "",
        lastMName: "",
        firstName: "",
        secondName: "",
        birthDate: "",
      },
    },
    validationSchema: Yup.object().shape({
      address: Yup.object().shape({
        postalCode: Yup.string()
          .matches(/(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/)
          .required(),
        street: Yup.string().required(),
        exteriorNumber: Yup.string().required(),
        state: Yup.string().required(),
        city: Yup.string().required(),
        delegation: Yup.string().required(),
        suburb: Yup.string().required(),
      }),
      userDetails: Yup.object().shape({
        firstName: Yup.string().required(),
        lastFName: Yup.string().required(),
        lastMName: Yup.string().required(),
        birthDate: Yup.string().required(),
      }),
      rfc: Yup.string()
        .required("Requerido")
        .matches(/^[a-zA-Z]{3,4}(\d{6})((\D|\d){3})?$/, "Ingrese RFC valido"),
    }),
    onSubmit: (values) => {
      setIsLoading2(true);

      console.log(values);
      apiClient.updateInfoNomina(values).then((response) => {
        console.log(response);
        if (response.error) {
          setIsLoading2(false);
          formik2.setValues(init);
          setOpen(false);
          setOpenMessage(true);
          setMessage(response.error.message);
          setSeverity("error");
        }
        setData(response);
        setIsLoading2(false);
        setOpen(false);
        setOpenMessage(true);
        setMessage("Datos actualizados correctamente");
        setSeverity("success");
      });
    },
  });
  const submit = () => {
    formik2.handleSubmit();
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />
      <div className="containerVA">
        <div className="containerVa productop empresas-margin">
          <p className="sp">Seleccione su ciudad</p>
        </div>

        <Formik
          initialValues={userLoan}
          onSubmit={createNomina}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, errors, touched, setFieldValue }) => (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div className="containerV">
                <div>
                  <label htmlFor="city">Ciudad</label>
                  <Field
                    as="select"
                    id="city"
                    name="city"
                    className={errors.city && touched.city && "invalidV"}
                  >
                    <option value="">Selecciona una opcion</option>

                    {ciudades.map((ciudad) => (
                      <option key={ciudad.id} value={ciudad.name}>
                        {ciudad.nameDescription}
                      </option>
                    ))}
                  </Field>
                </div>
                <div></div>

                <div className="numero">
                  <label htmlFor="cliente">Número de Empleado</label>
                  <Field
                    type="text"
                    id="cliente"
                    name="cliente"
                    className={errors.cliente && touched.cliente && "invalidV"}
                    onChange={(e) => {
                      const value = e.target.value || "";
                      setFieldValue(
                        "cliente",
                        value
                          .normalize("NFD")
                          .replace(/[\u0300-\u036f]/g, "")
                          .toUpperCase()
                      );
                    }}
                  />
                </div>
                <div className="numero">
                  <Button variant="outlined" onClick={handleClickOpen}>
                    Validar información
                  </Button>
                </div>
              </div>

              <div className="ButtonV">
                <button className="btn blue" type="submit" disabled={loading}>
                  <ProgressCircular loading={loading} title="Continuar" />
                </button>
              </div>
              <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                fullScreen={fullScreen}
                maxWidth={maxWidth}
                aria-describedby="alert-dialog-slide-description"
              >
                <Backdrop className={classes.backdrop} open={isLoading2}>
                  <img
                    src={logo_balnco}
                    alt="PRADO'S"
                    className="img-fluid p-3 img-client"
                    width="300"
                  />
                  <CircularProgress color="primary" size={80} />
                </Backdrop>
                <DialogTitle className={classes.dialogTitle}>
                  {" "}
                  <Grid container spacing={3}>
                    <Grid item xs={4} md={2}></Grid>
                    <Grid item xs={4} md={8}>
                      {"¿Tus datos son correctos?"}
                    </Grid>
                    <Grid item xs={4} md={2}>
                      <IconButton
                        aria-label="delete"
                        color="inherit"
                        onClick={handleClose2}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </DialogTitle>

                <DialogContent>
                  <div className="d-flex justify-content-center mt-1 container">
                    <div className="card p-1 card-redounded-lg shadow ">
                      <div className="container">
                        <form id="div-perfil" onSubmit={formik2.handleSubmit}>
                          <p className="txt-blue-strong">
                            Información personal
                          </p>
                          <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                              <div className="mb-1">
                                <label
                                  htmlFor="firstName"
                                  className="form-label sol1-tittle"
                                >
                                  Nombre (s)*{" "}
                                </label>
                                <input
                                  type="input"
                                  className={
                                    Boolean(
                                      formik2.errors.userDetails?.firstName
                                    )
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  id="userDetails.firstName"
                                  name="userDetails.firstName"
                                  aria-describedby="emailHelp"
                                  value={formik2.values.userDetails?.firstName}
                                  onChange={formik2.handleChange}
                                />
                              </div>

                              <div className="mb-1">
                                <label
                                  htmlFor="lastFName"
                                  className="form-label sol1-tittle"
                                >
                                  Apellido paterno *
                                </label>
                                <input
                                  type="input"
                                  className={
                                    Boolean(
                                      formik2.errors.userDetails?.lastFName
                                    )
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  id="userDetails.lastFName"
                                  aria-describedby="emailHelp"
                                  value={formik2.values.userDetails?.lastFName}
                                  onChange={formik2.handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                              <div className="mb-1">
                                <label
                                  htmlFor="secondName"
                                  className="form-label sol1-tittle"
                                >
                                  Segundo nombre *
                                </label>
                                <input
                                  type="input"
                                  className={
                                    Boolean(
                                      formik2.errors.userDetails?.secondName
                                    )
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  id="userDetails.secondName"
                                  value={formik2.values.userDetails?.secondName}
                                  onChange={formik2.handleChange}
                                />
                              </div>
                              <div className="mb-1">
                                <label
                                  htmlFor="lastMName"
                                  className="form-label sol1-tittle"
                                >
                                  Apellido materno *
                                </label>
                                <input
                                  type="input"
                                  className={
                                    Boolean(
                                      formik2.errors.userDetails?.lastMName
                                    )
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  id="userDetails.lastMName"
                                  value={formik2.values.userDetails?.lastMName}
                                  onChange={formik2.handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="secondName"
                                  className="form-label sol1-tittle"
                                >
                                  Fecha de nacimiento *
                                </label>
                                <input
                                  type="date"
                                  className={
                                    Boolean(
                                      formik2.errors.userDetails?.birthDate
                                    )
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  id="userDetails.birthDate"
                                  value={formik2.values.userDetails?.birthDate.substring(
                                    0,
                                    10
                                  )}
                                  onChange={formik2.handleChange}
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    disabled={changed}
                    onClick={submit}
                  >
                    Guardar
                  </Button>
                  <Button variant="contained" onClick={handleClose2}>
                    Descartar
                  </Button>
                </DialogActions>
              </Dialog>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default City;
