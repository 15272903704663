import React, { useEffect, useState } from "react";
import "./rechazado.css";
import checked from "../../../../../../assets/circle-x.png";
import Header from "../../../../header.Nomina.component";
import {useHistory, useParams} from "react-router-dom";

function Rechazado(){
  const history = useHistory();
  function back(){
    var credito = '';
    if(localStorage.getItem('userLocal')){
      var user = JSON.parse(localStorage.getItem('userLocal'));
      credito = user.creditoNomina
    }
    if(credito == 'nomina'){
      history.push('/Nomina')
    } else if(credito == 'celular'){
      history.push('/Celular')
    } else if(credito == 'solarum'){
      history.push('/Tequila')
    }
    history.push("/productos-nomina");

  }

  let params = useParams<any>();
  const [reason, setReason] = useState('')
  
  const [cellphone, setCellphone] = useState('')
  const [whatsapp, setWhatsapp] = useState('')


  useEffect(() => {
    if(params.reason){
        setReason(params.reason)
    }

    setCellphone('3336168059');
    setWhatsapp(params.type === 'nomina' ? '3321931358' : '3315206918');
}, [])

const reasonText = (reason)=>{
  const objectReason = {
    'city-not-found': 'Pronto estaremos en tu ciudad con créditos con Titulo PABS.',
    'validated-user': 'El contrato registrado ya fue utilizado, si tiene alguno otro vigente favor de agregarlo.',
    'contract-not-found': 'El contrato no existe.',
    'user-does-not-match': 'El nombre regisrado no coincide con el número de contrato. Favor de verificar los datos.',
    'suspended-account': 'El título registrado está en Suspensión. ¿Cuenta con otro titulo vigente?',
    'major-balance': 'El título registrado cuenta con un saldo mayor a $2,000.00. ¿Cuenta con otro titulo pagado o saldo menor a $2,000.00?',
    'loan-denied': 'Prestamo denegado.',
    'credito-activo-con-saldo': 'Crédito activo con saldo.',
    'rejection' : '  '
  }
  return objectReason[reason] ? objectReason[reason] : 'No se ha podido continuar con la solicitud.'
}

    return (

      <div>
        <Header/>

        <div className="containerVR">

          <img src={checked} alt="" />
          <h3>{params.type === 'nomina' ? '¡Lo sentimos! No pudimos validar tu información.':'Empleado no sujeto a crédito por el momento'}</h3>
          <p>
          Te ofrecemos las siguientes alternativas:
          </p>
          <p>
              {params.type === 'nomina' 
                ? '1. Verifica que los datos capturados estén correctos (Nombre, ciudad, empresa y número de empleado)' 
                : ''
              }
              
          </p>

          <p> {params.type === "nomina" ? '2.' : ''} {reasonText(reason)}
          
          </p>

          <p>
          {params.type === "nomina" ? '3.' : '1.'}  Para mayor información comunícate al {cellphone} o manda whatsapp al {whatsapp}
          </p>


          <div className="solicitud-b__bottom button-center">
           
            <button className="btn blue" onClick={back} >
              Regresar
            </button>
          </div>
    
          
    
        </div>
      </div>
        
      );


}

export default Rechazado;
