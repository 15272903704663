import React, { useState, useEffect, SetStateAction } from "react";
import UploadBox from "../../../credit-forms/components/uploadBox";
import Desk from "../../../../../assets/desk.png";

import { FileUpload } from "../../../../../interfaces/fileInterace";
import { ProgressCircular } from "../../Progress";
import AlertMessage from "../../../alertNomina.component";

interface IneProps {
  fileLoaded: any;
  setMessage: React.Dispatch<SetStateAction<string>>;
  setOpenMessage: React.Dispatch<SetStateAction<boolean>>;
  setSeverity: React.Dispatch<SetStateAction<string>>;
  callback: React.Dispatch<SetStateAction<object>>;
  loading: boolean;
  file: Array<FileUpload>;
}

export default function Ine({
  fileLoaded,
  loading,
  callback: sendData,
  file,
}: IneProps) {
  const [collapsed, setCollapsed] = useState(false);
  const [ineFileFrente, setIneFileFrente] = useState<FileUpload>();
  const [ineFileReverso, setIneFileReverso] = useState<FileUpload>();
  const [ineSelfieFile, setIneSelfieFile] = useState<FileUpload>();
  const [proofOfAddressFile, setProofOfAddressFile] = useState<FileUpload>();
  const [proofOfIncome, setProofOfIncome] = useState<FileUpload>();
  const [error, setError] = useState(false);
  const [incomplete, setIncomplete] = useState(true);
  const [avisoDePrivacidad, setavisoDePrivacidad] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [openMessage, setOpenMessage] = useState(false);

  const handleChange = () => {
    setavisoDePrivacidad(!avisoDePrivacidad);
  };

  const handleClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
  };

  const onSubmit = () => {
    if (!ineFileFrente || !ineSelfieFile || !proofOfAddressFile || !proofOfIncome || !ineFileReverso) {
      setMessage("Es necesario subir los documentos solicitados");
      setSeverity("error");
      setOpenMessage(true);
      setIncomplete(true);
      setError(false);
      return;
    }
    if (!avisoDePrivacidad) {
      setMessage("Tiene que aceptar los terminos y condiciones");
      setSeverity("error");
      setOpenMessage(true);
      setError(true);
      return;
    }
    setError(false);
    setIncomplete(false);
    setCollapsed(true);
    fileLoaded([ineFileFrente, ineFileReverso, ineSelfieFile, proofOfAddressFile, proofOfIncome]);
  };
  useEffect(() => {
    sendData(file);
  }, [file]);

  return (
    <div className="solicitud-b__inciso">
      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />
      <div className="incisio-header">
        {/* {error && collapsed ? (
          <img src={Error} alt="Error icon" />
        ) : !error && collapsed && incomplete ? (
          <img src={Warning} alt="Warning icon" />
        ) : !error && collapsed && !incomplete ? (
          <img src={GreenChecked} alt="Success icon" />
        ) : (
          ""
        )} */}
      </div>

      <div
        id="1"
        className={
          "solicitud-b__inciso-content"
        }
        style={{justifyContent: "center"}}
      >
        <div className="files-accordeon">
        <UploadBox
            propKey="ifeIneFrente"
            mimeType="image/*,application/pdf"
            file={ineFileFrente}
            setter={setIneFileFrente}
            message="Foto de tu INE o IFE (frente)*"
          />

          <UploadBox
            propKey="ifeIneReverso"
            mimeType="image/*,application/pdf"
            file={ineFileReverso}
            setter={setIneFileReverso}
            message="Foto de tu INE o IFE (reverso)*"
          />

          <UploadBox
            propKey="selfieIne"
            mimeType="image/*,application/pdf"
            file={ineSelfieFile}
            setter={setIneSelfieFile}
            message="Selfie con tu INE o IFE *"
          />

          <UploadBox
            propKey="proofOfAdress"
            mimeType="image/*,application/pdf"
            file={proofOfAddressFile}
            setter={setProofOfAddressFile}
            message="Comprobante de domicilio *"
          />

          <UploadBox
            propKey="BossRequest"
            mimeType="image/*,application/pdf"
            file={proofOfIncome}
            setter={setProofOfIncome}
            message="Solicitud autorizada *"
          />
        </div>
        
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* <div>
          <label>
            <input
              style={{ float: "left", width: "20px" }}
              type="checkbox"
              checked={avisoDePrivacidad}
              onChange={handleChange}
            />
          </label>
          <span
            style={{
              fontSize: 18,
              marginLeft: 5,
              textAlign: "center",
              width: "500px",
            }}
          >
            He leído y acepto el
            <a
              href="/aviso-de-privacidad"
              target="_blank"
              style={{ fontSize: 18, marginLeft: 5 }}
            >
              AVISO DE PRIVACIDAD
            </a>
            .
          </span>
        </div> */}
        <div style={{marginBottom: 10, marginTop: 20}}>
            <label>
              <input style={{ float: 'left', width: '15px' }} type="checkbox"
                checked={avisoDePrivacidad}
                onChange={handleChange}
              />
            </label>
            <strong style={{ fontSize: 16, marginLeft: 5 }}>
              Certifico que los datos proporcionados son verídicos y que actúo en nombre propio al solicitar este crédito.
            </strong>

          </div>
              <span style={{ fontSize: 14, marginLeft: 5 }}>
                Probenso Group, Sociedad Anónima, Sociedad Financiera  de Objeto Múltipe, Entidad No Regulada (E.N.R.), con domicilio en Ures Sur 137, Colonia Centro, Tepic, 63000, Nayarit, México es el responsable de sus datos personales.Utilizaremos tus datos personales para los siguientes fines indispensables: (i) Crear tu cuenta de usuario en la plataforma alojada en https://percapita.mx/; (ii) Identificarte como usuario de la plataforma alojada en https://percapita.mx/; (iii) Generar el expediente de solicitud de tu crédito; (iv) Consultar tu historial y capacidad crediticia;  (v) Decidir si se aprueba o rechaza tu solicitud  de crédito, y; (vi) Firmar los contratos de crédito que sean aprobados.Para conocer el aviso de privacidad integral, por favor haz <a href="/aviso-de-privacidad" target="_blank" style={{ fontSize: 14, marginLeft: 5 }}>click aquí.</a>
              </span>
        <button className="blue" onClick={onSubmit} disabled={loading}>
          <ProgressCircular loading={loading} title="Enviar solicitud" />
        </button>
      </div>
      {/* {collapsed && ( */}
      {/* )} */}
    </div>
  );
}
