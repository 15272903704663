import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import eye from '../../assets/eye.png';
import eyeHide from '../../assets/visibilidad-24.png';
import { UserContext } from '../../context/UserProvider';
import useLocalStorage from '../../hooks/local-storage';
import AlertMessage from './alertNomina.component';
import ApiClient from '../../services/apiclient';
import { ProgressCircular } from './pages/Progress';
import Swal from 'sweetalert2'

const publicIp = require('public-ip');

const FormRegister = (props: any) => {
  const history = useHistory();

  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [, setEmail] = useLocalStorage('email', null);

  const { userMain, updateUser } = React.useContext(UserContext);
  const userNombre = userMain.nombre;

  const [shown, setShown] = React.useState(false);
  const [publicIpV4, setPublicIpV4] = React.useState(null);

  const apiClient = ApiClient.getInstance();
  const [paso, setPaso] = useState('');

  const [user, setUser] = useState({
    phone: '',
    password: '',
    repassword: '',
  });

  const [detalle, setDetalle] = useState({
    nombreCompleto: '',
    last_f_name: '',
    last_m_name: '',
    fechaNacimiento: '',
  });
  useEffect(() => {
    if(localStorage.getItem('userLocal')){
      var userLocal = JSON.parse(localStorage.getItem('userLocal'));
    //console.log(userLocal)
        setPaso(userLocal.creditoNomina);
    }
      
  }, []);

    

  const getIp = async () => {
    try {
      var ip = await publicIp.v4();
      setPublicIpV4(ip)

    } catch (error) {
      setPublicIpV4('')
    }
  }

  useEffect(() => {
    getIp();
      
  }, []);

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    console.log(e.target.name)
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });

    setDetalle({ ...detalle, [e.target.name]: e.target.value });
    if(e.target.name === "phone" && e.target.value.length === 10){
      apiClient.validatePhone(e.target.value).then((data) => {
        if (data.error) {
          Swal.fire({
            title: "Telefono registrado",
            text: `Encontramos que tu teléfono ${e.target.value} ya ha sido registrado, te invitamos a iniciar sesión presionando el botón (Iniciar Sesión)`,
            icon: "warning",
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText:"Salir",
            confirmButtonText: "Iniciar Sesión"
          }).then((result) => {
            if (result.isConfirmed) {
              return history.push("/login-nomina")
            } else {
              return history.push("/")
            }
          });
          // setOpenMessage(true);
          // setMessage(
          //   "Lo sentimos, el telefono ingresado ya se encuentra registrado, si ya tienes una cuenta, inicia sesión"
          // );
          // setSeverity("error");
          // return;
        }
      });
    }
  };

  const userRegister = (e: { preventDefault: () => void }) => {
    e.preventDefault();


    if (userNombre !== 'Iniciar Sesión') {
      setOpenMessage(true);
      setMessage('No se puede crear una cuenta nueva, cerrar sesion para continuar con el proceso');
      setSeverity('error');
      return;
    }
    // dar formato a fecha de naciemiento del usuario

    if (user.phone === '') {
      setOpenMessage(true);
      setMessage(
        'El número de teléfono del usuario es requerido. Favor de verificar',
      );
      setSeverity('error');
      return;
    }

    var re = /^(\(\+?\d{2,3}\)[\*|\s|\-|\.]?(([\d][\*|\s|\-|\.]?){6})(([\d][\s|\-|\.]?){2})?|(\+?[\d][\s|\-|\.]?){8}(([\d][\s|\-|\.]?){2}(([\d][\s|\-|\.]?){2})?)?)$/;
    let validar = re.test(String(user.phone).toLowerCase());

    if (!validar) {
      setOpenMessage(true);
      setMessage('Ingrese un número de teléfono válido. Favor de verificar.');
      setSeverity('error');
      return;
    }

    if (user.password === '') {
      setOpenMessage(true);
      setMessage('La contraseña del usuario es requerido. Favor de verificar');
      setSeverity('error');
      return;
    }
    if (user.repassword === '') {
      setOpenMessage(true);
      setMessage('Favor de confirmar contraseña');
      setSeverity('error');
      return;
    }
    if (user.password !== user.repassword) {
      setOpenMessage(true);
      setMessage('La contraseñas deben coincidir');
      setSeverity('error');
      return;
    }

    setLoading(true);
    apiClient.signup(user.phone, user.password)
      .then(res => res.json())
      .then(data => {
        localStorage.setItem('cxdc',user.phone)
        localStorage.setItem('fwcd',user.password)
        if (data.error) {
          setLoading(false);
          localStorage.clear();
          setOpenMessage(true);
          setMessage(data.status >= 500 ? 'Error del servidor' : data.error.message);
          setSeverity('error');
          return;
        }
        setLoading(false);
        setOpenMessage(true);
        setMessage('Registro realizado correctamente');
        setSeverity('success');
        
        if(paso === 'nomina' || paso === ''){
          updateUser({ ...userMain, nombre: 'Iniciar Sesión', apellidoPaterno: '', creditoNomina:"nomina" });
          return history.push({
            pathname:"/step2-nomina",
            state: {tipo: "nomina"}            
        });
        
                  
        } else if(paso === 'solarum'){
          updateUser({ ...userMain, nombre: 'Iniciar Sesión', apellidoPaterno: '', creditoNomina:"solarum" });
          return history.push({
            pathname:"/step2-nomina",
            state: {tipo: "solarum"}
        });
        } else if(paso === 'celular'){
          updateUser({ ...userMain, nombre: 'Iniciar Sesión', apellidoPaterno: '', creditoNomina:"celular" });
          return history.push({
            pathname:"/step2-nomina",
            state: {tipo: "celular"}
        });
        }
        
      }).catch(err => {
        setLoading(false);
        setOpenMessage(true);
        setMessage('Error en el servidor');
        setSeverity('error');
      });

  };

  // useEffect(() => {
  //   console.log(props);
  // }, [props]);

  const handleClose = () => {
    setOpenMessage(false);
  };


  const [loading, setLoading] = useState(false);

  const switchShown = () => setShown(!shown);

  return (
    <>
      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />
      <form className="landing-form">

        <div>
          <label htmlFor="phone">Número de telefono</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            onChange={handleInputChange}
            placeholder="Número de teléfono"
          />
        </div>
        <div>
          <label htmlFor="password">Contraseña</label>
          <div className="password-input">
            <input
              id="password"
              name="password"
              type={shown ? 'text' : 'password'}
              onChange={handleInputChange}
            />

            {props.type !== 'landing' && (
              <Link to="#">
                {' '}
                <img src={shown ? eyeHide : eye} onClick={switchShown} />
              </Link>
            )}
          </div>

          {props.type !== 'landing' && (
            <span id="pass-label">
              {shown ? 'Ocultar Contraseña' : 'Mostrar contraseña desactivado'}
            </span>
          )}
        </div>
        <div>
          <label htmlFor="password">Confirmar Contraseña</label>
          <div className="password-input">
            <input
              id="repassword"
              name="repassword"
              type={shown ? 'text' : 'password'}
              onChange={handleInputChange}
            />

            {props.type !== 'landing' && (
              <Link to="#">
                {' '}
                <img src={shown ? eyeHide : eye} onClick={switchShown} />
              </Link>
            )}
          </div>

          {props.type !== 'landing' && (
            <span id="pass-label">
              {shown ? 'Ocultar Contraseña' : 'Mostrar contraseña desactivado'}
            </span>
          )}
        </div>
      </form>

      {props?.type !== 'landing' ? (
        <div>
          <p>
            Para terminar este paso, deberás verificar tu numero celular con un codigo que
            te enviaremos (Necesitamos checar la autenticidad de cada registro
            para mayor seguridad)
          </p>
          <button className="blue center-div" onClick={userRegister}>
            Continuar
          </button>
        </div>
      ) : (
        <button
          className="blue landing-first-section-btn"
          type="button"
          onClick={userRegister}
          disabled={loading}
        >
            <ProgressCircular loading={loading} title=' Solicitar mi préstamo'/>

        </button>
      )}
    </>
  );
};

export default FormRegister;
