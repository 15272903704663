import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import logo from '../../assets/lOGO2.png';
import { TramiteCreditoContext } from '../../context/TramiteCreditoProvider';
import { UserContext } from '../../context/UserProvider';

const useStyles = makeStyles({
  root: {
    width: '250px',
  },
});

function HeaderDashboard() {
  const history = useHistory();
  const { userMain, updateUser } = React.useContext(UserContext);
  const { credito, updateCredito } = React.useContext(TramiteCreditoContext);
  const location = useLocation();

  function handleClick(e: { preventDefault: () => void }) {
    e.preventDefault();
    localStorage.clear();
    updateUser({ ...userMain, nombre: 'Iniciar Sesión', apellidoPaterno: '',creditoNomina:'' });
    return history.push('/login-nomina');
  }

  function redirecComponent(e: { preventDefault: () => void }) {
    e.preventDefault();
    if (credito.estatusSolicitud === 'aprobado') {
      if (credito.isDelivered) {
        return history.push('/credito-detalle-nomina');
      } else if (!credito.isDelivered) {
        return history.push('/dashboard2');
      }
    } else if (credito.estatusSolicitud === 'revision') {
      return history.push('/proceso-credito-nomina');
    } else if (credito.estatusSolicitud === 'sin-solicitud') {
      // return history.push('/sin-credito');
      return history.push('/type-credit-application-nomina');
    } else if (credito.estatusSolicitud === 'solicitud-no-aprobada') {
      return history.push('/sin-permiso-tramite-nomina');
    }
  }

  const [routes, setRoutes] = React.useState({
    root: "/",
    estado: "/estado-cuenta-nomina",
    login: "/login-nomina",
  });

  const classes = useStyles();

  const [mobileNav, setMobileNav] = React.useState(false);

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setMobileNav(open);
  };

  return (
    <nav className="navigation-bar">
      <SwipeableDrawer
        classes={classes}
        anchor="right"
        open={mobileNav}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <List>
          <ListItem button>
            <Link to="/">
              <img className="logo" src={logo} />
            </Link>
          </ListItem>
          <Divider />
          <ListItem button>
            <Link to="#" className={(location.pathname != routes.estado) ? 'active' : ''} onClick={redirecComponent}>
              Mi préstamo
            </Link>
          </ListItem>

          {credito.estatusSolicitud === 'aprobado' ? (<ListItem button><Link to="/estado-cuenta" className={(location.pathname === routes.estado ? "active" : "")}>Estado de cuenta</Link></ListItem>) : ''}

          <ListItem button>
            <Link to="#" className="separator">
              {userMain.nombre}
            </Link>
          </ListItem>
          <ListItem button>
            <Link to="#" onClick={handleClick}>
              <i className="fas fa-sign-out-alt"></i> Cerrar Sesión
            </Link>
          </ListItem>
        </List>
      </SwipeableDrawer>
      <Link to="/">
        <img className="logo" src={logo} />
      </Link>
      <div className="dash-nav-items-mobile-hide">
        <Link to="#" className={(location.pathname != routes.estado) ? 'active' : ''} onClick={redirecComponent}>
          Mi préstamo
        </Link>
        {credito.estatusSolicitud === 'aprobado' ? (<Link to="/estado-cuenta" className={(location.pathname === routes.estado ? "active" : "")}>Estado de cuenta</Link>) : ''}

        <Link to="#" onClick={handleClick}>
          <i className="fas fa-sign-out-alt"></i> Cerrar Sesión
        </Link>
      </div>
      <i onClick={toggleDrawer(true)} className="fas fa-bars mobile-btn"></i>
    </nav>
  );
}

export default HeaderDashboard;
