import { useEffect, useState } from "react";
import ApiClient from "../../../../../services/apiclient";
import AlertMessage from "../../../../alert.component";
import "./Information.css";
import PersonalForm from "./personal-form";

export default function Informacion({ nextStep }) {
  const [loading, setLoading] = useState(false);
  const [employeeNumber, setEmployeeNumber] = useState(null);
  const apiClient = ApiClient.getInstance();
  const [openMessage, setOpenMessage] = useState(false);
  const [personalData, setPersonalData] = useState({});

  useEffect(() => {
    apiClient
      .getProductsNomina('celular')
      .then((res) => {
        console.log("getEmpleadoNumber: ", res.employeeCode);
        setEmployeeNumber(res.employeeCode);
      })
      .catch((err) => {
        console.log("errorr: ", err.message);
        setLoading(false);
      });
  }, []);

  const handleSendDate = () => {
    console.log(employeeNumber, "handle");
    setLoading(true);
    console.log({ ...personalData, employeeNumber: employeeNumber });
    apiClient
      .updateInformationProduct({ ...personalData, employeeNumber: employeeNumber },'celular')
      .then((res) => {
        if (res.id) {
          localStorage.setItem("NOMINA_ID", res.id);
        }
        setLoading(false);
        nextStep();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <div>
      <AlertMessage open={openMessage} />
      <div className="solicitud__container">
        <div className="solicitud-b__container">
          <PersonalForm
            callback={handleSendDate}
            setPersonalData={setPersonalData}
            personaldata={personalData}
            firstFile="proofOfAdress"
            secondFile="BossRequest"
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
}
