import React, { useEffect, useState } from 'react';
import icons from '../../assets/icons.png';
import Header from '../header.component';
import AlertMessage from "../alert.component";
import useLocalStorage from "../../hooks/local-storage";
import { Link, useHistory } from 'react-router-dom';
import ApiClient from '../../services/apiclient';
import { useFormik } from 'formik';
import { UserContext } from "./../../context/UserProvider";

//function RequestForm2() {
const RequestForm2 = (props: any) =>  {

  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [email, setEmail] = useLocalStorage('email', null);
  const [code, setCode] = useState('');
  const { userMain, updateUser } = React.useContext(UserContext);

  const apiClient = ApiClient.getInstance();

  const history = useHistory();


  const validateCode = () => {


    apiClient.activateAccountSMS(codeForm.values.code).then(data => {
      setOpenMessage(true);
      setMessage('Tu cuenta ha sido activada');
      //history.push('/login-nomina');
      //do Login
      console.log(localStorage.getItem('cxdc') , localStorage.getItem('fwcd'))
      if(localStorage.getItem('cxdc') && localStorage.getItem('fwcd')){
        console.log(data)
      apiClient
      .login(localStorage.getItem('cxdc'),localStorage.getItem('fwcd'))
      .then((res) => res.json())
      .then(async (a) => {
        console.log("esteps",a)
        localStorage.removeItem('cxdc')
        localStorage.removeItem('fwcd')
        if (data.error) {
          localStorage.clear();
          setOpenMessage(true);
          const statusCode = a.error?.status || a.error.statusCode;
          setMessage(statusCode === 401 ? 'Telefono o contraseña invalidos' : data.error.message);
          setSeverity("error");

          if(statusCode === 403)
            return history.push("/step2");
            else return history.push("/login");
        }

        localStorage.setItem("TOKEN", a.token);
        localStorage.setItem("USER_ID", a.userId);

        apiClient.token = a.token;
        apiClient.userId = a.userId;
        if (data.fullName) {
          updateUser({
            ...userMain,
            nombre: a.fullName || a.fullName,
            telefono: a.phone,
          });
        } else {
          updateUser({
            ...userMain,
            telefono: a.phone,
          });
        }
          return history.push("/register-form");
      })
      }
      return history.push("/login");
    }).catch(reason => {
      setOpenMessage(true);
      setMessage(reason.message);
      setSeverity('error');
    });

  }


  const codeForm = useFormik({

    initialValues: {
      code: '',
    },
    onSubmit: values => {
    },

  });

  const handleClose = () => {
    setOpenMessage(false);
  }


  return (
    <div>
      <Header />
      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />
      <div className="solicitud__container">
        <div className="solicitud-2__content">
          <div className="solicitud-1__tabs">
            <Link to="#" className="active">
              1. Regístrate
            </Link>
            <Link to="#">2. Llena tu información personal</Link>
          </div>
          <h1>¡Necesitas verificar tu número de teléfono para continuar!</h1>
          <span>
            Se envío un código por SMS a tu número de teléfono, ingresalo aquí para validar tu identidad.
          </span>

          <form className="solicitud-2__form">
            <input
              type="text"
              placeholder="Código"
              name="code"
              id="code"
              onChange={codeForm.handleChange}
              value={codeForm.values.code}
            />
          </form>

          <button onClick={validateCode} className="blue">Válidar cuenta</button>
          <img src={icons} alt="Sitio web de percapita" />
        </div>
      </div>
    </div >
  );
}

export default RequestForm2;
