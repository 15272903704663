import { useFormik } from "formik";
import { FORM_SECTION_5 } from "../forms";
import Work from "../../../../assets/work.png";
import { useState } from "react";
import actividadesEconomicas from "./actividadesEconomicas";
import Error from "../../../../assets/error.png";
import GreenChecked from "../../../../../assets/green-cheked.png";
import Warning from "../../../../../assets/warning.png";
import AlertMessage from "../../../alertNomina.component";
import { useEffect } from "react";
import { ProgressCircular } from "../../Progress";
import ApiClient from "../../../../../services/apiclient";

export default function EmpleoForm({
  setLabor,
  employeeNumber,
  loading,
  callback: sendData,
  laborData,
  job
}) {
  const formSection5 = useFormik(FORM_SECTION_5);
  const [collapsed, setCollapsed] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [error, setError] = useState(false);
  const [incomplete, setIncomplete] = useState(true);
  const apiClient = ApiClient.getInstance();

  const handleClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
  };

  const submitData = () => {
    console.log(formSection5);
    if (!formSection5.values.antiquityMonth) {
      formSection5.values.antiquityMonth = 0;
    }
    if (!formSection5.isValid) {
      console.log(formSection5.values);
      setMessage("El formulario contiene errores, favor de verificar.");
      setSeverity("error");
      setOpenMessage(true);
      setLabor(undefined);
      setError(true);
      setIncomplete(true);
      return;
    }
    if (
      formSection5.values.companyName === "" ||
      formSection5.values.jobPosition === ""
    ) {
      setMessage("Los datos de empleo son obligatorios.");
      setSeverity("error");
      setOpenMessage(true);
      setIncomplete(true);
      setError(false);
      setLabor(undefined);
      return;
    }
    setError(false);
    setIncomplete(false);
    setLabor(formSection5.values);
    setCollapsed(true);
  };
  useEffect(() => {
    sendData(laborData);
  }, [laborData]);

  useEffect(() => {
    apiClient.getUserPreInfo().then((data) => {
      if (!data.error) {
        formSection5.setFieldValue("companyName", data.companyName);
        formSection5.setFieldValue("jobPosition", data.jobPosition);
        formSection5.setFieldValue("immediateBoss", data.immediateBoss);
      }
    });
  }, []);
  useEffect(() => {
    apiClient.getActiveCreditNomina("nomina").then((creditNomina) => {
      let val = '';
      if(creditNomina.city !== 'FUN-GUADALAJARA') {
        val = `PABS-${creditNomina.city}`
      } else {
        val = creditNomina.city
      }
      formSection5.setFieldValue("companyName",
                val
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toUpperCase()
              );
    })              
  },[]);
  return (
    <div id="" className="solicitud-b__container">
      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />

      <div
        className="incisio-header"
        style={{
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {/* {error && collapsed ? (
          <img src={Error} alt="Error icon" />
        ) : !error && collapsed && incomplete ? (
          <img src={Warning} alt="Warning icon" />
        ) : !error && collapsed && !incomplete ? (
          <img src={GreenChecked} alt="Success icon" />
        ) : (
          ""
        )} */}
      </div>
      <div className="solicitud-4__input-group informacionC">
        <div>
          <label htmlFor="companyName">Nombre de la compañía</label>
          <input
            tabIndex={22}
            type="text"
            id="companyName"
            name="companyName"
            placeholder="Ej."
            // onChange={(e) => {
            //   const value = e.target.value || "";
            //   formSection5.setFieldValue(
            //     "companyName",
            //     value
            //       .normalize("NFD")
            //       .replace(/[\u0300-\u036f]/g, "")
            //       .toUpperCase()
            //   );
            // }}
            disabled
            value={formSection5.values.companyName}
          />
        </div>

        {/* <div>
            <label htmlFor="salary">Sueldo *</label>
            <input
              tabIndex={23}
              type="text"
              id="salary"
              name="salary"
              placeholder="Ej."
              pattern="[0-9]+\.?[0-9]{0,2}"
              onChange={formSection5.handleChange}
              value={formSection5.values.salary}
            />
          </div>

          <div>
            <label htmlFor="economicActivity">Actividad Económica *</label>
            <select
              tabIndex={26}
              className="input-select"
              id="economicActivity"
              name="economicActivity"
              onChange={formSection5.handleChange}
              value={formSection5.values.economicActivity}
            >
              <option value="">Seleccione una opción</option>
              {actividadesEconomicas.map((actividad: any) => (
                <option key={actividad} value={actividad}>
                  {actividad}
                </option>
              ))}
            </select>
          </div> */}

        <div>
          <label htmlFor="companyRole">Puesto de trabajo *</label>
          <input
            tabIndex={24}
            type="text"
            id="jobPosition"
            name="jobPosition"
            placeholder="Ej."
            onChange={(e) => {
              const value = e.target.value || "";
              formSection5.setFieldValue(
                "jobPosition",
                value
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toUpperCase()
              );
            }}
            value={formSection5.values.jobPosition}
          />
        </div>

        {/* <div>
            <label htmlFor="antiquity">Antigüedad</label>
            <div className="domicilioTime">
              <input
                tabIndex={25}
                className="domicilioTimeI"
                type="number"
                id="antiquity"
                name="antiquity"
                placeholder="Años"
                pattern="[0-9]+"
                onChange={formSection5.handleChange}
                value={formSection5.values.antiquity}
              />
              <input
                className="domicilioTimeI"
                tabIndex={25}
                type="number"
                id="antiquityMonth"
                name="antiquityMonth"
                placeholder="Meses"
                pattern="[0-9]+"
                onChange={formSection5.handleChange}
                value={formSection5.values.antiquityMonth}
              />
            </div>
          </div>

          <div>
            <label htmlFor="companyPhone">Telefono *</label>
            <input
              tabIndex={27}
              type="text"
              id="phone"
              name="phone"
              placeholder="Ejemplo: 9999999999"
              pattern="[0-9]{0,10}"
              onChange={formSection5.handleChange}
              value={formSection5.values.phone}
            />
          </div> */}

        <div>
          <label htmlFor="companyRole">Jefe inmediato *</label>
          <input
            tabIndex={24}
            type="text"
            id="immediateBoss"
            name="immediateBoss"
            onChange={(e) => {
              const value = e.target.value || "";
              formSection5.setFieldValue(
                "immediateBoss",
                value
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toUpperCase()
              );
            }}
            value={formSection5.values.immediateBoss}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <button className="blue" onClick={submitData} disabled={loading}>
          <ProgressCircular loading={loading} title="Continuar" />
        </button>
      </div>
    </div>
  );
}
