import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../../context/UserProvider';
import AlertMessage from '../alertNomina.component';
import HeaderDashboard from '../headerDashboardNomina.component';

function TypeCreditApplication() {
  const history = useHistory();
  const { userMain, updateUser } = React.useContext(UserContext);

  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');

  const [shown, setShown] = useState(false);
  const [numEmpleado, setNumEmpleado] = useState('');

  const handleClose = () => {
    setOpenMessage(false);
  };

  const switchShown = () => setShown(!shown);

  var prestamoPersonal = function () {
    updateUser({
      ...userMain,
      esEmpleado:false,
    });
    return history.push('/step4-nomina');
  };

  var prestamoEmpleado = function () {

    if (numEmpleado === '') {
      setOpenMessage(true);
      setMessage('El número de empleado es requerido. Favor de verificar.');
      setSeverity('error');
      return;
    }

    fetch(
      process.env.REACT_APP_URL_API + '/num-employee-entities/' + numEmpleado,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
      .then(res => res.json())
      .then(data => {
        console.log(data)
        if (data.error) {
          setOpenMessage(true);
          setMessage('El número de empleado ingresado no existe. Favor de verificar.');
          setSeverity('error');
          return;
        } else {


          if (!data.active_employee) {
            setOpenMessage(true);
            setMessage('El empleado con el número ingresado, se encuentra dado de baja. Favor de verificar.');
            setSeverity('error');
            return;
          }

          var dataJson = {
            number: data.employee_number,
            userId: userMain.userId,
          };

          updateUserDetails(dataJson);

        }
      })
      .catch(error => console.log(error));

  }

  const updateUserDetails = (valor: any) => {

    const body = {
      employee_number: valor.number,
    };

    const userId = userMain.userId;

    fetch(process.env.REACT_APP_URL_API + '/user-detail/' + userId, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(res => {
        console.log(res);
        updateUser({
          ...userMain,
          esEmpleado:true,
        });
        setOpenMessage(true);
        setMessage('Se han validado al empleado correctamente.');
        setSeverity('success');
        setTimeout(() => {
          history.push('/credit-nomina');
        }, 1500);
      })
      .catch(err => console.error(err));
  };

  return (
    <div>
      <HeaderDashboard />
      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />

      <div className="dashboard__container">
        <div className="dashboard-user__header">
          <div>
            <h1>¡Hola {userMain.nombre}! Bienvenido a tu espacio</h1>
          </div>
        </div>

        <div className="dashboard-usuario-1__content">
          <div className="dashboard-usuario-1__card">
            <h3>Seleccione el tipo de préstamo de crédito a realizar:</h3>
            <div className="dashboard-usuario-2">
              <div className="dashboard-banck__small-cards-container">
                <div className="dashboard-banck__small-cards">
                  <div>
                    <button className="blue" onClick={prestamoPersonal}>
                      Préstamo Personal
                    </button>
                  </div>
                </div>

                <div className="dashboard-banck__small-cards">
                  <div>
                    <button className="blue" onClick={switchShown}>
                      Préstamo Nomina
                    </button>
                  </div>

                  {shown ? (

                    <div>
                      <label>Núm. Empleado</label>
                      <input
                        placeholder="Pro-01"
                        type="text"
                        id="tarjeta"
                        name="tarjeta"
                        style={{ width: '9vw' }}
                        onChange={e => setNumEmpleado((e.target.value))}
                      />
                      <button className="btn green" onClick={prestamoEmpleado}>Validar</button>
                    </div>

                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TypeCreditApplication;
