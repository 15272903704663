import { useEffect, useState } from "react";
import { connect }  from "react-redux";
import { useForm }  from "react-hook-form"; 
import { bindActionCreators } from "redux";

import ApiClient    from "../../../../../services/apiclient";
import { nextStep } from "../../../../../store/actions/PrestamosPersonalesActions";
import { ProgressCircular } from "../../../Progress";
import "./styles.css";
import Menu from "../Menu/Menu";
import { useHistory } from "react-router-dom";

const ReferencesForm = ({
  nominaId,
  nextStep,
}) => {

  const apiClient = ApiClient.getInstance();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit,reset, formState: { errors } } = useForm();
  const[preInfo,setPreInfo] = useState(null);

  const onSubmit = data => {

    const personalReferences = [
      { 
        name: data?.nameRef1,
        relationship: data?.relationShip1,
        contactNumber: data?.phoneRef1,
      },
      { 
        name: data?.nameRef2,
        relationship: data?.relationShip2,
        contactNumber: data?.phoneRef2,
      }
    ];

    const body = { personalReferences };

    setIsLoading(true)

    apiClient.referencesForm(body, nominaId)
    .then(data => {
      nextStep("QUESTION-PEPS")
      console.log(data)
    })
    .catch(e => {
      console.log(e)
      setIsLoading(false)
    })
    
  };

  // Redirect to /Veriff if user is not verified
  useEffect(() => {
    const checkUserVeriffValidated = async () => {
    
      const isValidatedRes = await apiClient.checkUserVeriffValidated();
      if(isValidatedRes.veriffValidated !== true) {
          history.push('/veriff?from=prestamo-personal');
          return;
      }
  
    };
    const timeout = setTimeout(() => {
      checkUserVeriffValidated();
    }, 500);

    return () => {
      clearTimeout(timeout);
    }
  }, []);

  useEffect(() => {

    apiClient.getUserPreInfo().then(data => {
      if(!data.error) {
        const userPreInfo = data;      
        setPreInfo(userPreInfo);
        reset(userPreInfo);
      }        
    });

  }, []);

  return (
    <div className="solicitud__container">
      <div className="solicitud-b__container">
        <form onSubmit={handleSubmit(onSubmit)} id="div-perfil">

          <Menu step="references" />

            <div id="4" className="solicitud-4__input-group informacionC">

                  <div>
                    <label htmlFor="nameRef1">Nombre completo</label>
                    <input
                      tabIndex={1}
                      type="text"
                      id="nameRef1"
                      name="nameRef1"
                      {...register("nameRef1")}

                      placeholder="Ej."
                    />
                  </div>

                  <div>
                      <label htmlFor="relationShip1">Parentesco *</label>
                      <select
                        tabIndex={29}
                        {...register("relationShip1", { required: true })}
                        className={errors.relationShip1 ? "input-select inputErr" : "input-select"}
                      >
                        <option value="">Seleccione una opción</option>
                        <option value="Familiar">Familiar</option>
                        <option value="Amistad">Amistad</option>
                      </select>
                  </div>

                    <div>
                        <label htmlFor="phoneRef1">Teléfono de contacto*</label>
                        <input
                            type="text"
                            id="phoneRef1"
                            name="phoneRef1"
                            placeholder="Ej."
                            {...register("phoneRef1", { required: true, pattern: /[0-9]+/ })}
                            className={errors.phoneRef1 ? "inputErr" : ""}
                        />
                    </div>

            </div>

          <p className="segunda-referencia">
            {" "}
            <b>Segunda referencia personal</b>{" "}
          </p>

            <div className="solicitud-4__input-group informacionC">
                <div>
                    <label htmlFor="nameRef2">Nombre completo</label>
                    <input
                      tabIndex={2}
                      type="text"
                      id="nameRef2"
                      name="nameRef2"
                      placeholder="Ej."
                      {...register("nameRef2")}
                    />
                </div>

                <div>
                      <label htmlFor="relationShip2">Parentesco *</label>
                      <select
                        tabIndex={29}
                        id="relationShip2"
                        name="relationShip2"
                        {...register("relationShip2", { required: true })}
                        className={errors.relationShip2 ? "input-select inputErr" : "input-select"}
                      >
                        <option value="">Seleccione una opción</option>
                        <option value="Familiar">Familiar</option>
                        <option value="Amistad">Amistad</option>
                      </select>
                </div>

                <div>
                  <label htmlFor="phoneRef2">Teléfono de contacto*</label>
                  <input
                    tabIndex={6}
                    type="text"
                    id="phoneRef2"
                    name="phoneRef2"
                    placeholder="Ej."
                    {...register("phoneRef2", { required: true, pattern: /[0-9]+/ })}
                    className={errors.phoneRef2 ? "inputErr" : ""}
                  />
                </div>

            </div>

              <div className="btnSubmitWrapReferences">
                <button
                  className="btn blue"
                  type="submit"
                  disabled={isLoading}
                >
                  <ProgressCircular loading={isLoading} title='Siguiente'/>
                </button>
              </div>          
        </form>

      </div>
    </div>
  )
};

const mapStateToProps = ({ personalCreditReducer }) => ({
  user: personalCreditReducer?.user ?? {},
  nominaId: personalCreditReducer?.nominaId ?? "",
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators ({
    nextStep,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferencesForm);
