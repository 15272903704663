import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import clock from "../../../../assets/clock.png";
import { TramiteCreditoContext } from "../../../../context/TramiteCreditoProvider";
import { UserContext } from "../../../../context/UserProvider";
import ApiClient from "../../../../services/apiclient";
import HeaderDashboard from "../../headerDashboardNomina.component";
import { io } from "socket.io-client";
import Popup from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import CreditType from "../credit-type/credit-type";
import { boolean } from "joi";
import PopUp from "../../popup/popupNomina";
import {
  Badge,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import AlertMessage from "../../alertNomina.component";
import MailIcon from "@material-ui/icons/Mail";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      marginTop: theme.spacing(3),
      padding: theme.spacing(2),
    },
  },
  itemA: {
    flex: "inherit",
    color: "#194b74",
    textAlign: "left",
  },
  itemC: {
    flex: "inherit",
    textAlign: "left",
  },
  spam: {
    textAlign: "left",
  },
  container: {
    textAlign: "right",
  },
  margin:{
    marginLeft: theme.spacing(1),
  }
}));

function CreditProgress() {
  const classes = useStyles();
  // const io = require("socket.io-client");
  //"https://dev.percapita.mx/percapitaSocket"
  console.log(
    `${
      process.env.REACT_APP_SOCKET ?? "https://dev.percapita.mx"
    }/percapitaSocket`
  );
  const history = useHistory();
  const { userMain, updateUser } = React.useContext(UserContext);

  const { credito, updateCredito, deleteCredito } = React.useContext(TramiteCreditoContext);

  const [today, setToday] = React.useState<string>(undefined);

  const [cantidadSolicitada, setCantidadSolicitada] = React.useState(undefined);

  const apiClient = ApiClient.getInstance();

  const contratoRes = { accepted: false };

  const [comentarios, setComentarios] = useState([]);
  const [countComentarios, setCountComentarios] = useState(0);
  const [comentario, setComentario] = React.useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [tipoCredito, setTipoCredito] = useState("");
  const [productoDescripcion, setProductoDescripcion] = useState("");

  React.useEffect(() => {
    getCreditData();
  }, []);
  const getValidationTerms = () => {
    var userLocal = JSON.parse(localStorage.getItem("userLocal"));
      var tipo = userLocal.creditoNomina;
    apiClient.getValidationTermsProduct(tipo).then(resp =>{
      const {loanActive,acceptedContract} = resp;
      console.log('Credit progress - getValidationTerms: ', resp)
      if(loanActive === true && acceptedContract === false){
        setPopupVisibility(true);
      } else if(loanActive === true && acceptedContract === true) {
        history.push('/dashboard2-nomina')
      }else{
        setPopupVisibility(false);
      }
      
    }).catch(err =>{
      if(err.message.includes('Entity not found: User with id')){
        localStorage.clear();
        history.push('/login-nomina')
      }

        if (err.status === 401) {
          localStorage.clear();
          history.push("/login-nomina");
        }
      });
  };

  useEffect(() => {
    // const socket = io(
    //   `${
    //     process.env.REACT_APP_SOCKET ?? "https://dev.percapita.mx"
    //   }/percapitaSocket`,
    //   {
        
    //   }
    // );
    // console.log("useEffect-socket");
    // socket.on("connect", () => {
    //   console.log("connect-scoket-io");
    //   socket.emit("userId", apiClient.getUserId());
    // });

    // socket.on("loan accepted/rejected", async (msg) => {
    //   console.log("message: ", msg);
    //   if (msg === true) {
    //     setPopupVisibility(true);
    //   } else {
    //     deleteCredito()
    //     return history.push('/solicitud-no-aprobada');
    //   }

    //   const activeCredit = await apiClient.getActiveCredit();
    //   const credit = await apiClient.getCredit(activeCredit.creditType);
    //   updateCredito({
    //     ...credito,
    //     idPrestamo: credit.id,
    //     fecha_solicitud: credit.creationDate,
    //     isDelivered: credit.delivered,
    //     isApproved: credit.status === "ACCEPTED" ? true : false,
    //     importe: credit.amount,
    //     estatusSolicitud:
    //       credit.status === "IN_PROGRESS"
    //         ? "revision"
    //         : credit.status === "ACCEPTED"
    //         ? "aprobado"
    //         : credit.status === "DENIED"
    //         ? "solicitud-no-aprobada"
    //         : "sin-solicitud",
    //   });
    // });

    apiClient
      .getCountComentarios()
      .then((data) => {
        console.log("count", data.count);
        setCountComentarios(data.count);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  /*const statusCredito = async () => {

    try {
      const { creditType, id } = await apiClient.getActiveCredit();

      const creditStatus = await apiClient.getCreditStatus(id, creditType);
      const credit = await apiClient.getCredit(creditType);

      if (creditStatus.approved === 'ACCEPTED') {
        clearInterval(intervalo);

        if (!credit.isDelivered) {
          return history.push('/dashboard2');
        } else {
          return history.push('/credito-detalle');
        }
      } else if (creditStatus.approved === 'DENIED') {
        clearInterval(intervalo);
        return history.push('/solicitud-no-aprobada');
      }
    } catch (e) {

    }
  }


  var intervalo = setInterval(statusCredito, 10000);*/
  const [vista, setVisa] = useState(false);
  const getCreditData = async () => {
    try {
      var userLocal = JSON.parse(localStorage.getItem("userLocal"));
      var tipo = userLocal.creditoNomina;
      const { creditType, status , product} = await apiClient.getActiveCreditNomina(tipo);
      setTipoCredito(creditType)
      setProductoDescripcion(product)
      const respuesta = await apiClient.getActiveCreditNomina(tipo);
      console.log('creditType: ', respuesta);
      const credit = await apiClient.getCredit(creditType);
      setCantidadSolicitada(credit.amount);
      if(status === 'IN_PROGRESS' || status === 'ACCEPTED'){
        setVisa(true);
      }else{
        return history.push('/')
      }
    } catch (e) {
      console.error(e);
      console.log('creditType: ', e);
      return history.push('/')

    }
  };

  React.useEffect(() => {
    getValidationTerms();
    const date = new Date();

    setToday(
      date.toLocaleDateString("es-ES", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      })
    );
  }, []);

  const [isPopupVisible, setPopupVisibility] = useState(false);

  const [open, setOpen] = React.useState(false);

  const handleClickOpenComentarios = () => {
    setOpen(true);
    getComments();
    setTimeout(function () {
      apiClient
        .setCountComentarios()
        .then((data) => {
          console.log("setCount", data);
        })
        .catch((error) => {
          console.log("setCount", error);
        });
    }, 20);
  };

  const getComments = () => {
    apiClient
    .getComentariosClient()
    .then((data) => {
      console.log("comentarios", data);
      setComentarios(data);
    })
    .catch((error) => {
      setMessage("Ha ocurrido un error al cargar los comentarios.");
      setSeverity("error");
      setOpenMessage(true);
    });
  };

  const handleCloseComentarios = () => {
    setOpen(false);
  };

  const handleAddComentario = () => {
    console.log(comentario);
    comentario.length > 0 &&
      apiClient
        .addComentariosClient(comentario)
        .then((data) => {
          setMessage("Informacion guardada correctamente..");
          setSeverity("success");
          setOpenMessage(true);
          console.log(data);
          setTimeout(function () {
            setComentario("");
            getComments();
          }, 10);
        })
        .catch((error) => {
          setMessage("Ha ocurrido un error al guardar la información.");
          setSeverity("error");
          setOpenMessage(true);
        });
  };
  const handleClose = () => {
    setOpenMessage(false);
  };
  return (
    vista
    ?
    <div>
      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />
      <HeaderDashboard />
      {
        isPopupVisible
        ? 
        <PopUp
        isPopupVisible = {isPopupVisible}
        setPopupVisibility = {setPopupVisibility}
        />
        : 
        null
      }
      <div className="dashboard__container">
        <div className="dashboard__header">
          <div>
            <h1>¡Hola {userMain.nombre}! Bienvenido a tu espacio</h1>
            <span style={{ marginTop: "10px" }}>{today}</span>
           
          </div>
          <div className="dashboard__header-banner-en-proceso">
            <i className="far fa-calendar-alt"></i>
            Aquí podrás encontrar detalles de tu pago una vez que tu crédito
            esté aprobado
          </div>
        </div>
        <div className="dashboard__header">
          <div className={classes.root}>
            <button onClick={handleClickOpenComentarios}>
              <Badge badgeContent={countComentarios} color="error">
                <h3>
                  {" "}
                  Comentarios <MailIcon />{" "}
                </h3>
              </Badge>
            </button>
          </div>
        </div>
        <div className="dashboard-en-proceso__content">
          <h2>¡Estamos analizando tu solicitud de crédito!</h2>
          
            
            {cantidadSolicitada && tipoCredito === 'nomina' ? (
              <span>
              ¡No te preocupes! Estamos trabajando arduamente para determinar la
              viabilidad de tu solicitud por
              <br />
              <b>
                {" "}
                ${" "}
                {cantidadSolicitada
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}{" "}
                MXN.
              </b>
              </span>
            ) : 
            <span>
              ¡No te preocupes! Estamos trabajando arduamente para determinar la
              viabilidad de tu solicitud de
              <br />
              <b>
                {" "}
                <h1>{productoDescripcion}{" "}</h1>
              </b>
              </span>
               }
          
          <img src={clock} />
          <progress max="100" value="80"></progress>
          <span>Regresa pronto para encontrar los detalles de tu crédito.</span>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://api.whatsapp.com/send?phone=+523315206918"
          >
            <button className="blue">
              <i className="fas fa-phone-alt"> </i> Necesito ayuda
            </button>
          </a>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleCloseComentarios}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="dialog-title-comentarios">
            Comentarios 
          <div>
        <h6 className={classes.container}> Sin Leer
          <Badge color="secondary" variant="dot" className={classes.margin}/>
        </h6>
            </div>
        </DialogTitle>
        <DialogContent>
          <List dense>
            {comentarios.map((comment) => (
              <>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    className={
                      comment.origin === "admin" ? classes.itemA : classes.itemC
                    }
                  >
                    <h3 className={classes.spam}>
                      {comment.origin}{" "}
                      {comment.viewed === false && (
                        <Badge color="secondary" variant="dot" />
                      )}
                    </h3>
                    <span className={classes.spam}>{comment.comment}</span>
                  </ListItemText>
                </ListItem>
                <Divider />
              </>
            ))}
          </List>
        </DialogContent>

        <textarea
          className="text-area_comentarios"
          name="story"
          placeholder="Mensaje"
          rows={4}
          cols={120}
          value={comentario}
          onChange={(e) => setComentario(e.target.value)}
          required
        />

        <DialogActions>
          <button onClick={handleCloseComentarios}>Salir</button>
          <button className="blue" onClick={handleAddComentario}>
            Enviar
          </button>
        </DialogActions>
      </Dialog>
    </div>
    :
    null
  );
}

export default CreditProgress;
