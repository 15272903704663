import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { bindActionCreators } from "redux";
import ApiClient from "../../../services/apiclient";
import { nextStep } from "../../../store/actions/PrestamosPersonalesActions";
import HeaderDashboard from "../../headerDashboard.component";
import Footer from '../footer';
import { ProgressCircular } from '../Progress';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import AlertMessage from "../../alert.component";
import Popup from "devextreme-react/popup";
import Menu from "../prestamoPersonalNuevo/components/Menu/Menu";
import { useHistory } from "react-router-dom";

const TermsConditionsCDC = ({
    nextStep,
  nominaId,
}) => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const history = useHistory();
  const apiClient = ApiClient.getInstance();
    const [avisoDePrivacidad, setavisoDePrivacidad] = useState(false);
        const handleChange = () => {
            setavisoDePrivacidad(!avisoDePrivacidad);
        };
        const [isPopupVisible, setPopupVisibility] = useState(false);
        const [loading, setLoading] = useState(false);
        const [openMessage, setOpenMessage] = useState(false);
        const [message, setMessage] = useState("");
        const [severity, setSeverity] = useState("success");

         const onsubmitForm = async(values) => {
          
            if (avisoDePrivacidad == false) {
              console.log(values.NIP);
              setOpenMessage(true);
              setMessage("Para poder continuar necesitas aceptar el aviso de privacidad.");
              setSeverity("info");
              return;
            }
            
            setLoading(true);
            apiClient.validateCirculoCredito(values.NIP).then(response => {
              console.log(response);
                if(response.error.message === "Tu solicitud no puede ser procesada"){
                  history.push('/RECHAZADO/pabs/contract-not-found');
                } 
               else {
                // console.log(response);
                // apiClient.cdcForm(nominaId)
                // .then(res => {
                //   if(res.error?.message?.rechazado){
                //     console.log(res);
                //   } 
                //   else{
                     nextStep("PERSONAL-REFERENCES")
                //   }
                // })
                // .catch(e => {
                //   console.log(e)
                  
                // })
               }
            });
              
                
            
         };
         const [userCode, setUserCode] = useState({
            NIP: "",
          });

          const validationSchema = Yup.object().shape({
            NIP: Yup.string().required("El código NIP es requerido"),
          });

          const handleClose = (event, reason) => {
            if (reason === "clickaway") {
              return;
            }
            setOpenMessage(false);
        
          };
  return (
    <div>
      <br></br>
      <Menu step="circulo_credito" />
<AlertMessage
          open={openMessage}
          message={message}
          handleClose={handleClose}
          severity={severity}
        />
        <Popup
        visible={isPopupVisible}
        closeOnOutsideClick={false}
        width={1000}
        height={550}
        showTitle={false}
        
      >
        <AlertMessage
          open={openMessage}
          message={message}
          handleClose={handleClose}
          severity={severity}
        />
           
        </Popup>
            <Formik
            initialValues={userCode}
            onSubmit={onsubmitForm}
            validationSchema={validationSchema}
        >
          {({ handleSubmit, errors, touched }) => (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
                
        <div className="avisoPrivacidad">
                <h1>TERMINOS Y CONDICIONES Y AUTORIZACIÓN DE CONSULTA</h1>
            </div>

            <div id="textBlock" >

                <div style={{display:'flex',justifyContent:'center'}}>
                <label>
                    <input style={{ float: 'left', width: '30px' }} type="checkbox"
                    checked={avisoDePrivacidad}
                    onChange={handleChange}
                    />
                </label>
                <span style={{ fontSize: 14, marginLeft: 5 }}>
                    Acepto
                    <a href="/aviso-de-privacidad" target="_blank" style={{ fontSize: 14, marginLeft: 5 }}> Términos y Condiciones </a>
                    y cláusula de medios electrónicos tales como NIP.
                </span>
                </div>
                <br />
                </div>

            <div className="avisoContent">
            
                <br /><br /><center> <b> Autorización para solicitar reportes de crédito</b></center>

                <br /><br />Autorizo expresamente a <b>PROBENSO GROUP S.A. S.O.F.O.M. E.N.R,</b> para que lleve a cabo
                investigación sobre mi comportamiento crediticio en las sociedades de información crediticia (SIC) que estime
                conveniente. Conozco la naturaleza y alcance de la información que se solicitará, del uso que se le dará y 
                que se podrán realizar consultas periódicas de mi historial crediticio.
            <br /><br />

                Consiento que esta autorización tenga una vigencia de 3 años contando a partir de hoy, y en su caso mientras
                mantengamos relación jurídica. Acepto que este documento quede bajo propiedad de <b>PROBENSO GROUP S.A. S.O.F.O.M. E.N.R, </b>
                 para efectos de control y cumplimiento del articulo 28 de la LRSIC. 

            <br /><br />
              <div className="solicitud-b__container">
                <div id="div-perfil" className="">
                  <div
                    id="4"
                    className=""                
                  >
                  <div className="-co"></div>
                    <div className="">
                      <div>
                        <label htmlFor="NIP">Código NIP *</label>
                        <Field
                          tabIndex={4}
                          type="text"
                          id="NIP"
                          name="NIP"
                          /*  onChange={handleInputChange} */
                          placeholder="1987"
                        />
                        <p style = {{color:'#2d73ac', fontSize:13 }}>Mensaje enviado por SMS *</p>
                        <ErrorMessage name={`NIP`}>
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="button-center">
                  <button className="btn blue" type="submit" style ={{fontSize:'xx-large'}} disabled={loading}>
                    {loading ?
                      <ProgressCircular
                        loading={true}
                        title="Autorizo"
                      />
                      :
                      "Autorizo"
                    }
                  </button>
                </div>
                  </div>
            </Form>
          )}
        </Formik>

    </div>
  );
};

const mapStateToProps = ({ personalCreditReducer }) => ({
  user: personalCreditReducer?.user ?? {},
  nominaId: personalCreditReducer?.nominaId ?? "",
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    nextStep,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TermsConditionsCDC);
