import React from 'react';
import { Link } from 'react-router-dom';
import circle_checked from '../../../assets/circle-checked.png';
import lOGO from '../../../assets/lOGO2.png';
import phone from '../../../assets/phone.png';
import HeaderDashboard from '../headerDashboardNomina.component';

function RequestForm6() {
  return (
    <div>
     <HeaderDashboard />
      <div className="solicitud__container">
        <div className="solicitud-6__form-solicitud">
          <img className="solicitud-6__logo" src={lOGO} />
          <img className="solicitud-6__checkmark" src={circle_checked} />
          <h1>¡Felicidades!</h1>
          <span>
            De parte de todo el equipo Percapita, nos alegra informarte que tu
            solicitud por crédito de <b> $ 17,500 mxn </b> ha sido aprobado con
            éxito.
          </span>
          <span>
            El equipo determinó que tu préstamo será a un plázo de
            <b> 12 meses </b>, con una tasa de interés del<b>4.08%</b>, lo que
            significa que deberás <b> pagar $1449 pesos cada mes</b> para
            liquidar la deuda y la cantidad que se acumula debido al interés.
          </span>
          <Link to="#" className="blue">
            Terminar mi solicitud de crédito
          </Link>
          <span>
            Al dar clic en el botón te redirigiremos a la plataforma para que
            llenes tu información bancaria y te podamos depositar el monto en tu
            cuenta.
          </span>
          <Link to="#" className="blue">
            {' '}
            <img src={phone} /> Necesito ayuda{' '}
          </Link>
          <span className="solicitud-6__disclosure">
            Disclosure or additional infromation we may need to let the user
            know here. Percapita bla bla bla bla- Derechos y más info.
          </span>
        </div>
      </div>
    </div>
  );
}

export default RequestForm6;
