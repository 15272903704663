import React, { useEffect, useState } from "react";
import ApiClient from "../../../../../services/apiclient";
import AlertMessage from "../../../../alert.component";
import ImgVector from "../../../../../assets/dark-blue-vector.png";
import "./credit.css";
import { useHistory, useParams } from "react-router-dom";
import { TramiteCreditoContext } from "../../../../../context/TramiteCreditoProvider";
import { UserContext } from "../../../../../context/UserProvider";
import { ProgressCircular } from "../../Progress";
import CustomSlider from "../../../sliderNomina.component";
import CustomDropdown from "../../dashboard/dropdown.nomina.component";
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import sol from "../../../../../assets/solarumMinimium.png";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

interface RouteParams {
  creditType: "nomina";
}

export default function Informacion({
  nextStep,
}) {
  const history = useHistory();

  const { creditType } = useParams<RouteParams>();

  const { credito, updateCredito } = React.useContext(TramiteCreditoContext);
  const { userMain, updateUser } = React.useContext(UserContext);
  const [step, setStep] = useState(2);
  const [loading, setLoading] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [recommendation, setRecommendation] = useState(null);
  const [idNomina, setIdNomina] = useState(null);
  const apiClient = ApiClient.getInstance();
  const [items,setItems] = useState([]);
  const [loanAmount, setLoanAmount] = useState(0);
  const [recurrencyPayment, setRecurrencyPayment] = useState(null);
  const [product, setProduct] = useState([]);
  const [filtroOpciones, setFiltroOpciones] = useState([]);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  const [plazo,setPlazo] = useState(0);

  let isSendCodePhone = false;
  const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      width: 151,
    },
    rootGrid: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      fontSize: '20px',
      color: theme.palette.text.primary,
    },
    formControl: {
      margin: theme.spacing(1),
      display: 'flex',
    height: '100%',
    width: '96%',
    fontSize: '20px',
    },
    selectEmpty: {
      fontSize: '20px',
    },
    rootField: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '50ch',
      },
    },
  }),
);
const classes = useStyles();
  const theme = useTheme();
  
  const opciones =  async () => {
    
   }


  const sendCodePhone = (phone: string) => {
    const body = {
      phone: phone,
    };

    fetch(process.env.REACT_APP_URL_API + "/verification/phone/resend", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        console.log("code phone enviado ", res);
        // showMessage("Se han guardado los datos de su credito");
        isSendCodePhone = true;
      })
      .catch((err) => {
        console.error(err);
        isSendCodePhone = true;
      });
  };

  const handleChangeCantidad = (event: React.ChangeEvent<{ value: unknown }>) => {
    var a = event.target.value as number;
    alert (a)
  };
  // useEffect(() => {
    
  //   setTimeout(() => {
  //     if (!isSendCodePhone) {
  //       sendCodePhone(userMain.telefono);
  //     }
  //   }, 2000);
  // }, []);

  const handleUpdateLoan = () => {
    console.log("update loan",loanAmount);


      if (loanAmount <= 0) {
        console.log("-----dsad")
        setOpenMessage(true);
        setMessage(
          "Favor de seleccionar un equipo"
        );
        setSeverity("error");
        return;
      }
      if (plazo === 0) {
        setOpenMessage(true);
        setMessage(
          "Favor de seleccionar un plazo de pago"
        );
        setSeverity("error");
        return;
      }

    updateLoan();
  };
  const updateLoan = () => {
    // nextStep()
   
       setLoading(true);
       const body = {
         amount: loanAmount,
         plazo: plazo,
         frequencyPayments: recurrencyPayment,
       };
       console.log(body);
       apiClient
         .sendNominaProducto(body,'celular')
         .then((resp) => {
           setLoading(false);
           nextStep();
           //history.push("/credit-nomina");
         })
         .catch((err) => {
           
           setLoading(false);
           setOpenMessage(true);
           setMessage(err.message);
           setSeverity("error");
         });
   };

   const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    var a = event.target.value as string;
    var categroy = a.split('|')[1];
    var amount = parseInt(a.split('|')[0]);
    setLoanAmount(amount);
    const search = product.filter(value => value.name.split('/')[0] === categroy);
    console.log(search);
    setFiltroOpciones(search);

    
  };

  const handleChangePlazo = (
    event: React.ChangeEvent<{ name?: number; value: unknown }>,
  ) => {
    var a = event.target.value as number;
    
    setPlazo(parseInt(a));

    
  };



  useEffect(() => {
    
    apiClient
    .getProductsNomina('tequila')
    .then(async (res) => {
      console.log(res.option[0]);
      setItems(res.option[0])
      console.log(items)
      setRecurrencyPayment(res.recurrencyPayment === "SEMANAL" ? 'Semanal' : 'Quincenal');
      setLoading(false);
      apiClient.getProductsDesglose()
      .then((res) => {
        console.log(res);
        setProduct(res);
      })
      .catch((error) => {
        console.log(error);
      });
      
    })
    .catch((err) => {
      console.log(err);
    });
  },[])
  const handleClose = () => {
    setOpenMessage(false);
  };
  {
    if (loading) {
      return(
        <p>Loading...</p>
      )
      
    }
  else {
  return (
    
    <div>
      <AlertMessage open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity} />
      
      <div className="solicitud__container">
        <div className="solicitud-b__container">
          <div className="">
            <h2>
              Selecciona tu producto y la recurrencia de pago para
              terminar tu solicitud.  
            </h2>
            <h2></h2>
            <br></br>
            
            
            <div className="">
              {/* <section className="solicitud-credito__credito">
                <div className="solicitud-credito__amount solicitud-credito__item "> */}
                  {/* <div className="solicitud-credito__recurrence solicitud-credito__item ">
                    <h4>Elige tu smartphone</h4>
                    <CustomDropdown
                    placeholder={"Equipo"}
                    width = '250px'
                    handleChange={handleChange}
                  >       
                  <option aria-label="None" value="" />
                  {items.map((data) => {
                    return (                  
                      <option value={data.cost+'|'+data.category}>{data.name}</option>
                    );
                  })}                         
                  </CustomDropdown>
                  </div> */}
                  {/* <Card className={classes.root}>
                  <CardMedia
                        className={classes.cover}
                        image= {sol}
                        title="Live from space album cover"
                      />
                      <div className={classes.details}>
                        <CardContent className={classes.content}>
                          <Typography component="h4" variant="h4">
                          Tequila Solarum Extra
                          <br></br> Añejo Cristalino - 750 ml
                          </Typography>
                        </CardContent>
                       
                      </div>
                      
                    </Card>
                    
                </div>
                <div className="solicitud-credito__termn solicitud-credito__item ">
                <div className="solicitud-credito__recurrence solicitud-credito__item ">
                    <h4>Elige el plazo</h4>
                    <CustomDropdown
                    placeholder={"Plazo"}
                    width = '480px'
                    handleChange={handleChangePlazo}
                  >       
                  <option aria-label="None" value="" />
                  {filtroOpciones.map((data) => {
                    return (                  
                      <option value={data.semanas}>{data.semanas} pagos semanales de ${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(data.pago_semanal)} Total a pagar: ${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(data.total_pagado)}</option>
                   
                    );
                  })}                         
                  </CustomDropdown>
                  </div>
                </div>

                <div className="solicitud-credito__recurrence solicitud-credito__item ">
                  <h4>Selecciona tu recurrencia de pago</h4>

                  <CustomDropdown
                    value={recurrencyPayment}
                    disabled

                  >
                    <option value={recurrencyPayment}>{recurrencyPayment}</option>
                    
                  </CustomDropdown>
                </div>
              </section>
             
              <section className="solicitud-credito__buttons">
                <button
                  className="blue"
                  disabled={loading}
                  onClick={handleUpdateLoan}
                  style={{width: "100px"}}
                >
                  <ProgressCircular
                    loading={loading}
                    title="Enviar solicitud"
                  />
                </button>
              </section> */}

<div className={classes.rootGrid}>
            <Grid container spacing={1}>
            <Grid item xs={4}>
          <Paper className={classes.paper} variant ='elevation' elevation ={3} >Producto</Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper} variant ='elevation' elevation ={3} >Precio</Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper} variant ='elevation' elevation ={3} >Cantidad</Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper} variant ='elevation' elevation ={3} >Opciones de pago</Paper>
        </Grid>
        </Grid>
            <Grid container spacing={2}>
            <Grid item xs={4}>
          <Card className={classes.root}>
                  <CardMedia
                        className={classes.cover}
                        image= {sol}
                        title="Live from space album cover"
                      />
                      <div className={classes.details}>
                        <CardContent className={classes.content}>
                        <Typography component="h1" variant="h6">
                          Tequila Solarum Extra Añejo Cristalino - 750 ml
                          </Typography>
                        </CardContent>
                       
                      </div>
                      
                    </Card>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}>
          <FormControl className={classes.formControl} disabled>
          <InputLabel id="demo-simple-select-disabled"></InputLabel>
              <Select
                labelId="demo-simple-select-disabled-label"
                id="demo-simple-select-disabled"
                value={1}
                onChange={handleChange}
                className={classes.selectEmpty}
              >
                <MenuItem value="" className={classes.selectEmpty}>
                  <em>None</em>
                </MenuItem>
                <MenuItem value={1} className={classes.selectEmpty}>$4800</MenuItem>
              </Select>
            </FormControl>

          </Paper>
        
        </Grid>

        <Grid item xs={2}>
        <Paper className={classes.paper}>
        <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label"></InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={items}
          onChange={handleChangeCantidad}
          className={classes.selectEmpty}
        >
          {items.map((data) => {
                    return (
                      <MenuItem value={data.cost} className={classes.selectEmpty}>{data.name}</MenuItem>
                    );
                  })} 
          {/* <MenuItem value={10} className={classes.selectEmpty}>Ten</MenuItem>
          <MenuItem value={20} className={classes.selectEmpty}>Twenty</MenuItem>
          <MenuItem value={30} className={classes.selectEmpty}>Thirty</MenuItem>  */}
        </Select>
      </FormControl>
      {/* <CustomDropdown
                    placeholder={"Equipo"}
                  
                    handleChange={handleChange}
                  >       
                  <option aria-label="None" value="" />
                  {items.map((data) => {
                    return (                  
                      <option value={data.cost+'|'+data.category}>{data.name}</option>
                    );
                  })}                         
                  </CustomDropdown> */}
        </Paper>
        
        </Grid>

        <Grid item xs={4}>
        <Paper className={classes.paper}>
        <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label"></InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={10}
          onChange={handleChange}
          className={classes.selectEmpty}
        >
          <MenuItem value={10} className={classes.selectEmpty}>Ten</MenuItem>
          <MenuItem value={20} className={classes.selectEmpty}>Twenty</MenuItem>
          <MenuItem value={30} className={classes.selectEmpty}>Thirty</MenuItem>
        </Select>
      </FormControl>
        </Paper>
        
        </Grid>
            </Grid>
            </div>
            <section className="solicitud-credito__buttons">
                <button
                  className="blue"
                  disabled={loading}
                  onClick={handleUpdateLoan}
                  style={{width: "150px"}}
                >
                  <ProgressCircular
                    loading={loading}
                    title="Enviar solicitud"
                  />
                </button>
              </section>
            </div>
          </div>
          <img className="img-back-credito" src={ImgVector} />
        </div>
      </div>
    </div>
  );
                        }
                      }
}
