import React, { useState } from "react";
import { Field, Formik, Form } from "formik";
import "./Business.css";
import ApiClient from "../../../../../services/apiclient";
import AlertMessage from "../../../alertNomina.component";
import * as Yup from "yup";
import Modal from "react-modal";
import contrato1 from "../../../../../assets/contrato1.jpeg";
import contrato2 from "../../../../../assets/contrato2.jpg";
import { ProgressCircular } from "../../Progress";

export const BusinessTequila = ({ nextStep }) => {
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");

  const [empresa, setEmpresa] = useState({
    empresaName: "",
  });
  const onsubmitForm = (values: any, actions: any) => {
    setLoading(true);
    apiClient
      .setCompanyNomina(values.empresaName,'tequila')
      .then((res) => {
        console.log(res);
        setLoading(false);
        nextStep();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validationSchema = Yup.object().shape({
    empresaName: Yup.string().required("Requerido"),
  });

  const [loading, setLoading] = useState(false);

  const apiClient = ApiClient.getInstance();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    /* subtitle.style.color = '#f00'; */
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className="container">
      <div className="containerVa productop empresas-margin">
        <p className="sp">Seleccione su empresa</p>
      </div>
      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />

      <Formik
        initialValues={empresa}
        onSubmit={onsubmitForm}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, errors, touched }) => (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div className="containerV">
              <div>
                <label htmlFor="city">Empresa</label>
                <Field
                  as="select"
                  id="empresaName"
                  name="empresaName"
                  className={errors.city && touched.city && "invalidV"}
                >
                  <option value="">Selecciona una opcion</option>
                  <option value="PABS">PABS</option>
                  <option value="Funeraria Latinoamericana">
                    Funeraria Latinoamericana
                  </option>
                  <option value="HQ Flavor">HQ Flavor</option>
                  <option value="Cofiplem">Cofiplem</option>
                </Field>
              </div>
            </div>

            <div className="ButtonV">
              <button
                className="btn blue"
                type="submit"
                disabled={loading}
                style={{ width: "100px" }}
              >
                <ProgressCircular loading={loading} title="Continuar" />
              </button>
            </div>
          </Form>
        )}
      </Formik>

      <div>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
        >
          <div className="modal1-boton-cerrar">
            <button className="btn blue" onClick={closeModal}>
              Cerrar
            </button>
          </div>

          <div className="imagenes-modal">
            <img src={contrato1} alt="" width="20%" />
            <img src={contrato2} alt="" width="50%" />
          </div>
        </Modal>
      </div>
    </div>
  );
};
