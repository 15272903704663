import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import React from 'react';
export default function CustomDropdown(props: any) {
  return (
    <FormControl
      style={{width: '200px'}}
      variant="filled"
      // className={classes.formControl}
    >
      <InputLabel htmlFor="filled-age-native-simple">
        {props.placeholder}
      </InputLabel>
      <Select
        native
        value={props.value}
        onChange={props.handleChange}
        inputProps={{
          name: 'age',
          id: 'filled-age-native-simple',
        }}
      >
        {props.children}
      </Select>
    </FormControl>
  );
}
