import React, { useEffect, useState, useCallback } from "react";

import AlertMessage from "../../../../alert.component";
import "./perfil.css";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ApiClient from "../../../../../services/apiclient";
import { ProgressCircular } from "../../../Progress";
import moment from "moment";
export default function Perfil({
  userData,
  nextStep,
  userData1,
  setUserData1,
  productName,
  setProductName,
  pabsNumber,
  prevStep
}) {
  const [openMessage, setOpenMessage] = useState(false);
  const apiClient = ApiClient.getInstance();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const str2bool = (value) => {
    if (value === 'true') {
      return true;
    } else if (value === 'false') {
      return false
    }

    return false;
  };

  useEffect(() => {
    userData1.questionsPeps.titleNumber = pabsNumber
  }, []);

  const formikRef = React.useRef(null);

  const inputChange = useCallback((e) => {

    if (e.target.value === 'true' || e.target.value === 'false') {
      e.target.value = str2bool(e.target.value);
      console.log("booleans", e.target.value, typeof (e.target.value));
      formikRef.current?.handleChange(e);
    }

  }, []);

  const mapObject = (values): Promise<any> => {
    return new Promise((resolve, reject) => {
      let mappedRequest = {};
      Object.keys(values.questionsPeps).forEach(key => {
        if (values.questionsPeps[key] === 'true' || values.questionsPeps[key] === 'false') {
          console.log(values.questionsPeps[key])

          mappedRequest[key] = JSON.parse(values.questionsPeps[key]);
        }
        mappedRequest[key] = values.questionsPeps[key];
      });

      resolve(mappedRequest);
    });
  }


  const onsubmitForm = (values: any, actions: any) => {
    setLoading(true);


    values.questionsPeps.publicOffice = str2bool(values.questionsPeps.publicOffice);
    values.questionsPeps.conyugeIsPEP = str2bool(values.questionsPeps.conyugeIsPEP);
    values.questionsPeps.thirdWillGetBenefits = str2bool(values.questionsPeps.thirdWillGetBenefits);
    values.questionsPeps.thirdProvidesResources = str2bool(values.questionsPeps.thirdProvidesResources);


    console.log(values.questionsPeps.conyugeIsPEP, typeof (values.questionsPeps.conyugeIsPEP));
    console.log(values);

    const splitDate1 = values.questionsPeps.expirationDateINE.split('-');
    values.questionsPeps.expirationDateINE = (new Date(Number.parseInt(splitDate1[0]), Number.parseInt(splitDate1[1]) - 1, Number.parseInt(splitDate1[2]))).toISOString();


    const splitDate2 = values.questionsPeps.expirationDateProofAddress.split('-');
    values.questionsPeps.expirationDateProofAddress = (new Date(Number.parseInt(splitDate2[0]), Number.parseInt(splitDate2[1]) - 1, Number.parseInt(splitDate2[2]))).toISOString();

    console.log(values.questionsPeps.expirationDateINE);
    console.log(values.questionsPeps.expirationDateProofAddress);



    setUserData1(values);

    apiClient.pabsContinue(values).then(data => {
      nextStep();
    }).catch(err => {
      setOpenMessage(true);
      setMessage('No se puede continuar al siguiente paso, verifique la informacion.');
      setSeverity('error');
      setLoading(false);
    });



  };

  useEffect(() => {
    console.log("UserData segunda parte", userData1);
  }, []);



  const validationSchema = Yup.object().shape({
    questionsPeps: Yup.object({
      publicOffice: Yup.boolean().required("Requerido"),
      specifyPublicOffice: Yup.string().when('publicOffice', {
        is: true,
        then: Yup.string().required("Requerido")
      }),
      conyugeIsPEP: Yup.boolean().required("Requerido"),
      specifyConcuyeIsPEP: Yup.string().when('conyugeIsPEP', {
        is: true,
        then: Yup.string().required("Requerido")
      }),
      thirdWillGetBenefits: Yup.boolean().required("Requerido"),
      specifyThirdWillGetBenefits: Yup.string().when('thirdWillGetBenefits', {
        is: true,
        then: Yup.string().required("Requerido")
      }),
      thirdProvidesResources: Yup.boolean().required("Requerido"),
      nameWillProvideResources: Yup.string().when('thirdProvidesResources', {
        is: true,
        then: Yup.string().required("Requerido")
      }),
      sourcesOfResources: Yup.string().required("Requerido"),
      destinationOfResources: Yup.string().required("Requerido"),
      natureOfResources: Yup.string().required("Requerido"),
      titleNumber: Yup.string().required("Requerido"),
      numberCheque: Yup.string(),
      expirationDateINE: Yup.string().required("Requerido"),
      expirationDateProofAddress: Yup.string().required("Requerido"),
      wayToPay: Yup.string().required("Requerido"),


    })


  });

  const handleClose = () => {
    setOpenMessage(false);
  };


  return (
    <div className="container">
      <AlertMessage 
      open={openMessage}
      message={message}
      handleClose={handleClose}
      severity={severity} 
      />
      <div className="solicitud__container">
        <Formik
          initialValues={userData1}
          onSubmit={onsubmitForm}
          validationSchema={validationSchema}
          innerRef={formikRef}
        >
          {({ handleSubmit, errors, touched }) => (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div className="solicitud-b__container">

                <div className="navbar4">
                  <a className="hg13">1. Validación</a>
                  <a className="hg23">2. Información personal</a>
                  <a className="hg33">3. Referencias</a>
                  <a className="hg43">4. Perfil</a>
                  <a className="hg53 ">5. Titulo PABS</a>
                </div>
                <p>
                  {" "}
                  <b>Preguntas PEPS y perfil transaccional</b>{" "}
                </p>

                <div className="solicitud-4__input-group informacionC">
                  <div>
                    <label htmlFor="publicOffice">
                      ¿Desempeña o desempeñó cargo público?
                    </label>
                    <Field
                      as="select"
                      type="text"
                      id="publicOffice"
                      name="questionsPeps.publicOffice"
                      onChange={inputChange}

                    >
                      <option value="">Seleccione una opción</option>
                      <option value="true">Si</option>
                      <option value="false">No</option>
                    </Field>
                    <ErrorMessage name={`questionsPeps.publicOffice`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                  </div>
                  <div className="Especificar">
                    <label htmlFor="specifyPublicOffice">
                      Especificar dependecia, puesto, periodo y funciones
                    </label>
                    <Field
                    maxlength="50"
                      type="text"
                      id="specifyPublicOffice"
                      name="questionsPeps.specifyPublicOffice"
                    />
                    <ErrorMessage name={`questionsPeps.specifyPublicOffice`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                  </div>

                  <div>
                    <label htmlFor="conyugeIsPEP">
                      ¿Cónyuge o pariente a segundo grado es PEP?
                    </label>
                    <Field
                      as="select"
                      type="text"
                      id="conyugeIsPEP"
                      name="questionsPeps.conyugeIsPEP"
                      onChange={inputChange}
                    >
                      <option value="">Seleccione una opción</option>
                      <option value="true">Si</option>
                      <option value="false">No</option>
                    </Field>
                    <ErrorMessage name={`questionsPeps.conyugeIsPEP`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                  </div>

                  <div className="Especificar">
                    <label htmlFor="specifyConcuyeIsPEP">
                      Especificar dependecia, puesto, periodo y funciones
                    </label>
                    <Field
                    maxlength="50"
                      type="text"
                      id="specifyConcuyeIsPEP"
                      name="questionsPeps.specifyConcuyeIsPEP"
                    />
                    <ErrorMessage name={`questionsPeps.specifyConcuyeIsPEP`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                  </div>

                  <div>
                    <label htmlFor="thirdWillGetBenefits">
                      ¿Algún tercero obtendrá los beneficios?
                    </label>
                    <Field
                      as="select"
                      type="text"
                      id="thirdWillGetBenefits"
                      name="questionsPeps.thirdWillGetBenefits"
                      onChange={inputChange}
                    >
                      <option value="">Seleccione una opción</option>
                      <option value="true">Si</option>
                      <option value="false">No</option>
                    </Field>
                    <ErrorMessage name={`questionsPeps.thirdWillGetBenefits`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                  </div>

                  <div className="Especificar">
                    <label htmlFor="specifyThirdWillGetBenefits">
                      Especificar el nombre del propietario real
                    </label>
                    <Field
                    maxlength="50"
                      type="text"
                      id="specifyThirdWillGetBenefits"
                      name="questionsPeps.specifyThirdWillGetBenefits"
                    />
                    <ErrorMessage name={`questionsPeps.specifyThirdWillGetBenefits`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                  </div>

                  <div>
                    <label htmlFor="thirdProvidesResources">
                      ¿Algún tercero aportará recursos para cumplimiento?
                    </label>
                    <Field
                      as="select"
                      type="text"
                      id="thirdProvidesResources"
                      name="questionsPeps.thirdProvidesResources"
                      onChange={inputChange}
                    >
                      <option value="">Seleccione una opción</option>
                      <option value="true">Si</option>
                      <option value="false">No</option>
                    </Field>
                    <ErrorMessage name={`questionsPeps.thirdProvidesResources`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                  </div>

                  <div className="Especificar">
                    <label htmlFor="nameWillProvideResources">
                      Especificar el nombre del propietario real
                    </label>
                    <Field
                    maxlength="50"
                      type="text"
                      id="nameWillProvideResources"
                      name="questionsPeps.nameWillProvideResources"
                    />
                    <ErrorMessage name={`questionsPeps.nameWillProvideResources`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                  </div>

                  <div>
                    <label htmlFor="titleNumber">Numero del título</label>
                    <Field
                      type="text"
                      id="titleNumber"
                      maxlength="40"
                      name="questionsPeps.titleNumber"
                      value={pabsNumber}
                      disabled
                    />
                    <ErrorMessage name={`questionsPeps.titleNumber`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                  </div>

                  <div>
                    <label htmlFor="sourcesOfResources">
                      Origen de los recursos
                    </label>
                    {/*<Field
                     maxlength="40"
                      type="text"
                      id="sourcesOfResources"
                      name="questionsPeps.sourcesOfResources"
                      value="INGRESOS PROPIOS DE SU ACTIVIDAD"
                    >

                    </Field>
                    <ErrorMessage name={`questionsPeps.sourcesOfResources`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>*/}


                    <Field
                      as="select"
                      type="text"
                      id="sourcesOfResources"
                      name="questionsPeps.sourcesOfResources"
                    >
                      <option value="">Seleccione una opción</option>
                      <option value="INGRESOS PROPIOS DE SU ACTIVIDAD">INGRESOS PROPIOS DE SU ACTIVIDAD</option>
                      <option value="GASTO FAMILIAR">GASTO FAMILIAR</option>
                      <option value="OTROS">OTROS</option>
                      <option value="PENSION">PENSION</option>
                    </Field>
                    <ErrorMessage name={`questionsPeps.sourcesOfResources`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                  </div>

                  <div>
                    <label htmlFor="destinationOfResources">
                      Destino de los recursos
                    </label>
                    {/*<Field
                 
                      type="text"
                      maxlength="40"
                      id="destinationOfResources"
                      name="questionsPeps.destinationOfResources"
                      value="PAGO DE DEUDA"
                    >
                    
                    </Field>
                    <ErrorMessage name={`questionsPeps.destinationOfResources`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>*/}
                    

                    <Field
                      as="select"
                      type="text"
                      id="destinationOfResources"
                      name="questionsPeps.destinationOfResources"
                    >
                      <option value="">Seleccione una opción</option>
                      <option value="PAGO DE DEUDA">PAGO DE DEUDA</option>
                      <option value="COMPRA DE ARTICULOS PERSONALES">COMPRA DE ARTICULOS PERSONALES</option>
                      <option value="COMPRA DE BIENES DE CONSUMO DURARERO">COMPRA DE BIENES DE CONSUMO DURARERO</option>
                      <option value="COMPRA DE INMUEBLES">COMPRA DE INMUEBLES</option>
                      <option value="CAPITAL DE TRABAJO">CAPITAL DE TRABAJO</option>
                    </Field>
                    <ErrorMessage name={`questionsPeps.destinationOfResources`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>



                  </div>

                  <div>
                    <label htmlFor="natureOfResources">
                      Naturaleza de los recursos
                    </label>
                    {/*<Field
                     maxlength="40"
                      type="text"
                      id="natureOfResources"
                      
                      value="CREDITO SIMPLE"
                    />*/}


                    <Field
                      as="select"
                      type="text"
                      id="natureOfResources"
                      name="questionsPeps.natureOfResources"
                    >
                      <option value="">Seleccione una opción</option>
                      <option value="CREDITO SIMPLE">CREDITO SIMPLE</option>
                    </Field>
                    <ErrorMessage name={`questionsPeps.natureOfResources`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
               
                  </div>

                  <div>
                    <label htmlFor="expirationDateINE">
                      Fecha de vencimiento INE
                    </label>
                    <Field
                      type="date"
                      max={ "9999-12-31" }
                      id="expirationDateINE"
                      name="questionsPeps.expirationDateINE"
                    />
                    <ErrorMessage name={`questionsPeps.expirationDateINE`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                  </div>

                  <div>
                    <label htmlFor="expirationDateProofAddress">
                      Vencimiento comprobante de domicilio
                    </label>
                    <Field
                      type="date"
                      max={ "9999-12-31" }
                      id="expirationDateProofAddress"
                      name="questionsPeps.expirationDateProofAddress"
                    />
                    <ErrorMessage name={`questionsPeps.expirationDateProofAddress`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                  </div>

                  <div>
                    <label htmlFor="wayToPay">Forma de pago</label>
                    {/*<Field
                     
                      type="text"
                      maxlength="40"
                      id="wayToPay"
                      name="questionsPeps.wayToPay"
                      placeholder="EFECTIVO"
                      disabled
                    >
                    
                    </Field>
                    <ErrorMessage name={`questionsPeps.wayToPay`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>*/}


                    <Field
                      as="select"
                      type="text"
                      id="wayToPay"
                      name="questionsPeps.wayToPay"
                    >
                      <option value="">Seleccione una opción</option>
                      <option value="EFECTIVO">EFECTIVO</option>
                      <option value="CHEQUE">CHEQUE</option>
                      <option value="SPEI">SPEI</option>
                      <option value="VENTANILLA BANCARIA (DAP/DIE)">VENTANILLA BANCARIA (DAP/DIE)</option>
                    </Field>
                    <ErrorMessage name={`questionsPeps.wayToPay`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                  </div>
                </div>
                

                  
                <div className="Button-cpr">
                <button onClick={prevStep} className="btn blue" disabled={loading}>
                    Regresar
                  </button>
                  <button type="submit" className="btn blue" disabled={loading}>
                  <ProgressCircular loading={loading} title='Continuar'/>
                </button>
                </div>
                
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
