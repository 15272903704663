import Dashboard from "../components/home.component";
import DashboardNomina from "../components/nominaForm/homeNomina.component";
import Dashboard2 from "../components/dashboard/dashboard.component";
import Dashboard2Nomina from "../components/nominaForm/dashboard/dashboard.component";
import TypeCreditApplication from "../components/pages/type-credit-application.component";
import TypeCreditApplicationNomina from "../components/nominaForm/pages/type-credit-application.component";
import AccountStatus from "../components/pages/dashboard/account-status.component";
import AccountStatusNomina from "../components/nominaForm/pages/dashboard/account-status.nomina.component";
import CreditDetail from "../components/pages/dashboard/credit-detail.component";
import CreditDetailNomina from "../components/nominaForm/pages/dashboard/credit-detail.nomina.component";
import Solicitud from "../components/pages/solicitud/solicitud";
import SolicitudNomina from "../components/nominaForm/pages/solicitud/solicitud-nomina";
import NavPABS from "../components/pages/PABS/nav";
import NavNomina from "../components/nominaForm/pages/Nomina/nav";
import NavCelular from "../components/nominaForm/pages/NominaCelular/nav";
import NavTequila from "../components/nominaForm/pages/NominaTequila/nav";
import Rechazado from "../components/pages/PABS/validacion/rechazado/rechazado";
import RechazadoNomina from "../components/nominaForm/pages/PABS/validacion/rechazado/rechazado";
import CreditType from "../components/pages/credit-type/credit-type";
import CreditTypeNomina from "../components/nominaForm/pages/credit-type/credit-type";
import ApprovedCredit from "../components/pages/dashboard/approvedCredit.component";
import ApprovedCreditNomina from "../components/nominaForm/pages/dashboard/approvedCredit.nomina.component";
import CreditProgress from "../components/pages/dashboard/credit-progress.component";
import CreditProgressNomina from "../components/nominaForm/pages/dashboard/credit-progress.nomina.component";
import CreditoNoAprovado from "../components/pages/dashboard/credit-no-approved.component";
import CreditoNoAprovadoNomina from "../components/nominaForm/pages/dashboard/credit-no-approved.nomina.component";
import CreditoRechazadoAprovado from "../components/pages/dashboard/credit-rechazado-approved.component";
import CreditoRechazadoAprovadoNomina from "../components/nominaForm/pages/dashboard/credit-rechazado-approved.nomina.component";
import PrestamoPersonalNuevo from "../components/pages/prestamoPersonalNuevo/prestamo-personal-nuevo";
import SinPermisoTramite from "../components/pages/dashboard/no-solicitud-credit.component";
import SinPermisoTramiteNomina from "../components/nominaForm/pages/dashboard/no-solicitud-credit.nomina.component";
import ConfigCreditUser from "../components/pages/dashboard/config-credit-user.component";
import ConfigCreditUserNomina from "../components/nominaForm/pages/dashboard/config-credit-user.nomina.component";
import NonCreditUser from "../components/pages/dashboard/non-credit-user.component";
import NonCreditUserNomina from "../components/nominaForm/pages/dashboard/non-credit-user.nomina.component";
import Page4 from "../components/pages/request-form-4.component";
import Page4Nomina from "../components/nominaForm/pages/request-form-4.component";
import Page5 from "../components/pages/request-form-5.component";
import Page5Nomina from "../components/nominaForm/pages/request-form-5.component";
import Page6 from "../components/pages/request-form-6.component";
import Page6Nomina from "../components/nominaForm/pages/request-form-6.component";
import TermsConditionsCDC from "../components/pages/circulo-credito/term-conditions";
import { Veriff } from "../components/pages/veriff/veriff";
import { VeriffNomina } from "../components/nominaForm/pages/veriff/veriff";
import "../css/main.css";
import "../css/ss.scss";
import {NOMINA_ACTIVE, PABS_ACTIVE, PERSONAL_ACTIVE} from '../utils/config'

const privateRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/dashboard2", component: Dashboard2 },
  { path: "/type-credit-application", component: TypeCreditApplication },
  { path: "/estado-cuenta", component: AccountStatus },
  { path: "/credito-detalle", component: CreditDetail },
  { path: "/solicitud", component: Solicitud },
  // { path: "/PABS", component: NavPABS },
  // { path: "/Nomina", component: NavNomina },
  { path: "/credito-nomina/Ciudad", component: NavNomina },
  { path: "/RECHAZADO/:type/:reason", component: Rechazado },
  { path: "/productos", component: CreditType },
  { path: "/credito-aprobado", component: ApprovedCredit },
  { path: "/proceso-credito", component: CreditProgress },
  { path: "/solicitud-no-aprobada", component: CreditoNoAprovado },
  { path: "/solicitud-rechazada-aprobada/:type", component: CreditoRechazadoAprovado },
  // { path: "/prestamo-personal", component: PrestamoPersonalNuevo },
  { path: "/sin-permiso-tramite", component: SinPermisoTramite },
  { path: "/credit-config/nomina", component: NavNomina },
  { path: "/credito-config/:creditType", component: ConfigCreditUser },
  { path: "/sin-credito", component: NonCreditUser },
  { path: "/step4", component: Page4 },
  { path: "/step5", component: Page5 },
  { path: "/step6", component: Page6 },
  { path: "/veriff", component: Veriff },
  { path: "/autoriza-circulo-credito", component: TermsConditionsCDC },
  //Rutas para módulo de nómina

  { path: "/dashboard-nomina", component: DashboardNomina },
  { path: "/dashboard2-nomina", component: Dashboard2Nomina },
  { path: "/type-credit-application-nomina", component: TypeCreditApplicationNomina },
  { path: "/estado-cuenta-nomina", component: AccountStatusNomina },
  { path: "/credito-detalle-nomina", component: CreditDetailNomina },
  { path: "/solicitud-nomina", component: SolicitudNomina },
  { path: "/credito-nomina/Ciudad", component: NavNomina },
  { path: "/RECHAZADO-nomina/:type/:reason", component: RechazadoNomina },
  { path: "/productos-nomina", component: CreditTypeNomina },
  { path: "/credito-aprobado-nomina", component: ApprovedCreditNomina },
  { path: "/proceso-credito-nomina", component: CreditProgressNomina },
  { path: "/solicitud-no-aprobada-nomina", component: CreditoNoAprovadoNomina },
  { path: "/solicitud-rechazada-aprobada-nomina/:type", component: CreditoRechazadoAprovadoNomina },
  { path: "/sin-permiso-tramite-nomina", component: SinPermisoTramiteNomina },
  { path: "/credit-config/nomina", component: NavNomina },
  { path: "/credito-config-nomina/:creditType", component: ConfigCreditUserNomina },
  { path: "/sin-credito-nomina", component: NonCreditUserNomina },
  { path: "/step4-nomina", component: Page4Nomina },
  { path: "/step5-nomina", component: Page5Nomina },
  { path: "/step6-nomina", component: Page6Nomina },
  { path: "/veriff-nomina", component: VeriffNomina },
  //{ path: "/autoriza-circulo-credito", component: TermsConditionsCDC },
];

if(PABS_ACTIVE) privateRoutes.push({ path: "/PABS", component: NavPABS });
if(NOMINA_ACTIVE) privateRoutes.push({ path: "/Nomina", component: NavNomina });
if(NOMINA_ACTIVE) privateRoutes.push({ path: "/Celular", component: NavCelular });
if(NOMINA_ACTIVE) privateRoutes.push({ path: "/Tequila", component: NavTequila });
if(PERSONAL_ACTIVE) privateRoutes.push({ path: "/prestamo-personal", component: PrestamoPersonalNuevo });

export default privateRoutes;
