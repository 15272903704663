import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import ImgVector from '../../../../assets/dark-blue-vector.png';
import {TramiteCreditoContext} from '../../../../context/TramiteCreditoProvider';
import {UserContext} from '../../../../context/UserProvider';
import AlertMessage from '../../alertNomina.component';
import HeaderDashboard from '../../headerDashboardNomina.component';
import CustomSlider from '../../sliderNomina.component';
import CustomDropdown from './dropdown.nomina.component';

// este solo se usa sin tener sesion, y tiene config de nivel A
export default function NonCreditUser() {
  const history = useHistory();

  const {credito, updateCredito} = React.useContext(TramiteCreditoContext);
  const {userMain, updateUser} = React.useContext(UserContext);

  const [loanAmount, setLoanAmount] = useState(0);
  const [pagoQuincenal, setPagoQuincenal] = useState(0);

  //este datos serán dinamicos en base a su perfil
  const [maxLoanAmount, setMaxLoanAmount] = useState(credito.limitAmountCredit); // monto maximo
  const [maxTerm, setMaxTermn] = useState(credito.limitTermCredit); // numero de meses
  const [tasa, setTasa] = useState(credito.tasa);

  const [term, setTerm] = useState(0);
  const [recurrence, setRecurrence] = useState<string>('quincenal');

  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');

  const handleChange = (
    event: React.ChangeEvent<{name?: string; value: unknown}>,
  ) => {
    var res = event.target.value as string;
    setRecurrence(res);
    recalcularMonto(res);
  };

  function recalcularMonto(recurrence: any) {
    if (recurrence === 'mensual') {
      calcularPagoMensual();
    } else if (recurrence === 'quincenal') {
      calcularPagoQuincenal();
    } else {
      calcularPagoSemanal();
    }
  }

  function valueAmount(value: number) {
    setLoanAmount(value);

    return `${value}`;
  }
  function valueTerm(value: number) {
    setTerm(value);
    recalcularMonto(recurrence);
    return `${value}`;
  }

  const calcularPagoQuincenal = () => {
    var principal = loanAmount;
    var interest = tasa / 100 / 26; // 26 quiencenas al año
    console.log(interest);

    var x = Math.pow(1 + interest, term);
    var total = (principal * x * interest) / (x - 1);

    setPagoQuincenal(total);
  };

  const calcularPagoSemanal = () => {
    var principal = loanAmount;
    var interest = tasa / 100 / 52; //52 semanas por año
    console.log(interest);

    var x = Math.pow(1 + interest, term); //Math.pow computes powers
    var total = (principal * x * interest) / (x - 1);

    setPagoQuincenal(total);
  };

  const calcularPagoMensual = () => {
    var principal = loanAmount;
    var interest = tasa / 100 / 12; // 12 meses por año
    console.log(interest);

    var x = Math.pow(1 + interest, term); //Math.pow computes powers
    var total = (principal * x * interest) / (x - 1);

    setPagoQuincenal(total);
  };

  useEffect(() => {
    setLoanAmount(10000);
    setTerm(parseInt(String(maxTerm / 2), 10));
  }, [credito]);

  useEffect(() => {
    calcularPagoQuincenal();
  }, [loanAmount]);

  const formatMoney = (value: number) => {
    return value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const showMessage = (message: string, type = 'success') => {
    setOpenMessage(true);
    setMessage(message);
    setSeverity(type);
  };

  const handleClose = () => {
    setOpenMessage(false);
  };

  const handleUpdateLoan = () => {
    console.log('update loan');

    // if (!phone || !phoneCode) {
    //   showMessage('Debe de verificar su telefono por favor', 'error');
    //   return;
    // }

    // fetch(
    //   process.env.REACT_APP_URL_API + '/verification/phone/check/' + phoneCode,
    //   {
    //     method: 'GET',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //   },
    // )
    //   .then(res => {
    //     console.log(res);

    //     if (res.status < 400) {
    //       showMessage('El telefono se ha verificado con éxito');
    //       updateLoan();
    //     } else {
    //       showMessage('Sucedio un problema al verificar su telefono', 'error');
    //     }
    //   })
    //   .catch(err => console.error(err));

    const updateLoan = () => {
      var forma_pago: number = 0;

      if (recurrence === 'mensual') {
        forma_pago = 1;
      } else if (recurrence === 'quincenal') {
        forma_pago = 2;
      } else {
        forma_pago = 3;
      }

      const body = {
        loan_amount_requested: loanAmount,
        number_term_payment: term,
        form_of_payment: forma_pago,
      };

      const userId = userMain.userId;

      fetch(process.env.REACT_APP_URL_API + '/user-loan/' + userId, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
        .then(res => {
          console.log(res);
          showMessage('Se han guardado los datos de su credito');
          setTimeout(() => {
            history.push('/step5-nomina');
          }, 1500);
        })
        .catch(err => console.error(err));
    };

    // const userId = 'd04561e8-2e57-43d4-a4e4-80484ceb23a4';
  };

  return (
    <div>
      <HeaderDashboard />
      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />
      <div className="solicitud-credito">
        {/*<div className="solicitud-credito__header">*/}
        {/*  <h1>¡Hola {userMain.nombre}! Bienvenido a tu espacio</h1>*/}
        {/*  <span>2 Noviembre 2020</span>*/}
        {/*</div>*/}
        <h2>
          Selecciona la cantidad, el plazo y la recurrencia de pago para
          terminar tu solicitud.
        </h2>

        <div className="solicitud-credito__content">
          <section className="solicitud-credito__credito">
            <div className="solicitud-credito__amount solicitud-credito__item ">
              <CustomSlider
                headerText="¿Cuánto dinero necesitas?"
                min={2000}
                max={maxLoanAmount}
                minLabel={'$2,000'}
                maxLabel={formatMoney(maxLoanAmount)}
                step={500}
                valueText={valueAmount}
                defaultValue={maxLoanAmount / 2}
                valueLabelFormat={(value: number) =>
                  value.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                }
              />
            </div>

            <div className="solicitud-credito__termn solicitud-credito__item ">
              <CustomSlider
                headerText="¿A qué plazo?"
                min={1}
                max={maxTerm}
                minLabel={'1 mes'}
                maxLabel={`${maxTerm} meses`}
                step={1}
                valueText={valueTerm}
                defaultValue={maxTerm / 2}
                valueLabelFormat={(value: number) => value}
              />
            </div>

            <div className="solicitud-credito__recurrence solicitud-credito__item ">
              <h4>Selecciona tu recurrencia de pago</h4>

              <CustomDropdown
                placeholder={'Recurrencia'}
                handleChange={handleChange}
                value={recurrence}
              >
                <option aria-label="None" value="" />
                <option value={'quincenal'}>Quincenal</option>
                <option value={'semanal'}>Semanal</option>
              </CustomDropdown>
            </div>

            {/* <div className="solicitud-credito__result solicitud-credito__item ">
              <span>
                Pagos de
                <b>
                  {' '}
                  {pagoQuincenal.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}{' '}
                </b>
                {recurrence}es por{' '}
                <strong> {`${term} ${term !== 1 ? 'meses' : 'mes'}`}.</strong>
              </span>
              <span>
                <b> &nbsp; Los montos son de carácter informativo.</b>
              </span>
            </div> */}
          </section>

          {/*<section className="solicitud-credito__contrato">*/}
          {/*  <h2>*/}
          {/*    Para concluir, deberás aceptar el contrato al verificar tu*/}
          {/*    teléfono celular*/}
          {/*  </h2>*/}

          {/*  <div className="solicitud-credito__input-group">*/}
          {/*    <label>Teléfono</label>*/}
          {/*    <input type="number" placeholder="333 123 2345" value={phone} onChange={(e) => setPhone(e.target.value)} />*/}
          {/*  </div>*/}
          {/*  <div className="solicitud-credito__input-group">*/}
          {/*    <label>Código de verificación</label>*/}
          {/*    <input placeholder="23frge" value={phoneCode} onChange={(e) => setPhoneCode(e.target.value)} />*/}
          {/*  </div>*/}
          {/*</section>*/}

          {/*<section className="solicitud-credito__buttons">*/}
          {/*  <button className="descargar-contrato" onClick={requestCredit}>*/}
          {/*    Descargar contrato <i className="fas fa-download"></i>*/}
          {/*  </button>*/}
          {/*  <button className="blue" onClick={updateLoan}>Aceptar y solicitar mi préstamo</button>*/}
          {/*</section>*/}

          <section className="solicitud-credito__buttons">
            {/* <button className="descargar-contrato" onClick={requestCredit}>
              Descargar contrato <i className="fas fa-download"></i>
            </button> */}
            <button className="blue" onClick={handleUpdateLoan}>
              Enviar solicitud
            </button>
          </section>
        </div>
      </div>
      <img className="img-back-credito" src={ImgVector} />
    </div>
  );
}
