import { makeStyles, useTheme } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { TramiteCreditoContext } from '../../../../context/TramiteCreditoProvider';
import { UserContext } from '../../../../context/UserProvider';
import HeaderDashboard from '../../headerDashboardNomina.component';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const style = {
    label: {
        display: 'block',
        font: "1rem 'Fira Sans', sans-serif",
        textAlign: 'left',
        float: 'left',
        marginTop: '10px',
        marginBottom: '5px',
        fontSize: '17px',
    },
    input_label: {
        width: '30%',
        height: '30px',
        margin: '.4rem 0',
        border: '1px solid #ccc',
        padding: '3px',
        marginBottom: '5px',
        transition: '300ms all',
    },
};


function CreditoNoAprovado() {
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();

    const { userMain, updateUser } = React.useContext(UserContext);

    const { credito, updateCredito } = React.useContext(TramiteCreditoContext);

    function redirecComponent(e: { preventDefault: () => void; }) {
        e.preventDefault();

        /*fetch(process.env.REACT_APP_URL_API + '/loan-no-approved-entities/' + userMain.userId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(res => res.json())
            .then(result => {

                if (result.error) {

                } else {

                    if (result.isActive) {
                        updateCredito({
                            ...credito,
                            estatusSolicitud: 'solicitud-no-aprobada',
                        });
                        
                        return history.push('/sin-permiso-tramite')
                    } else {
                        updateCredito({
                            ...credito,
                            estatusSolicitud: 'sin-solicitud',
                        });

                        return history.push('/sin-credito');
                    }

                }
            })
            .catch(error => console.log(error))*/

        return history.push('/');
    }

    return (
        <div>
            <HeaderDashboard />
            <div className="dashboard__container">
                <div className="dashboard-user__header">
                    <div>
                        <h1>¡Hola {userMain.nombre}! Bienvenido a tu espacio</h1>
                        <span>2 Noviembre 2020</span>
                    </div>
                    
                </div>

                <div className="dashboard-usuario-1__card">
                    <h2>Solicitud de Crédito Rechazada</h2>
                    <br />
                    <div className="dashboard-en-proceso__content">
                        <br />
                        {credito.importe > 0 &&
                            (<span>
                                Luego de un meticuloso análisis de su solicitud de prestamo con un importe de
                                <b> $ {credito.importe} MXN.</b>
                            </span>)
                        }

                        <br />
                        <span>Por parte de el equipo Percapita nos vemos en la obligación de informarle que lamentablemente no podemos aprobar el crédito en este momento</span>
                        <br />
                        <span>Sentimos los inconvenientes ocasionados.</span>
                        <br />
                        <span>Sirvase realizar un nuevo trámite de solicitud en un lapso de periodo de 6 meses.</span>
                        <button className="blue" onClick={redirecComponent}>
                            <i className="fas fa-phone-alt"> </i> Regresar
                        </button>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default CreditoNoAprovado;
