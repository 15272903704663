import HeaderDashboard from "../../headerDashboardNomina.component";

import savelogo from "../../../../assets/save-money_1.png";
import solarum from "../../../../assets/solarumProducto.jpg";
import nomina from "../../../../assets/nomina.png";
import phone from "../../../../assets/phoneCredit.png"
import { Link, useHistory } from "react-router-dom";
import React,{ useEffect, useState } from "react";
import {NOMINA_ACTIVE, PABS_ACTIVE, PERSONAL_ACTIVE} from '../../../../utils/config';
import ApiClient from "../../../../services/apiclient";
import { UserContext } from "../../../../context/UserProvider";
import { TramiteCreditoContext } from "../../../../context/TramiteCreditoProvider";


function CreditTypeNomina() {
  
  const [creditsTypes, setCreditsTypes] = useState([]);
  const [activeCredits, setActiveCredits] = useState([]);
  const history = useHistory();
  const apiClient = ApiClient.getInstance();
  const [loading, setLoading] = useState<boolean>(false);
  var userLocal = JSON.parse(localStorage.getItem("userLocal"));
  const selected  = userLocal.creditoNomina;
  const { userMain, updateUser } = React.useContext(UserContext);
  const { credito, updateCredito } = React.useContext(TramiteCreditoContext);
  
  const [step, setStep] = useState([]);
  const [titulo, setTitulo] = useState('');


 const getCredits = async () => {
    
    apiClient.getStepsNomina().then((res) => {
      console.log(localStorage.getItem('userLocal'));
      let activeCredits = []
    activeCredits = [
      ...activeCredits,
      {
        type: 'nomina',
        url: '/Nomina',
        title: 'Crédito personal',
        img: nomina
      },
      {
        type: 'celular',
        url: '/Celular',
        title: 'Crédito celular',
        img: phone
      },
      // {
      //   type: 'solarum',
      //   url: '/Tequila',
      //   title: 'Crédito tequila solarum',
      //   img: solarum
      // },
    ];     
      let actualCredits = [] 
      setStep(res.steps); 
      if(selected === '' ) //Mostar los creditos activos en caso de tener creditos en proceso
      {
        const activos = (res.steps).filter(step => step.activo === true);
        console.log(activos)
        if (activos.length > 1) {
          for (let i = 0; i < activos.length; i++){
            console.log(activos[i].producto)
            let a = activeCredits.filter(c => c.type === activos[i].producto);       
            actualCredits = [
              ...actualCredits,
              ...a
            ]
            
          }   
          console.log(actualCredits)
          setTitulo('¡Hola! Elige uno de tus créditos activos') ;    
          setCreditsTypes(actualCredits); 
          
        } else if(activos.length == 1) {
          let a = activeCredits.filter(c => c.type === activos[0].producto); 
          updateUser({ ...userMain, creditoNomina:a[0].type });
          history.push(a[0].url)
        } else {
          setTitulo('¡Hola! Elige el tipo de credito que te interesa');
          setCreditsTypes(activeCredits);
        }

      } else if (selected !== '') {
        
        const result = activeCredits.filter(credito => credito.type === selected);
        setCreditsTypes(result);
        console.log(result)
        history.push(result[0].url)
      }
    })};

  useEffect(() => {
    getCredits();
  }, []);

  const handleClick = (type: string) => {
    updateUser({ ...userMain, creditoNomina: type });
  }

  return (
    <div>
      <HeaderDashboard />

      <div className="dashboard__container ">
        <div className="dashboard-usuario-1__content">
          <div className="dashboard-usuario-2">
            <div className="dashboard-user__header credit-type-header">
              <div>
                <h1>{titulo}</h1>
              </div>
            </div>


            <div className="dashboard-banck__small-cards-container">
              {
                creditsTypes.map(({url, title, img, type}, i) =>
                <div >
                  <Link key={i} to={url} onClick={() => handleClick(type)} > 
                    <div >
                      <img src={img} alt="" />
                      <p style={{textAlign: 'center'}}>
                        <b>{title}</b>
                      </p>
                    </div>
                  </Link> 
                  </div>)
              }

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreditTypeNomina;
