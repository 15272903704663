import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import IconLoogin from '../assets/login.png';
import logo from '../assets/lOGO.png';
import { TramiteCreditoContext } from '../context/TramiteCreditoProvider';
import { UserContext } from '../context/UserProvider';

const useStyles = makeStyles({
  root: {
    width: '250px',
  },
});

function Header() {
  const history = useHistory();

  const { userMain, updateUser } = React.useContext(UserContext);
  const { credito, updateCredito } = React.useContext(TramiteCreditoContext);
  const location = useLocation();

  const user = userMain.nombre;

  function handleClick(e: { preventDefault: () => void }) {
    e.preventDefault();
    updateCredito({
      idPrestamo: '',
      fecha_solicitud: '',
      isApproved: false,
      isDelivered: false,
      estatusSolicitud: 'sin-credito',
      importe: 0,
      paymentStatus: '0', // (0 = nuevo, 1 = en proceso, 2 = aprobado, 3 = Rechazado)
      importe_interes: 0,
      total_importe: 0,
      numero_plazo: 0,
      importe_liquidar: 0,
      importe_pendiente: 0,
      detalles: [],
      limitAmountCredit: 20000, // limite de dinero a prestar
      limitTermCredit: 12, // limite de meses
      tasa: 70,
    });
    localStorage.clear();
    updateUser({ ...userMain, nombre: 'Iniciar Sesión', apellidoPaterno: '' });
    return history.push('/login');
  }

  

  function redirecComponent(e: { preventDefault: () => void }) {
    e.preventDefault();
   // console.log('redirecComponent: ', credito)
    if (credito.estatusSolicitud === 'aprobado') {
      if (credito.isDelivered) {
        return history.push('/credito-detalle');
      } else if (!credito.isDelivered) {
        return history.push('/dashboard2');
      }
    } else if (credito.estatusSolicitud === 'revision') {
      return history.push('/proceso-credito');
    } else if (credito.estatusSolicitud === 'enviar-solicitud') {
      return history.push('/credito-config');
    } else if (credito.estatusSolicitud === 'solicitud-no-aprobada') {
      return history.push('/sin-permiso-tramite');
    } else if (credito.estatusSolicitud === 'sin-credito') {
      return history.push('/productos');
    } else {
      // return history.push('/sin-credito');
      return history.push('/type-credit-application');
    }
  }

  function redirecEspacioSolicitud(e: { preventDefault: () => void }) {
    e.preventDefault();

    if (user != 'Iniciar Sesión') {
      redirecComponent(e);
    } else {
      return history.push('/step1');
    }
  }

  //mobile nav

  const classes = useStyles();

  const [mobileNav, setMobileNav] = React.useState(false);

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setMobileNav(open);
  };

  const [routes, setRoutes] = React.useState({
    root: "/",
    pasosSeguir: "/pasos-a-seguir",
    faq: "/faq",
    culturaFinanciera: "/educacion-financiera",
    login: "/login",
    solicitud: "/register-form",
  });

  return (
    <nav className="navigation-bar">
      <div className="left-cont">
        <Link to="/">
          <img className="logo" src={logo} />
        </Link>
      </div>
      <div className="right">
        <div>
          <SwipeableDrawer
            classes={classes}
            anchor="right"
            open={mobileNav}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            <List>
              <ListItem button>
                <Link to="/">
                  <img className="logo" src={logo} />
                </Link>
              </ListItem>
              <Divider />
              <ListItem button>
                <Link to="/" className={(location.pathname === routes.root ? "active" : "")}>
                  Inicio
                </Link>
              </ListItem>
              {
                credito.estatusSolicitud === 'aprobado'
                  ?
                  null
                  :
                  <ListItem button>
                    <Link to="#" className={(location.pathname === '/step1'
                      || location.pathname === '/credito-detalle'
                      || location.pathname === '/dashboard2'
                      || location.pathname === '/proceso-credito'
                      || location.pathname === '/credito-config'
                      || location.pathname === '/sin-permiso-tramite'
                      || location.pathname === '/type-credit-application'
                      || location.pathname === '') ? 'active' : ''} onClick={redirecEspacioSolicitud}>
                      Quiero un préstamo
                    </Link>
                  </ListItem>
              }
              <ListItem button>
                <Link to="/pasos-a-seguir" className={(location.pathname === routes.pasosSeguir ? "active" : "")}>Pasos a seguir</Link>
              </ListItem>
              <ListItem button>
                <Link to="/faq" className={(location.pathname === routes.faq && location.pathname != routes.solicitud ? "active" : "")}>Preguntas frecuentes</Link>
              </ListItem>
              <ListItem button>
                <Link to="/educacion-financiera" className={(location.pathname === routes.culturaFinanciera ? "active" : "")}>Cultura financiera</Link>
              </ListItem>
            </List>
          </SwipeableDrawer>

          <div className="mobile-items-container">
            <div>
              <Link to="/" className={(location.pathname === routes.root ? "active" : "")}>
                Inicio
              </Link>
              {
                credito.estatusSolicitud === 'aprobado'
                  ?
                  null
                  :
                  <Link to="#" className={(location.pathname === '/step1'
                    || location.pathname === '/credito-detalle'
                    || location.pathname === '/dashboard2'
                    || location.pathname === '/proceso-credito'
                    || location.pathname === '/credito-config'
                    || location.pathname === '/sin-permiso-tramite'
                    || location.pathname === '/type-credit-application'
                    || location.pathname === '') ? 'active' : ''} onClick={redirecEspacioSolicitud}>
                    Quiero un préstamo
                  </Link>
              }
              <Link to="/pasos-a-seguir" className={(location.pathname === routes.pasosSeguir ? "active" : "")}>Pasos a seguir</Link>
              <Link to="/faq" className={(location.pathname === routes.faq ? "active" : "")}>Preguntas frecuentes</Link>
              <Link to="/educacion-financiera" className={(location.pathname === routes.culturaFinanciera ? "active" : "")}>Cultura financiera</Link>
            </div>
          </div>
        </div>
        <div className="right-cont">
          {user !== 'Iniciar Sesión' ? (
            <Link to="#" className="separator" onClick={redirecComponent}>
              {userMain.nombre + ' ' + userMain.apellidoPaterno}
              {userMain.nombre.split(' ')[0]}
            </Link>
          ) : (
            <Link to="/login" className={(location.pathname === routes.login ? "active separator" : "separator")}>
              Iniciar Sesión
            </Link>
          )}
          {user !== 'Iniciar Sesión' ? (
            <Link to="#" onClick={handleClick}>
              {' '}
              <img src={IconLoogin} /> Cerrar Sesión
            </Link>
          ) : (
            ''
          )}
        </div>
      </div>
      <i onClick={toggleDrawer(true)} className="fas fa-bars mobile-btn"></i>
    </nav>
  );
}

export default Header;
