import React, { useEffect, useState } from "react";
import ApiClient from "../../../../../services/apiclient";
import AlertMessage from "../../../../alert.component";
import ImgVector from "../../../../../assets/dark-blue-vector.png";
import "./credit.css";
import { Field, Formik, Form } from "formik";
import { useHistory, useParams } from "react-router-dom";
import { TramiteCreditoContext } from "../../../../../context/TramiteCreditoProvider";
import { UserContext } from "../../../../../context/UserProvider";
import { ProgressCircular } from "../../Progress";
import CustomSlider from "../../../sliderNomina.component";
import CustomDropdown from "../../dashboard/dropdown.nomina.component";

interface RouteParams {
  creditType: "nomina";
}

export default function Informacion({ nextStep }) {
  const history = useHistory();

  const { creditType } = useParams<RouteParams>();

  const { credito, updateCredito } = React.useContext(TramiteCreditoContext);
  const { userMain, updateUser } = React.useContext(UserContext);
  const [step, setStep] = useState(2);
  const [loading, setLoading] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [recommendation, setRecommendation] = useState(null);
  const [idNomina, setIdNomina] = useState(null);
  const apiClient = ApiClient.getInstance();
  const [items, setItems] = useState([]);
  const [loanAmount, setLoanAmount] = useState(0);
  const [recurrencyPayment, setRecurrencyPayment] = useState(null);
  const [product, setProduct] = useState([]);
  const [filtroOpciones, setFiltroOpciones] = useState([]);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  const [plazo, setPlazo] = useState(0);
  const [productCelular, setProductCelular] = useState("");
  const [antiquity, setAntiquity] = useState(0)

  let isSendCodePhone = false;

  const opciones = async () => {};

  const sendCodePhone = (phone: string) => {
    const body = {
      phone: phone,
    };

    fetch(process.env.REACT_APP_URL_API + "/verification/phone/resend", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        console.log("code phone enviado ", res);
        // showMessage("Se han guardado los datos de su credito");
        isSendCodePhone = true;
      })
      .catch((err) => {
        console.error(err);
        isSendCodePhone = true;
      });
  };
  // useEffect(() => {

  //   setTimeout(() => {
  //     if (!isSendCodePhone) {
  //       sendCodePhone(userMain.telefono);
  //     }
  //   }, 2000);
  // }, []);

  const handleUpdateLoan = () => {
    console.log("update loan", loanAmount);

    if (loanAmount <= 0) {
      console.log("-----dsad");
      setOpenMessage(true);
      setMessage("Favor de seleccionar un equipo");
      setSeverity("error");
      return;
    }
    if (plazo === 0) {
      setOpenMessage(true);
      setMessage("Favor de seleccionar un plazo de pago");
      setSeverity("error");
      return;
    }

    updateLoan();
  };
  const updateLoan = () => {
    // nextStep()

    setLoading(true);
    const body = {
      amount: loanAmount,
      plazo: plazo,
      frequencyPayments: recurrencyPayment,
      productName: productCelular,
    };
    console.log(body);
    apiClient
      .sendNominaProducto(body, "celular")
      .then((resp) => {
        setLoading(false);
        nextStep();
        //history.push("/credit-nomina");
      })
      .catch((err) => {
        setLoading(false);
        setOpenMessage(true);
        setMessage(err.message);
        setSeverity("error");
      });
  };

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    
    var a = event.target.value as string;
    var categroy = a.split("|")[1];
    var amount = parseInt(a.split("|")[0]);
    var name = a.split("|")[2];
    console.log(product.filter(product => product.name === "Samsung S20 ULTRA 5 G 128GB"),name, name === "Samsung S20 ULTRA 5 G 128GB", product.filter(product => product.name === name))
    setProductCelular(name);
    setLoanAmount(amount);
    var semanas  = antiquity <= 1 ? 24 : 52
    const search = product.filter(
      (value) =>
        value.name === name &&
        value.pago === recurrencyPayment &&
        value.isActive === true &&
        value.semanas <= (antiquity < 1 ? 24 : 52)
    );
    console.log(search, (antiquity < 1 ? 24 : 52));
    setFiltroOpciones(search);
  };

  const handleChangePlazo = (
    event: React.ChangeEvent<{ name?: number; value: unknown }>
  ) => {
    var a = event.target.value as number;
    setPlazo(parseInt(a));
  };

  useEffect(() => {
    apiClient
      .getProductsNomina("celular")
      .then((res:any) => {
        console.log("157**********", res.option[0]);
        setItems(res.option[0]);
        setAntiquity(res.antiquity);
        setRecurrencyPayment(
          res.recurrencyPayment === "SEMANAL" ? "Semanal" : "Quincenal"
        );
        setLoading(false);
        apiClient
          .getProductsDesglose()
          .then((res) => {
            console.log(res);
            setProduct(res);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleClose = () => {
    setOpenMessage(false);
  };
  {
    if (loading) {
      return <p>Loading...</p>;
    } else {
      return (
        <div>
          <AlertMessage
            open={openMessage}
            message={message}
            handleClose={handleClose}
            severity={severity}
          />

          <div className="solicitud__container">
            <div className="solicitud-b__container">
              <div className="solicitud-credito">
                <h2>
                  Selecciona tu smartphone y la recurrencia de pago para
                  terminar tu solicitud.
                </h2>
                <h1></h1>

                <div className="solicitud-credito__content">
                  <section className="solicitud-credito__credito">
                    <div className="solicitud-credito__amount solicitud-credito__item ">
                      <div className="solicitud-credito__recurrence solicitud-credito__item ">
                        <h4>Elige tu smartphone</h4>
                        <CustomDropdown
                          placeholder={"Equipo"}
                          width="auto"
                          handleChange={handleChange}
                        >
                          <option aria-label="None" value="" />
                          {items.map((data) => {
                            return (
                              <option
                                value={
                                  data.cost +
                                  "|" +
                                  data.category +
                                  "|" +
                                  data.name
                                }
                              >
                                {data.name}
                              </option>
                            );
                          })}
                        </CustomDropdown>
                      </div>
                    </div>
                    <div className="solicitud-credito__termn solicitud-credito__item ">
                      <div className="solicitud-credito__recurrence solicitud-credito__item ">
                        <h4>Elige el plazo</h4>
                        <CustomDropdown
                          placeholder={"Plazo"}
                          width="480px"
                          handleChange={handleChangePlazo}
                        >
                          <option aria-label="None" value="" />
                          {filtroOpciones.map((data) => {
                            return (
                              <option value={data.semanas}>
                                {data.semanas} pagos{" "}
                                {recurrencyPayment === "Semanal"
                                  ? "semanales"
                                  : "quincenales"}{" "}
                                de $
                                {new Intl.NumberFormat("en-US", {
                                  minimumFractionDigits: 2,
                                }).format(data.pago_semanal)}{" "}
                                Total a pagar: $
                                {new Intl.NumberFormat("en-US", {
                                  minimumFractionDigits: 2,
                                }).format(data.total_pagado)}
                              </option>
                            );
                          })}
                        </CustomDropdown>
                      </div>
                    </div>

                    <div className="solicitud-credito__recurrence solicitud-credito__item ">
                      <h4>Selecciona tu recurrencia de pago</h4>

                      <CustomDropdown value={recurrencyPayment} disabled>
                        <option value={recurrencyPayment}>
                          {recurrencyPayment}
                        </option>
                      </CustomDropdown>
                    </div>
                  </section>

                  <section className="solicitud-credito__buttons">
                    <button
                      className="blue"
                      disabled={loading}
                      onClick={handleUpdateLoan}
                    >
                      <ProgressCircular
                        loading={loading}
                        title="Enviar solicitud"
                      />
                    </button>
                  </section>
                </div>
              </div>
              <img className="img-back-credito" src={ImgVector} />
            </div>
          </div>
        </div>
      );
    }
  }
}
